import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import listProductsGearTransmissionOilsCo from "../ProductsExolCommercial/listProductsGearTransmissionOilsCo";
import "../../../assets/css/pages/Exol/Category/Commercial/categoryCommercial.css";
import { AskInformationProducts } from "../../../components/Modal/AskInformationProducts";

export const GearTransmissionOilsCo = () => {
  
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  // Inicio Modal Solicitud de Informacion Productos

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Fin Modal Solicitud de Informacion Productos

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "geartransmissionoilsco.title",
    defaultMessage:
      "Exol - Aceites para engranajes y transmisiones | Comercial", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "geartransmissionoilsco.description",
    defaultMessage:
      "Los aceites para engranajes y transmisiones de Exol están formulados con aditivos de alto rendimiento para proporcionar una lubricación óptima y protección contra el desgaste, la corrosión y la formación de espuma. Estos aceites ayudan a mantener un funcionamiento suave y eficiente de los engranajes y las transmisiones, minimizando el desgaste y prolongando la vida útil de los componentes.", //Cambia Descripcion Pagina
  });

  return (
    <section className="categorycommercial">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-categorycommercial">
        <div className="head-categorycommercial-img"></div>
        <div></div>
      </div>
      <Container>
        <Row className="product-categorycommercial-row1"></Row>
        <Row className="product-categorycommercial-row2">
          <h1>
            <FormattedMessage
              id="geartransmissionoilsco.row2-h1"
              defaultMessage="Productos | Aceites para engranajes y transmisiones"
            />
          </h1>
          <Col>
            <div className="container-listproductscommercial">
              {listProductsGearTransmissionOilsCo.map((product, index) => (
                <div
                  key={index}
                  className="product-link-categorycommercial"
                  onClick={handleOpenModal}
                  // onClick={scrollToTop}
                >
                  <div className="map-listproductcommercial">
                    <h2>
                      <FormattedMessage
                        id={product.name}
                        defaultMessage={product.name}
                      />
                    </h2>
                    <img
                      src={product.imgUrl}
                      alt="Lubricantes Comerciales - Aceites para engranajes y transmisiones"
                    />
                    <p>
                      <FormattedMessage
                        id={product.option}
                        defaultMessage={product.option}
                      />
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <AskInformationProducts
              showModal={showModal}
              onHide={handleCloseModal}
            />{" "}
            {/* Modal Escondido */}
          </Col>
        </Row>
        <Row className="product-categorycommercial-row3"></Row>
      </Container>
    </section>
  );
};
