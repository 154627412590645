import { useState, useEffect, useContext } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/img/oilfluid_logo.png";
import langEs from "../assets/img/icon/icon_es.png";
import langEn from "../assets/img/icon/icon_en.png";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { ProductSubMenu } from "./ProductSubMenu";

// Importaciones Languages / FormatJS
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../lang/LanguageContext";

export const NavBar = () => {
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  const [activeLink, setActiveLink] = useState("");
  const [scrolled, setScrolled] = useState(false);
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  const { lang } =
    useContext(LanguageContext); /* Languages - lang, handleLanguageChange */

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  // Para verificar el idiom console.log(lang);

  return (
    <section className="navbar">
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              alt="Logo"
              className={`logo-company1 ${scrolled ? "scrolled" : ""}`}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="navbar-toggle-bostrap"
          >
            <i
              className={`fas fa-bars custom-toggler-icon ${
                scrolled ? "scrolled" : ""
              }`}
            ></i>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <div>
                <a
                  href={intl.formatMessage({ id: "navbar.home-url" })}
                  className={
                    activeLink === "home"
                      ? "navbar-link-active"
                      : "navbar-link-disable"
                  }
                  onClick={() => onUpdateActiveLink("home")}
                  style={scrolled ? { color: "#ffffff" } : { color: "#121212" }}
                >
                  <FormattedMessage id="navbar.home" defaultMessage="INICIO" />
                </a>
              </div>

              <div className="navbar_btn_products_ul">
                <Link
                  to={intl.formatMessage({ id: "navbar.btn-products" })}
                  className={
                    activeLink === "products"
                      ? "navbar-link-active"
                      : "navbar-link-disable"
                  }
                  onClick={() => {
                    onUpdateActiveLink("products");
                  }}
                  style={scrolled ? { color: "#ffffff" } : { color: "#121212" }}
                >
                  <FormattedMessage
                    id="navbar.products"
                    defaultMessage="PRODUCTOS"
                  />
                </Link>
                <div className="navbar-products-submenu">
                  <ProductSubMenu />
                </div>
              </div>

              <Link
                to={intl.formatMessage({ id: "navbar.btn-contact" })}
                className={
                  activeLink === "contact"
                    ? "navbar-link-active"
                    : "navbar-link-disable"
                }
                onClick={() => onUpdateActiveLink("contact")}
                style={scrolled ? { color: "#ffffff" } : { color: "#121212" }}
              >
                <FormattedMessage
                  id="navbar.contact"
                  defaultMessage="CONTACTO"
                />
              </Link>
              <div className="container-btn-languages">
                {/* ---------- Languages Btn ---------- */}
                <a href="/es" > {/* onClick={() => handleLanguageChange("es-PE")} */}
                  <img
                    src={langEs}
                    alt="biobor_spanish"
                    className={lang === "es-PE" ? "lang_es_active" : "lang_es"}
                  />
                </a>
                <a href="/en" > {/* onClick={() => handleLanguageChange("en-US")} */}
                  <img
                    src={langEn}
                    alt="biobor_english"
                    className={lang === "en-US" ? "lang_en_active" : "lang_en"}
                  />
                </a>
                {/* <p>
                  <FormattedMessage
                    id="navbar.currentLanguage"
                    defaultMessage="Idioma actual: {locale}"
                    values={{ locale }}
                  />
                </p> */}
                {/* ---------- Languages Btn ---------- */}
              </div>
              {/* ---------- Boton Login ---------- */}
              <div className="btn-right-navbar">
                {!isAuthenticated ? (
                  <button onClick={loginWithRedirect} className="nav-btn-login">
                    <FormattedMessage
                      id="navbar.openSesion"
                      defaultMessage="Iniciar Sesion"
                    />
                  </button>
                ) : (
                  <button onClick={logout} className="nav-btn-logout">
                    <FormattedMessage
                      id="navbar.closedSesion"
                      defaultMessage="Cerrar Sesion"
                    />
                  </button>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
};
