const listCategoryCommercial = [

    { option: "mapcommercial1", name: "namecommercial1", imgUrl: "https://dsm01pap002files.storage.live.com/y4mbcxDhv1_lcECZm1qnSUWM4CEgVW47stD7Kg5RTe4vC0nc3Wmhi4aI6NRAVh8xqpLK5-XRqmSvbe2wRBGpUOVBy8X_xKJkr2ESRbvulO0QJxnkNPUwfeyP9oKKwVUmLwTdFC0WE7TZm1IoWIVvCFLJCJAQdfYPB-Ykk1AiuvbjWse4QQfnSSgnjAWyTkKCOix?width=600&height=600&cropmode=none", description: "Totalmente Sintéticos de Bajo Contenido de Cenizas y Bajas Emisiones", url:"commercial.url1"},
    { option: "mapcommercial2", name: "namecommercial2", imgUrl: "https://dsm01pap002files.storage.live.com/y4mPppyNQzi2pgyJMkXbFTqKnsw5V0QpPtjS327Ksqh_z_7bQcXg1fbzzJlMpSYk9WwdgLpPb6UZslvKApndLystwwr9Is_qcSz-wRRZB_mCobdG22gZXKV73NQEFFFHLteervIBZs2n4-EAs0v7fhI6sb8qiLnUUinu1DaQd6fAy0VjdAyrG9iY2Qzs84VCMYK?width=600&height=600&cropmode=none", description: "Totalmente Sintéticos", url:"commercial.url2" },
    { option: "mapcommercial3", name: "namecommercial3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mWQKZADnWauzK7d0dnnM-humnZMmGfzZl3_8JmCK7ioPz8MvsjtKqOpxDsv8YxDtiWPHXUPl2T11DX_hn5QMdRsHHJjc1moeBdPcqLGYGzD8Bwmu4p1eflpkf_of1PpILKbY3sghYOuzX24KrdMbRt5pBDHRbcICuWX63ji2B5eGjbN3JhB8hM2NIjpIcBqK4?width=600&height=600&cropmode=none", description: "Semisintéticos", url:"commercial.url3" },
    { option: "mapcommercial4", name: "namecommercial4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mW96ec7Jaf_IXYPaPUD30MDog20Pkn6II9zORmItXbu6dofDn1jUiLAmSxzWhwt2J5ken8MqaN_XOtYfTp2OQ8u8YjN5BaSx2xLrPJFTabzqMtnI02ykxrNlIPs44McwU2kkq7UUPUY4Q8B-_cwlob-3EEyJoOm_SWgFTgLLMRAde9loMCWteRyjUwJUOxALj?width=600&height=600&cropmode=none", description: "A Base de Aceite Mineral", url:"commercial.url4" },
    { option: "mapcommercial5", name: "namecommercial5", imgUrl: "https://dsm01pap002files.storage.live.com/y4msiziamXDLAtWVs7X3b3iv5iXpe-T04HAKZ0CnWI9yN8Kv89y3aOrAlGc4bw89OKBI-O9MNTMtvYM_lWfwT_iPxlFpSI6P_iqISCuynJXuqFU3sL3Hus4CpJGNuMbXNmDBIEKTrO1YtAhbw3TkQvDAEuZE9leXXDxzdb4srD41DUy7jKX76FaDW_NMFppDYuA?width=600&height=600&cropmode=none", description: "Funcionamiento suave y eficiente de la transmisión", url:"commercial.url5" },
    { option: "mapcommercial6", name: "namecommercial6", imgUrl: "https://dsm01pap002files.storage.live.com/y4mkhJ-gjM8IsRlrbhWTqRlPfjtNejKm22x2I_FSpFEkt-qFUjLvaPVC3dn-k3yUBGUYEdZjnXEYUELXcJkZs0rtNYNGRBVEQ9-In05tOK_RW4BcHAyyhu4vatTw4pztpV6zuvcwrohuW7K6ZpNRgtzl6IaFlK3wuQ3BMxJ6xq6gd-MSKHAN3ljhbjj2zTiJTuX?width=600&height=600&cropmode=none", description: "Protege tus piezas móviles del desgaste y la corrosión", url:"commercial.url6" },
    { option: "mapcommercial7", name: "namecommercial7", imgUrl: "https://dsm01pap002files.storage.live.com/y4mfNx4FDzLGwL2T4FrvKm7xpp2SIr65ugUJvUf-yG456HHpCZX8bTxWJ9pRh18kme0aGE1l6SX7guBacd1JrU12vltAPbRJt8okerZAew_p8KLEe2vnc3l8q1ncs14rV1M7yjPpwUHX17mLjSgGi-VUH1p_-fQRG79Za1AelqnRN_r0844qgSoAToFY0t-mkGm?width=600&height=600&cropmode=none", description: "Lubrica y protege eficientemente los motores de las motocicletas", url:"commercial.url7" },
  ];
  
  export default listCategoryCommercial;