import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect, useRef } from "react";
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Products } from "./components/Products";
import { Footer } from "./components/Footer";
import { Info1 } from "./components/Info1";
import { Info2 } from "./components/Info2";
import { Info3 } from "./components/Info3";
import { Contact } from "./components/Contact";
import { Aviation } from "./Pages/Aviation";
import { Diesel } from "./Pages/Diesel";
import { Gasoline } from "./Pages/Gasoline";
import { Accessories } from "./Pages/Accessories";
import { BioborJfAviation } from "./Pages/ProductsAviation/BioborJfAviation";
import { Lubribor } from "./Pages/ProductsAviation/Lubribor";
import { Turboline } from "./Pages/ProductsAviation/Turboline";
import { HumBug } from "./Pages/ProductsAviation/HumBug";
import { FuelStat } from "./Pages/ProductsAviation/FuelStat";
import { BioborjfDiesel } from "./Pages/ProductsDiesel/BioborjfDiesel";
import { BioborMd } from "./Pages/ProductsDiesel/BioborMd";
import { BioborDc } from "./Pages/ProductsDiesel/BioborDc";
import { BioborDcWinter } from "./Pages/ProductsDiesel/BioborDcWinter";
import { ColdflowLubribor } from "./Pages/ProductsDiesel/ColdflowLubribor";
import { BioborEB } from "./Pages/ProductsGasoline/BioborEB";
import { NotFound } from "./Pages/NotFound";
import { useLocation, BrowserRouter, Route, Routes } from "react-router-dom";
//Importaciones FormatJS
import { LanguageProvider } from "./lang/LanguageContext";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlContext, useIntl } from "react-intl";
import { Automotive } from "./Pages/Exol/Automotive";
import { LowSAPSFully } from "./Pages/Exol/ProductsExolAutomotive/LowSAPSFully";
import { FullySyntheticEngineOils } from "./Pages/Exol/ProductsExolAutomotive/FullySyntheticEngineOils";
import { SemiSyntheticEngineOils } from "./Pages/Exol/ProductsExolAutomotive/SemiSyntheticEngineOils";
import { MineralOilBasedEngineOils } from "./Pages/Exol/ProductsExolAutomotive/MineralOilBasedEngineOils";
import { GearTransmissionOils } from "./Pages/Exol/ProductsExolAutomotive/GearTransmissionOils";
import { Greases } from "./Pages/Exol/ProductsExolAutomotive/Greases";
import { TwoStrokeMotorcycleOils } from "./Pages/Exol/ProductsExolAutomotive/TwoStrokeMotorcycleOils";
import { FourStrokeMotorcycleOils } from "./Pages/Exol/ProductsExolAutomotive/FourStrokeMotorcycleOils";
import { AncillaryProducts } from "./Pages/Exol/ProductsExolAutomotive/AncillaryProducts";
import { Commercial } from "./Pages/Exol/Commercial";
import { SliderOtherMark } from "./components/SliderMark/SliderOtherMark";
import { LowSapsEngineOilsCo } from "./Pages/Exol/ProductsExolCommercial/LowSapsEngineOilsCo";
import { FullySemiSyntheticEngineOilsCo } from "./Pages/Exol/ProductsExolCommercial/FullySemiSyntheticEngineOilsCo";
import { MineralOilBasedEngineOilsCo } from "./Pages/Exol/ProductsExolCommercial/MineralOilBasedEngineOilsCo";
import { GearTransmissionOilsCo } from "./Pages/Exol/ProductsExolCommercial/GearTransmissionOilsCo";
import { HydraulicOilsCo } from "./Pages/Exol/ProductsExolCommercial/HydraulicOilsCo";
import { GreasesCo } from "./Pages/Exol/ProductsExolCommercial/GreasesCo";
import { AncillaryProductsCo } from "./Pages/Exol/ProductsExolCommercial/AncillaryProductsCo";
import { Industrial } from "./Pages/Exol/Industrial";
import { HydraulicOilsIn } from "./Pages/Exol/ProductsExolIndustrial/HydraulicOilsIn";
import { GearOilsIn } from "./Pages/Exol/ProductsExolIndustrial/GearOilsIn";
import { MachineSlidewayOilsIn } from "./Pages/Exol/ProductsExolIndustrial/MachineSlidewayOilsIn";
import { MultiFunctionalOilsIn } from "./Pages/Exol/ProductsExolIndustrial/MultiFunctionalOilsIn";
import { CompressorOilsIn } from "./Pages/Exol/ProductsExolIndustrial/CompressorOilsIn";
import { TurbineOilsIn } from "./Pages/Exol/ProductsExolIndustrial/TurbineOilsIn";
import { SolubleCuttingGrindingFluidsIn } from "./Pages/Exol/ProductsExolIndustrial/SolubleCuttingGrindingFluidsIn";
import { NGSolubleMetalWorkingFluidsIn } from "./Pages/Exol/ProductsExolIndustrial/NGSolubleMetalWorkingFluidsIn";
import { NeatCuttingGrindingOilsIn } from "./Pages/Exol/ProductsExolIndustrial/NeatCuttingGrindingOilsIn";
import { RustPreventativesIn } from "./Pages/Exol/ProductsExolIndustrial/RustPreventativesIn";
import { FormingOilsIn } from "./Pages/Exol/ProductsExolIndustrial/FormingOilsIn";
import { QuenchantsIn } from "./Pages/Exol/ProductsExolIndustrial/QuenchantsIn";
import { GreasesIn } from "./Pages/Exol/ProductsExolIndustrial/GreasesIn";
import { AncillaryProductsIn } from "./Pages/Exol/ProductsExolIndustrial/AncillaryProductsIn";
import { FoodGradeOilLubricantsIn } from "./Pages/Exol/ProductsExolIndustrial/FoodGradeOilLubricantsIn";




function App() {
  
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "index.title",
    defaultMessage: "OilFluid: Solución para proteger tus motores", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "index.description",
    defaultMessage:
      "Protege tu motor con aditivos para gasolina, combustible, gas, petróleo, diésel y lubricante. Protección antimicrobiana. Evita lodo y corrosión.", //Cambia Descripcion Pagina
  });

  return (
    <BrowserRouter>
      <LanguageProvider>
        {" "}
        {/* Languages */}
        <HelmetProvider>
          <div className="App">
            <Helmet>
              <title>{pageTitle}</title>
              <meta name="description" content={description} />
            </Helmet>
            <NavBar />
            <Routes>
              <Route
                path={"/es/productos/aditivos-para-combustibles-aviacion"}
                element={<Aviation />}
                exact
              />
              <Route
                path={"/en/products/aviation-fuel-additives"}
                element={<Aviation />}
                exact
              />
              <Route
                path="/es/productos/aditivos-para-combustibles-diesel"
                element={<Diesel />}
                exact
              />
              <Route
                path="/en/products/diesel-fuel-additives"
                element={<Diesel />}
                exact
              />
              <Route
                path="/es/productos/aditivos-para-combustibles-gasolina"
                element={<Gasoline />}
                exact
              />
              <Route
                path="/en/products/gasoline-fuel-additives"
                element={<Gasoline />}
                exact
              />
              <Route
                path="/es/productos/accesorios"
                element={<Accessories />}
                exact
              />
              <Route
                path="/en/products/accessories"
                element={<Accessories />}
                exact
              />
              {/* Pages Products Aviation */}
              <Route
                path="/es/productos/biobor-jf-aviation"
                element={<BioborJfAviation />}
                exact
              />
              <Route
                path="/en/products/biobor-jf-aviation"
                element={<BioborJfAviation />}
                exact
              />
              <Route
                path="/es/productos/lubribor"
                element={<Lubribor />}
                exact
              />
              <Route
                path="/en/products/lubribor"
                element={<Lubribor />}
                exact
              />
              <Route
                path="/es/productos/turboline"
                element={<Turboline />}
                exact
              />
              <Route
                path="/en/products/turboline"
                element={<Turboline />}
                exact
              />
              <Route
                path="/es/productos/hum-bug-detector-kit"
                element={<HumBug />}
                exact
              />
              <Route
                path="/en/products/hum-bug-detector-kit"
                element={<HumBug />}
                exact
              />
              <Route
                path="/es/productos/fuelstat-plus"
                element={<FuelStat />}
                exact
              />
              <Route
                path="/en/products/fuelstat-plus"
                element={<FuelStat />}
                exact
              />
              {/* Pages Products Diesel */}
              <Route
                path="/es/productos/biobor-jf-diesel"
                element={<BioborjfDiesel />}
                exact
              />
              <Route
                path="/en/products/biobor-jf-diesel"
                element={<BioborjfDiesel />}
                exact
              />
              <Route
                path="/es/productos/biobor-md"
                element={<BioborMd />}
                exact
              />
              <Route
                path="/en/products/biobor-md"
                element={<BioborMd />}
                exact
              />
              <Route
                path="/es/biobor-dc-plus-cetane"
                element={<BioborDc />}
                exact
              />
              <Route
                path="/en/biobor-dc-plus-cetane"
                element={<BioborDc />}
                exact
              />
              <Route
                path="/es/biobor-dc-plus-cetane-winter"
                element={<BioborDcWinter />}
                exact
              />
              <Route
                path="/en/biobor-dc-plus-cetane-winter"
                element={<BioborDcWinter />}
                exact
              />
              <Route
                path="/es/biobor-coldflo-lubribor"
                element={<ColdflowLubribor />}
                exact
              />
              <Route
                path="/en/biobor-coldflo-lubribor"
                element={<ColdflowLubribor />}
                exact
              />
              {/* Pages Products Gasoline */}
              <Route path="/es/biobor-eb" element={<BioborEB />} exact />
              <Route path="/en/biobor-eb" element={<BioborEB />} exact />
              <Route path="/es" element={<Home />} exact />
              <Route path="/en" element={<Home />} exact />
              {/* Exol Automotive*/}
              <Route
                path="/es/exol-lubricantes/productos/automotriz"
                element={<Automotive />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/automotive"
                element={<Automotive />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/automotriz/aceites-de-motor-totalmente-sinteticos-de-bajo-contenido-de-cenizas-sulfatadas"
                element={<LowSAPSFully />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/automotive/low-saps-fully-synthetic-engine-oils"
                element={<LowSAPSFully />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/automotriz/aceites-de-motor-totalmente-sinteticos"
                element={<FullySyntheticEngineOils />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/automotive/fully-synthetic-engine-oils"
                element={<FullySyntheticEngineOils />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/automotriz/aceites-de-motor-semisinteticos"
                element={<SemiSyntheticEngineOils />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/automotive/semi-synthetic-engine-oils"
                element={<SemiSyntheticEngineOils />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/automotriz/aceites-de-motor-a-base-de-aceite-mineral"
                element={<MineralOilBasedEngineOils />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/automotive/mineral-oil-based-engine-oils"
                element={<MineralOilBasedEngineOils />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/automotriz/aceites-para-transmisiones-de-engranajes"
                element={<GearTransmissionOils />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/automotive/gear-transmission-oils"
                element={<GearTransmissionOils />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/automotriz/grasas"
                element={<Greases />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/automotive/greases"
                element={<Greases />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/automotriz/aceites-para-motocicletas-de-2-tiempos"
                element={<TwoStrokeMotorcycleOils />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/automotive/2-stroke-motorcycle-oils"
                element={<TwoStrokeMotorcycleOils />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/automotriz/aceites-para-motocicletas-de-4-tiempos"
                element={<FourStrokeMotorcycleOils />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/automotive/4-stroke-motorcycle-oils"
                element={<FourStrokeMotorcycleOils />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/automotriz/productos-complementarios"
                element={<AncillaryProducts />}
                exact
              />
              <Route
                path="/en/exol-lubricants/automotive/ancillary-products"
                element={<AncillaryProducts />}
                exact
              />
              {/* Exol Commercial*/}
              <Route
                path="/es/exol-lubricantes/productos/comercial"
                element={<Commercial />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/commercial"
                element={<Commercial />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/comercial/aceites-de-motor-de-baja-emision-de-cenizas"
                element={<LowSapsEngineOilsCo />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/commercial/low-saps-engine-oils"
                element={<LowSapsEngineOilsCo />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/comercial/aceites-de-motor-semisinteticos-y-totalmente-sinteticos"
                element={<FullySemiSyntheticEngineOilsCo />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/commercial/fully-semi-synthetic-engine-oils"
                element={<FullySemiSyntheticEngineOilsCo />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/comercial/aceites-para-motores-basados-en-aceite-mineral"
                element={<MineralOilBasedEngineOilsCo />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/commercial/mineral-oil-based-engine-oils"
                element={<MineralOilBasedEngineOilsCo />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/comercial/aceites-para-engranajes-y-transmisiones"
                element={<GearTransmissionOilsCo />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/commercial/gear-transmission-oils"
                element={<GearTransmissionOilsCo />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/comercial/aceites-hidraulicos"
                element={<HydraulicOilsCo />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/commercial/hydraulic-oils"
                element={<HydraulicOilsCo />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/comercial/grasas"
                element={<GreasesCo />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/commercial/greases"
                element={<GreasesCo />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/comercial/productos-complementarios"
                element={<AncillaryProductsCo />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/commercial/ancillary-products"
                element={<AncillaryProductsCo />}
                exact
              />
              {/* Exol Industrial*/}
              <Route
                path="/es/exol-lubricantes/productos/industrial"
                element={<Industrial />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial"
                element={<Industrial />}
                exact
              />
               <Route
                path="/es/exol-lubricantes/productos/industrial/aceites-hidraulicos"
                element={<HydraulicOilsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/hydraulic-oils"
                element={<HydraulicOilsIn />}
                exact
              />
               <Route
                path="/es/exol-lubricantes/productos/industrial/aceites-para-engranajes"
                element={<GearOilsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/gear-oils"
                element={<GearOilsIn />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/industrial/aceites-para-maquinas-y-guias-de-deslizamiento"
                element={<MachineSlidewayOilsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/machine-slideway-oils"
                element={<MachineSlidewayOilsIn />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/industrial/aceites-multifuncionales"
                element={<MultiFunctionalOilsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/multi-functional-oils"
                element={<MultiFunctionalOilsIn />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/industrial/aceites-para-compresores"
                element={<CompressorOilsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/compressor-oils"
                element={<CompressorOilsIn />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/industrial/aceites-para-turbinas"
                element={<TurbineOilsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/turbine-oils"
                element={<TurbineOilsIn />}
                exact
              />
               <Route
                path="/es/exol-lubricantes/productos/industrial/fluidos-solubles-de-corte-y-rectificado"
                element={<SolubleCuttingGrindingFluidsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/soluble-cutting-grinding-fluids"
                element={<SolubleCuttingGrindingFluidsIn />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/industrial/fluidos-solubles-de-trabajo-de-metales-de-nueva-generacion"
                element={<NGSolubleMetalWorkingFluidsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/new-generation-soluble-metalworking-fluids"
                element={<NGSolubleMetalWorkingFluidsIn />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/industrial/aceites-puros-para-corte-y-rectificado"
                element={<NeatCuttingGrindingOilsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/neat-cutting-grinding-oils"
                element={<NeatCuttingGrindingOilsIn />}
                exact
              />
               <Route
                path="/es/exol-lubricantes/productos/industrial/preventivos-de-oxido"
                element={<RustPreventativesIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/rust-preventatives"
                element={<RustPreventativesIn />}
                exact
              />
               <Route
                path="/es/exol-lubricantes/productos/industrial/aceites-de-formado"
                element={<FormingOilsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/forming-oils"
                element={<FormingOilsIn />}
                exact
              />
               <Route
                path="/es/exol-lubricantes/productos/industrial/agentes-de-temple"
                element={<QuenchantsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/quenchants"
                element={<QuenchantsIn />}
                exact
              />
               <Route
                path="/es/exol-lubricantes/productos/industrial/grasas"
                element={<GreasesIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/greases"
                element={<GreasesIn />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/industrial/productos-complementarios"
                element={<AncillaryProductsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/ancillary-products"
                element={<AncillaryProductsIn />}
                exact
              />
              <Route
                path="/es/exol-lubricantes/productos/industrial/aceites-y-lubricantes-de-grado-alimentario"
                element={<FoodGradeOilLubricantsIn />}
                exact
              />
              <Route
                path="/en/exol-lubricants/products/industrial/food-grade-oils-and-lubricants"
                element={<FoodGradeOilLubricantsIn />}
                exact
              />
              <Route path="/" element={<Home />} exact />
            
              <Route path="*" element={<NotFound />} />
            </Routes>
            <SliderOtherMark />
            <Footer />
          </div>
        </HelmetProvider>
      </LanguageProvider>
    </BrowserRouter>
  );
}

function Home() {
  const contactRef = useRef(null);
  const productsRef = useRef(null);
  const location = useLocation();

  /* Desplazamiento Automatico hacia los componentes */

  useEffect(() => {
    if (
      (location.hash === "#contact" || location.hash === "#products") &&
      contactRef.current
    ) {
      setTimeout(() => {
        if (location.hash === "#contact") {
          contactRef.current.scrollIntoView({ behavior: "smooth" });
        } else if (location.hash === "#products" && productsRef.current) {
          productsRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, [location]);

  return (
    <>
      <Banner />
      <div ref={productsRef} id="products">
        <Products />
      </div>
      <Info1 />
      <Info2 />
      <Info3 />
      <div ref={contactRef} id="contact">
        <Contact />
      </div>
    </>
  );
}

export default App;
