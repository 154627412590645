import { Container, Row, Col } from "react-bootstrap";
// import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/oilfluid_logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import { FormattedMessage, useIntl } from "react-intl";

export const Footer = () => {
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando
  return (
    <footer className="footer">
      <Container className="container-footer">
        <Row className="row1-footer">
          {/* <MailchimpForm /> */}
          {/* <Col size={12} sm={3}>
            <img src={logo} alt="Logo" />
            <p>
              TECHNO STANDARD SAC Tiene como socio estratégico a TRIBOLOGIK®
              LABORATORIO DE ANALISIS DE LUBRICANTES que nos permite tener a una
              amplia gama de servicios de Mantenimiento Industrial para
              industrias en general.
            </p>
          </Col> /Para cuando desee agregar otra columna*/}
          <Col size={12} sm={3} className="colfooter4">
            <h3>
              {" "}
              <FormattedMessage
                id="footer.row1-col1-h3"
                defaultMessage="PRODUCTOS"
              />
            </h3>
            <ul>
              <li>
                <a href={intl.formatMessage({ id: "aviation.products-url" })}>
                  <FormattedMessage
                    id="footer.aviation"
                    defaultMessage="Aviacion"
                  />
                </a>
              </li>
              <li>
                <a href={intl.formatMessage({ id: "diesel.products-url" })}>
                  <FormattedMessage
                    id="footer.diesel"
                    defaultMessage="Diesel"
                  />
                </a>
              </li>
              <li>
                <a href={intl.formatMessage({ id: "gasoline.products-url" })}>
                  <FormattedMessage
                    id="footer.gasoline"
                    defaultMessage="Gasolina"
                  />
                </a>
              </li>
              <li>
                <a href={intl.formatMessage({ id: "accessories.products-url" })}>
                  <FormattedMessage
                    id="footer.accessories"
                    defaultMessage="Accesorios"
                  />
                </a>
              </li>
            </ul>
          </Col>
          <Col size={12} sm={3} className="colfooter1">
            <h3>
              <FormattedMessage
                id="footer.row1-col2-h3"
                defaultMessage="DIRECCION USA"
              />
            </h3>
            <p>
              <strong>TECHNO STANDARD CORP.</strong>
              <i className="fa-solid fa-location-dot"></i>  83 Lewis St <br />{" "}
                  Perthamboy NJ 08066 <br />
              <i className="fa-solid fa-phone"></i> (609) 4542471
              <br />
              <i className="fa-solid fa-envelope"></i>
              <span className="email-footer"> sales@oilfluid.com</span> <br />
            </p>
          </Col>
          <Col size={12} sm={3} className="colfooter2">
            <h3>
              <FormattedMessage
                id="footer.row1-col3-h3"
                defaultMessage="DIRECCION PERU"
              />
            </h3>
            <p>
              <strong>TECHNO STANDARD CORP.</strong>
              <br />
              <i className="fa-solid fa-location-dot"></i>  Calle Domingo
              Casanova 337 <br />
                  Lima 15046 - Peru <br />
              <i className="fa-solid fa-phone"></i> 761-2328
              <br />
              <i className="fa-brands fa-whatsapp"></i> +51 985250146 <br />
              <i className="fa-solid fa-envelope"></i> 
              <span className="email-footer">sales@oilfluid.com</span>
            </p>
          </Col>
          <Col size={12} sm={3} className="text-center text-sm-end colfooter3">
            <img src={logo} alt="Logo" className="logo-company2" />
            <br />
            <h4>
              <FormattedMessage
                id="footer.row1-col4-h4"
                defaultMessage="SIGUENOS"
              />
            </h4>
            <div className="social-icon">
              <a href="https://www.linkedin.com" target="alt_">
                <img src={navIcon1} alt="icon-linkedin" />
              </a>
              <a href="https://www.facebook.com/oilfluid/" target="alt_">
                <img src={navIcon2} alt="icon-facebook" />
              </a>
              <a href="https://www.instagram.com/" target="alt_">
                <img src={navIcon3} alt="icon-instagram" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="copyright-footer">
        <FormattedMessage
          id="footer.copyright"
          defaultMessage="© 2023 Todos los derechos reservados TECHNO STANDARD CORP.| "
        />
        Developer <a href="https://www.linkedin.com/in/mr-config" target="alt_" className="mr-config">Mr-Config</a> |
      </div>
    </footer>
  );
};
