import React, { useContext } from "react";
import "../../assets/css/pages/ItemsBiobor/itemsBiobor.css";
import { Container, Row, Col } from "react-bootstrap";
// import listProductsAviation from "./listProductsAviation";
import { Products } from "../../components/Products";
import { useAuth0 } from "@auth0/auth0-react";
import FuelStatImg from "../../assets/img/aviation/fuelstat-plus/biobor_fuelstat-plus.webp";
/* Imagenes PDF */
import sdsEn from "../../assets/img/aviation/fuelstat-plus/fuelstat_safety_data_sheet_pdf.webp";
// import sdsEs from "../../assets/img/aviation/biobor-jf-aviation/product_bioborjf_SDS_ES.avif";
import specSheetEn from "../../assets/img/aviation/fuelstat-plus/fuelstat_specification_sheets_pdf.webp";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
// import specSheetEs from "../../assets/img/aviation/biobor-jf-aviation/product_bioborjf_spec_sheet_ES.avif";

export const FuelStat = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "fuelstat.title",
    defaultMessage:
      "Fuelstat Plus: Detección Rápida de Microorganismos en Combustibles | OilFluid", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para description */

  const description = intl.formatMessage({
    id: "fuelstat.description",
    defaultMessage:
      "Descubre FUELSTAT, la prueba inmunoensayo de anticuerpos que identifica microorganismos dañinos en sistemas de combustible. Resultados en 15 minutos, sin equipo adicional costoso. La app descargable gratuita brinda verificación de campo precisa y análisis detallado.", //Cambia Descripcion Pagina
  });

  /* Link PDF */

  const sdsEnLink =
    "https://drive.google.com/file/d/1hBlR8gi8tnAbAMRXGJ4FjB7Wg9Za8M8E/view";
  //   const sdsEsLink =
  //     "https://drive.google.com/file/d/1H_ArZU3DnxsK0AOGOLc7UMVprBs8754p/view";
  //   const specSheetEsLink =
  //     "https://drive.google.com/file/d/1-gZ56QbaoyjRCne0JSVD7wtAKSgi9Hhk/view";
  const specSheetEnLink =
    "https://drive.google.com/file/d/1NM5JOxu8fymZBMj9VCDvbi7gsYeOtw0_/view";

  return (
    <section className="itemsBiobor" id="fuelstat">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-itemsBiobor">
        <div className="head-itemsBiobor-img"></div>
        <h1 className="head-itemsBiobor-text">
          <FormattedMessage id="fuelstat.h1" defaultMessage="FUELSTAT® Plus" />
        </h1>
      </div>
      <Container>
        <Row className="itemsBiobor-row1">
          <Col size={12} sm={8}>
            <h2>
              <FormattedMessage
                id="fuelstat.row1-col1-h2x1"
                defaultMessage="Prueba Rápida de Contaminación Microbiana"
              />
            </h2>
            <ul>
              <li>
                <FormattedMessage
                  id="fuelstat.row1-col1-ulli1"
                  defaultMessage="Para jet y diesel"
                />
              </li>
              <li>
                <FormattedMessage
                  id="fuelstat.row1-col1-ulli2"
                  defaultMessage="Elimina reparaciones costosas y tiempo de inactividad"
                />
              </li>
              <li>
                <FormattedMessage
                  id="fuelstat.row1-col1-ulli3"
                  defaultMessage="Resultados semicuantitativos"
                />
              </li>
              <li>
                <FormattedMessage
                  id="fuelstat.row1-col1-ulli4"
                  defaultMessage="Resultados de campo en solo 15 minutos"
                />
              </li>
              <li>
                <FormattedMessage
                  id="fuelstat.row1-col1-ulli5"
                  defaultMessage="Fácil de usar, no requiere ningún equipo de gasto"
                />
              </li>
              <li>
                <FormattedMessage
                  id="fuelstat.row1-col1-ulli6"
                  defaultMessage="Aplicación de teléfono gratuita para la verificación precisa de la prueba y la generación de informes instantáneos"
                />
              </li>
            </ul>
            <h2>
              <FormattedMessage
                id="fuelstat.row1-col1-h2x2"
                defaultMessage="Descripción del Producto"
              />
            </h2>
            <p>
              <FormattedMessage
                id="fuelstat.row1-col1-p1"
                defaultMessage="Descubre FUELSTAT, una prueba de inmunoensayo de anticuerpos que identifica microorganismos dañinos en los sistemas de combustible. A diferencia de otros kits de pruebas, FUELSTAT ofrece resultados en solo 15 minutos, sin necesidad de esperas prolongadas ni equipo especializado. Además, nuestra aplicación de teléfono gratuita brinda resultados precisos en el campo. Obtén informes detallados y trazabilidad de las pruebas con la versión completa de la aplicación. Asegura la calidad de tu combustible con FUELSTAT."
              />
            </p>
          </Col>
          <Col size={12} sm={4}>
            <img
              src={FuelStatImg}
              alt="Biobor JF Aviation: Detección fácil, precisa y rápida del crecimiento microbiano en el combustible"
            />
          </Col>
        </Row>
        <Row className="itemsBiobor-row2">
          <h2>
            <FormattedMessage
              id="turboline.row2-h2"
              defaultMessage="Recursos de FUELSTAT®Plus (PDF)"
            />
          </h2>
          <br />
          <div className="notice-to-register">
            {!isAuthenticated ? (
              <div className="notice1-to-register">
                <span>
                  ( <i className="fa-solid fa-lock fa-lock2"></i>
                </span>{" "}
                <span>
                  <FormattedMessage
                    id="notice.to-register1"
                    defaultMessage="Registrarte"
                  />{" "}
                  <button
                    onClick={loginWithRedirect}
                    className="noticetoregister-aqui"
                  >
                    <FormattedMessage
                      id="notice.to-register2"
                      defaultMessage="AQUI"
                    />
                  </button>{" "}
                  <FormattedMessage
                    id="notice.to-register3"
                    defaultMessage="para descargar cualquier informacion )"
                  />{" "}
                  )
                </span>
              </div>
            ) : (
              <div className="notice2-to-register">
                <span>
                  ( <i className="fa-solid fa-lock-open"></i>
                </span>
                <span>
                   {" "}
                  <FormattedMessage
                    id="notice.to-register4"
                    defaultMessage="Descarga cualquier informacion haciendo clic en la imagen )"
                  />{" "}
                  )
                </span>
              </div>
            )}
          </div>
          <br />
          <br />
        </Row>
        <div className="itemsBiobor-row3">
          <div className="container-lock-on-off">
            <h4>
              SDS (EN) <br />
              (Safety Data Sheet)
            </h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={sdsEn}
                  alt="FuelStat Plus SDS - Safety Data Sheet PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={sdsEnLink} target="alt_">
                  <img
                    src={sdsEn}
                    alt="FuelStat Plus SDS - Safety Data Sheet PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                      <p>Click para Descargar</p>
                    </div> */}
              </div>
            )}
          </div>
          <div className="container-lock-on-off">
            <h4>Specification Sheets (EN)</h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={specSheetEn}
                  alt="FuelStat Plus - Specification Sheets PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={specSheetEnLink} target="alt_">
                  <img
                    src={specSheetEn}
                    alt="FuelStat Plus - Specification Sheets PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                      <p>Click para Descargar</p>
                    </div> */}
              </div>
            )}
          </div>
        </div>
        {/* {!isAuthenticated ? (
              <Products /> ) : (
                      <Info1 />
                    )} */}

        {/* <Row className="biobor-jf-aviation-row4">
            <h2>¿Por qué usar Biobor JF Aviation?</h2>
            <p>
              Biobor JF ha sido el estándar mundial para el tratamiento del
              crecimiento microbiano en el combustible para aviones de aviación
              desde 1965. Originalmente desarrollado para su uso en las industrias
              de aviación comercial y militar, Biobor JF ha seguido brindando un
              historial de confiabilidad y eficacia comprobadas en numerosas
              industrias para más de 50 años. Para agregar a este impresionante
              pedigrí, Biobor JF es el único biocida de combustible de aviación
              recomendado por su nombre por numerosos fabricantes importantes de
              motores de turbina y fuselajes en todo el mundo.
            </p>
            <h4>El ÚNICO biocida de combustible disponible para la aviación:</h4>
            <p>
              Solo ha habido dos biocidas aprobados en la historia de los
              combustibles de aviación. Ahora, solo Biobor JF® está disponible.
              Después de varios incidentes de aeronavegabilidad causados por el
              otro biocida previamente aprobado que se retiró permanentemente del
              mercado, Biobor JF® es el único biocida de combustible disponible en
              todo el mundo.
            </p>
            <h4>La elección obvia para eliminar el crecimiento microbiano:</h4>
            <p>
              Cuando se discuten problemas relacionados con el combustible de
              aviación, se sabe ampliamente que el crecimiento microbiano es la
              causa más común de fallas en los motores relacionadas con el
              combustible. Intrínsecamente, el combustible de aviación y los
              tanques de combustible tienen la tendencia a acumular agua, ya sea
              por condensación del tanque, agua arrastrada por el suministro de
              combustible o simplemente por recibir un lote de combustible
              defectuoso. Los hum-bugs viven en la fase de agua de un tanque de
              combustible y se alimentan del combustible desarrollando esteras
              gelatinosas oscuras y viscosas. A medida que crecen estas colonias
              de bacterias y hongos (potencialmente a una tasa de duplicación cada
              20 minutos), pueden obstruir rápidamente los filtros de combustible
              y provocar acumulaciones excesivas de crecimiento. Además, sus
              productos de desecho son una de las principales causas de corrosión
              en tanques de combustible y componentes metálicos. Con el uso
              continuado de Biobor JF, se eliminará el crecimiento microbiano del
              sistema de combustible, lo que dará lugar a un sistema de
              combustible estéril, lo que extenderá la vida útil de los filtros de
              combustible y evitará problemas de corrosión.
            </p>
          </Row> */}
        <Products />
      </Container>
    </section>
  );
};
