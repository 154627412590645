const listProductsLowSapsEngineOilsCo = [

    { option: "maplowsapsengineoilsco1", name: "namelowsapsengineoilsco1", imgUrl: "https://dsm01pap002files.storage.live.com/y4mNUNdjN7cdiA1hH60LQOcZJbUVNIXVEmbInAr_j_ZyYwxv5szlp1onx_aWC5alsiwCNYMLvWdry6p7ToMtiXr6PekFP281B8lTYZIeqSEMQE94I1ZZ5SuaQ6hsv4ZyaPATDLZVsHEflAfU692DWgtCb6310gnP3-stJ2nqTvRO8_Rd908esYfuF9yuxIPeMNn?width=600&height=600&cropmode=none", description: "", url:"lowsapsengineoilsco.url1"},
    { option: "maplowsapsengineoilsco2", name: "namelowsapsengineoilsco2", imgUrl: "https://dsm01pap002files.storage.live.com/y4m6-WRRIAo0I5xdtQpekGu3SABG8rUjMpv2_TYOmqLQKQgsgdc9ZAkePS0FkiEVj8ohVXaTQdAX03jCuny1cfOHWgvCDPOzivJg8i1ZCLBzErYABl7LS6qRRkb0x8A6eaYg6BZzwlxEhLMM3-p3L32bw-Pbv3PBrLRsmo0F-WGQ5nLwkdvXBjwCxgpBm5gz8Kx?width=600&height=600&cropmode=none", description: "", url:"lowsapsengineoilsco.url2" },
    { option: "maplowsapsengineoilsco3", name: "namelowsapsengineoilsco3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mGZwP6j25aCgMmabQEbx0XSYGn1mC_Fv8EYHFbZmvBhMTqBgsCrHhN0mtpFKu1KSpQchs_SFxlA-nj_2e31Ngt5wpGmmXNKYiQCuO43Q-0XHdi0dQMQdKIF7efkwzA8ghb6s-BtHbhORSjDPGF5ZWDDC7e0b9cbytj8KB0c3Vpoeinl_N2mVLoYuFXz_ISHHe?width=600&height=600&cropmode=none", description: "", url:"lowsapsengineoilsco.url3" },
    { option: "maplowsapsengineoilsco4", name: "namelowsapsengineoilsco4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mMAGicvrwNsL3tbAfWLC9aNyHc0F55KGDZ6SC-FGpFGeeYpDwHeOYZvB_sz1VL3zUddGPpzspyyES_o97quioCJwZR4vBrtVQo9dNBpeSsjehBQl5FK9-cCC5LuouHl_mrdKkoFWR3l9rmpbLWhQQ8tQhnA2iecF1iEUi6KzEyNbw-pBuJ1w6QLQHpmDJhLZK?width=600&height=600&cropmode=none", description: "", url:"lowsapsengineoilsco.url4" },
    { option: "maplowsapsengineoilsco5", name: "namelowsapsengineoilsco5", imgUrl: "https://dsm01pap002files.storage.live.com/y4mKpGTSUHqF_O8s1TkSb9NIXisMD1ttA0HhXDPzxqz1oh7GxvLjXuaiXeaMRzFMQZLpW3MIQPsM4xUC8hlwpSi_-kvgnC2oGQFZBY4_0OSB2R-VU703rMPVAESkxAzzkPLtQcmIBOYgedT7LRBjvm_NxC4rGaypZ5FgOfjSILdJvMfmRWeRBhvLe6qumi1fJvU?width=600&height=600&cropmode=none", description: "", url:"lowsapsengineoilsco.url5" },
    { option: "maplowsapsengineoilsco6", name: "namelowsapsengineoilsco6", imgUrl: "https://dsm01pap002files.storage.live.com/y4mxaDhK0rb85yuFK5hY4MyMw-vi4d5HLydujlqqvrVeK4ENF8hg49eyEWP3vTSvBB-X91ACPPQeddAQO051mFdyqiJ2jbx4S0niijRvCTvcui9B0mtabbJt1yjqd_9Rtx7GmAwx_rzHPaZwGEgDDwBvYQcZIlWDWX1DDeJgz1GlOB-xKuTY_eye1lV2zGhoX7S?width=600&height=600&cropmode=none", description: "", url:"lowsapsengineoilsco.url6" },
    
];
  
  export default listProductsLowSapsEngineOilsCo;