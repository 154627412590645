const listProductsGreases = [

    { option: "mapgreases1", name: "namegreases1", imgUrl: "https://dsm01pap002files.storage.live.com/y4mq8JUFWOKUM-GjkPkmOO2vbunopjkj2M3M1rooRS5HfLDENBYUTRy6TiRGf67Yi9OoXiw8MXjpQdeoEJEooGaVFG6drjdEFRnjVFM-EzynYEIAaGoJsMT8z4Rwpj98WXxIyJKFZZC2d_dv0JWQ4SJTkTMapyHa7_NP1ZRVWzTtP6EiA3j9L5jznv_CmiJagOs?width=600&height=600&cropmode=none", description: "(Z011)", url:"greases.url1"},
    { option: "mapgreases2", name: "namegreases2", imgUrl: "https://dsm01pap002files.storage.live.com/y4mHBZ5BhrSYA5tApknM0PVoJfpR4w5zaqvAiv6iiQHXQnXi2FPnAi0CDREy8ZOMUD6-uuIQEs6wVZ1wDScQAJ8GhEEQaGSyQ3yE2I56rlthEExownx-diIuLxkhJ2lBIQyPUGH1E2eLBl8ydDgHnS9J3YOWBYHzHhor4EnlwCt4fcd573VHWXsy6RGQkrtQbkG?width=600&height=600&cropmode=none", description: "GREASE (Z074)", url:"greases.url2" },
    { option: "mapgreases3", name: "namegreases3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mZYKeuVz0_U3csL5d4znApMm3Vpo_VQ8Sd6BtRFWv3IopVwCCct4TGaPUg5Dx0ozAnCXOcVwAXIcrB-YCNjQl--vNgu5wLxaioj5XMDinlO_QPjUCT2_9B642gmSvCq5W_PnGVx8fOMdco1-0RwqR14NeOux1vtylW_i8kvJPiJLOvTrjjFNPoJHFHiwIk9LM?width=600&height=600&cropmode=none", description: "EP2 (Z005)", url:"greases.url3" },
    { option: "mapgreases4", name: "namegreases4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mEVSHSsh3bHbl5lSPn1NMAfLCetbleeWiPmtNkgpOZOFHc2dFUPk4Y20K9XjjZy-r_MpbfKGx0YIKJ5SWRL0V3uxLbhJ0fyXI5Rx5m5YvbcRmZE1jS_OCDxrE7FI6ArWbqkbgsRbqW9qgp8aNQNGl9HDhnoeTKCB_JHpg9UKSkFN-9Vl9oqNty7dPAPrVDdYZ?width=600&height=600&cropmode=none", description: "EP2M (Z008)", url:"greases.url4" },
    { option: "mapgreases5", name: "namegreases5", imgUrl: "https://dsm01pap002files.storage.live.com/y4mOC5nHYP2PxdHotKTkTqwSM5uKTNwDB6mx8yezPbbXqL-2SGJlP0dmgjrMGDBoL_cB0eA63XU_acQD_oSAnuuhGO3Av0Q9CfAiaV4pjRb9DOtaDPnCIGu21mRQk51d9Hl5tEjDPnDMYYIWIYB6jjfLA0xzd3fVTx_q9fI5HRdFscEo3QzcGMjNq-r1E8V9QCn?width=600&height=600&cropmode=none", description: "GREASE (Z010)", url:"greases.url5" },
    { option: "mapgreases6", name: "namegreases6", imgUrl: "https://dsm01pap002files.storage.live.com/y4mB65hG4Glma6_UkmnrYIb9ClS6E299wSj19nYwCPLJFzTETAupIF38o05Uw2vL-a-PN2W-Kh5UDD1XW8gXU24KOO0AzDT7k-r0UEVSAhnhFBPWiqQFBDesT5cKmeEiOCdglnw0HhAXkQEikwzCwhz8f3u1wiK0UlUbsUtvQrvgcU9oZFLXgLbDcFiYonDx9aS?width=600&height=600&cropmode=none", description: "GREASE (Z035)", url:"greases.url6" },
    { option: "mapgreases7", name: "namegreases7", imgUrl: "https://dsm01pap002files.storage.live.com/y4mvw3Xw6CwRMowT2BDBWyjeMNYSuI3ZruHATxdh8PHLBpM5QsRHPqn4qIGBjxtY2DNseomcuiohQFgsyqBqo5H9lAElgbbyk3wjrtyEm-aKWeGtm8wgdfmE0-pRUVihJ7L1uFffSVUN7aRWThi8hKNWXueBpO6MVdw0w_uOtXwEMUKXPoNhbHlVA0uIlOB-xbf?width=600&height=600&cropmode=none", description: "(Z109)", url:"greases.url7" },
    { option: "mapgreases8", name: "namegreases8", imgUrl: "https://dsm01pap002files.storage.live.com/y4mnEPMW7czS7lq6YjeVUOOxr6Jw0Z9vPiaBORWoZ9tzrdmseOev1FafHL9CGA3l7AQYunZGXaQJNwRr-E9-3fpHLkQ4UElz0a6WwKnX4AWnNh7N3AJ9C4nfaEl-AB6O-1q56hELE1o_a3TomhKB8VpHTytTkc-b7YWjpgRwVZB7gakRq02Caj4IhujC3ydwer5?width=600&height=600&cropmode=none", description: "EP 2 (Z110)", url:"greases.url8" },
    { option: "mapgreases9", name: "namegreases9", imgUrl: "https://dsm01pap002files.storage.live.com/y4mYWIQ1tCQCXmM8gKEt_03SyLCXnJehzuYg7USdydKBfmAOUT0Jd6Y3d3uuOTRslry6nn6Fp5BjrN2ymA3Z7rU43HD4zQxYwo2ycyW674iSWo5_z1_46dPSHdN3xgxxKTrmkMv6YyFzwko0VsHCUZpTmpraDvVDj2kYoZ63bQTpw6EpaWTYPmTTED0HzRg2qtL?width=600&height=600&cropmode=none", description: "EP 2 (Z107)", url:"greases.url9" },

];
  
  export default listProductsGreases;