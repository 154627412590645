import React, { useContext } from "react";
import "../assets/css/pages/diesel.css";
import { Container, Row, Col } from "react-bootstrap";
import listProductsDiesel from "./listProductsDiesel";
import { Products } from "../components/Products";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

export const Diesel = () => {
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "diesel.title",
    defaultMessage:
      "Aditivos: ¿que es diesel y que hace el aditivo para diesel?", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "diesel.description",
    defaultMessage:
      "El aditivo diesel o tambien llamado gasoil, es un aditivo liquido para mejorar todo tipos de combustibles para autos", //Cambia Descripcion Pagina
  });

  return (
    <section className="diesel">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-diesel">
        <div className="head-diesel-img"></div>
        <h1 className="head-diesel-text">
          <FormattedMessage
            id="diesel.h1"
            defaultMessage="ADITIVOS PARA COMBUSTIBLE DIESEL"
          />
        </h1>
      </div>
      <Container>
        <Row className="product-diesel-row1">
          <h2>
            <FormattedMessage
              id="diesel.row1-h2x1"
              defaultMessage="¿Que es diésel?"
            />
          </h2>
          <p>
            <FormattedMessage
              id="diesel.row1-p1"
              defaultMessage="El diésel es un tipo de combustible utilizado en motores diésel, que son comunes en vehículos de transporte y maquinaria. Es un combustible fósil derivado del petróleo crudo y se caracteriza por su alta densidad de energía y eficiencia en el consumo. Los motores diésel funcionan mediante la compresión del aire en el cilindro, lo que provoca la ignición del combustible diésel sin la necesidad de una chispa como en los motores de gasolina. Esto hace que los motores diésel sean más eficientes en términos de consumo de combustible y emisiones de CO2 en comparación con los motores de gasolina."
            />
          </p>
          <h2>
            <FormattedMessage id="diesel.row1-h2x2"
            defaultMessage="¿Que hace el aditivo para diésel?" />
          </h2>
          <p>
            <FormattedMessage 
              id="diesel.row1-p2"
              defaultMessage="El aditivo para combustible diésel, también llamado gasoil, proporciona múltiples ventajas, incluyendo prevención de microorganismos y separación de agua en el combustible, mejora de la estabilidad y rendimiento del motor, reducción de depósitos y una vida útil prolongada del combustible almacenado. Además, contribuye a minimizar el impacto ambiental y mantener un funcionamiento eficiente del vehículo."
            />
          </p>
        </Row>
        <Row className="product-diesel-row2">
          <h2>
            <FormattedMessage
              id="diesel.row2-h2"
              defaultMessage="Productos Diésel"
            />
          </h2>
          <Col>
            <div className="container-listProductsDiesel">
              {listProductsDiesel.map((product, index) => (
                <a
                  key={index}
                  href={intl.formatMessage({
                    id: product.url,
                    defaultMessage: product.url,
                  })}
                  className="product-link-diesel"
                >
                  <div className="map-listProductDiesel">
                    <h2>{product.name}</h2>
                    <img src={product.imgUrl} alt="Biobor JF diesel" />
                    <p>
                      <FormattedMessage
                        id={product.option}
                        defaultMessage={product.option}
                      />
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="product-diesel-row3">
          <h2>
            <FormattedMessage
              id="diesel.row3-h2x1"
              defaultMessage="¿Por qué utilizar nuestros mejores aditivos para diésel?"
            />
          </h2>
          <p>
            <FormattedMessage
              id="diesel.row3-p1"
              defaultMessage="Dos cambios importantes en el combustible diésel moderno marcan una gran diferencia en la confiabilidad y la vida útil a largo plazo de su motor diésel. En primer lugar, el proceso utilizado para eliminar el azufre del combustible diésel elimina la lubricidad que afecta la vida útil de todas las piezas móviles de su sistema de suministro de combustible diésel. El combustible que históricamente no tenía problemas microbianos ahora requiere un tratamiento constante para evitar la formación de lodos inducidos por microbios causados por microorganismos que utilizan hidrocarburos. Los desechos de los insectos son altamente ácidos y pueden corroer los tanques de combustible, las líneas y los sistemas de medición de combustible en los motores. Para empeorar las cosas, la adición de biodiésel, también derivado de organismos vivos, ha agravado el problema de los insectos.
Tratar todo su combustible con Biobor JF ayudará a eliminar estos problemas microbianos mientras proporciona lubricación adicional al Diésel de Ultra Bajo Azufre (ULSD) para cumplir con las recomendaciones del fabricante original (OEM). Biobor ha sido el estándar mundial para el tratamiento de todo tipo de combustibles, incluyendo aditivos para combustible de gasolina, aditivos para petróleo, agentes antibacterianos de combustible, eliminación de lodos, eliminación de agua en combustible, estabilizadores de combustible, detección de agua en combustible, pruebas rápidas para bacterias y agua en combustible, biocidas, estabilizadores, y corrosión por agua."
            />
          </p>
          <h2>
            <span>
              <FormattedMessage
                id="diesel.row3-h2x2"
                defaultMessage="Ver Otros Productos"
              />
            </span>
          </h2>
        </Row>
        <Products />
      </Container>
    </section>
  );
};
