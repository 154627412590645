import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import listCategoryIndustrial from "./listCategoryIndustrial";
import "../../assets/css/pages/Exol/Category/Industrial/industrial.css";

export const Industrial = () => {

  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "industrial.title",
    defaultMessage: "Exol - Industrial | OilFluid", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "industrial.description",
    defaultMessage:
      "Exol Industrial: Amplia gama de aceites, grasas y fluidos especializados para mejorar el rendimiento, protección y durabilidad en aplicaciones industriales exigentes.", //Cambia Descripcion Pagina
  });

  return (
    <section className="industrial">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-industrial">
        <div className="head-industrial-img"></div>
        <div>
          <h1 className="head-industrial-text">
            <FormattedMessage
              id="industrial.h1"
              defaultMessage="LUBRICANTES INDUSTRIALES"
            />
          </h1>
        </div>
      </div>
      <Container>
        <Row className="product-industrial-row1">
          <p>
            <FormattedMessage
              id="industrial.row1-p1"
              defaultMessage="La amplia gama de productos industriales de Exol ha sido diseñada para cubrir todas las necesidades de talleres y plantas industriales. La gama incluye fluidos de corte y conformado de metales, tanto puros como miscibles en agua, lubricantes para herramientas de máquina y protectores contra el óxido."
            />
          </p>
        </Row>
        <Row className="product-industrial-row2">
          <h2>
            <FormattedMessage
              id="industrial.row2-h2"
              defaultMessage="Productos Industriales"
            />
          </h2>
          <Col>
            <div className="container-listproductsindustrial">
              {listCategoryIndustrial.map((product, index) => (
                <a
                  key={index}
                  href={intl.formatMessage({
                    id: product.url,
                    defaultMessage: product.url,
                  })}
                  className="product-link-industrial"
                  // onClick={scrollToTop}
                >
                  <div className="map-listproduct-industrial">
                    <h2>
                      <FormattedMessage
                        id={product.name}
                        defaultMessage={product.name}
                      />
                    </h2>
                    <img
                      src={product.imgUrl}
                      alt="Lubricantes Industriales"
                    />
                    <p>
                      <FormattedMessage
                        id={product.option}
                        defaultMessage={product.option}
                      />
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="product-industrial-row3">
          {/* <h2>
            <FormattedMessage
              id="industrial.row3-h2x1"
              defaultMessage="¿Por qué usar nuestros productos Exol para Automotriz?"
            />
          </h2>
          <ol>
            <li>
              <FormattedMessage
                id="industrial.ul-li1"
                defaultMessage="Protección del motor: Los lubricantes Exol Automotive están diseñados para brindar una protección superior al motor de tu vehículo. Contienen aditivos de alta calidad que ayudan a reducir la fricción, el desgaste y la corrosión, lo que prolonga la vida útil del motor y mejora su rendimiento."
              />
            </li>
            <li>
              <FormattedMessage
                id="industrial.ul-li2"
                defaultMessage="Mejor eficiencia del combustible: Los lubricantes Exol Automotive están formulados para ofrecer una lubricación eficiente y reducir la resistencia interna del motor. Esto puede resultar en una mejor eficiencia del combustible y, en última instancia, en un ahorro de costos a largo plazo."
              />
            </li>
            <li>
              <FormattedMessage
                id="industrial.ul-li3"
                defaultMessage="Mantenimiento adecuado: Utilizar los lubricantes adecuados es esencial para el mantenimiento adecuado de tu vehículo. Los lubricantes Exol Automotive están diseñados para cumplir con las especificaciones y recomendaciones de los fabricantes de automóviles, lo que garantiza un rendimiento óptimo y evita problemas de funcionamiento y desgaste prematuro."
              />
            </li>
            <li>
              <FormattedMessage
                id="industrial.ul-li4"
                defaultMessage="Amplia gama de productos: Exol Automotive ofrece una amplia gama de lubricantes para adaptarse a diferentes tipos de motores, transmisiones y sistemas. Ya sea que tengas un automóvil de pasajeros, un vehículo comercial o incluso una flota de vehículos, Exol tiene la solución adecuada para tus necesidades."
              />
            </li>
            <li>
              <FormattedMessage
                id="industrial.ul-li5"
                defaultMessage="Calidad y confiabilidad: Exol es reconocido en la industria por su calidad y confiabilidad. Sus lubricantes son fabricados utilizando los mejores ingredientes y tecnología de vanguardia, lo que garantiza un producto de alto rendimiento y durabilidad."
              />
            </li>
          </ol>
          <h2>
            <span>
              <FormattedMessage
                id="industrial.row3-h2x2"
                defaultMessage="Ver Otros Productos"
              />
            </span>
          </h2> */}
        </Row>
        {/* <Row className="product-industrial-row4">
          <h2>
            <FormattedMessage
              id="industrial.row4-h2"
              defaultMessage="Biobor JF® El único biocida disponible para uso en aviación."
            />
          </h2>
        </Row> */}
      </Container>
    </section>
  );
};
