const listProductsLowSAPSFully = [

    { option: "maplowsapsfully1", name: "namelowsapsfully1", imgUrl: "https://dsm01pap002files.storage.live.com/y4mHGYioPqcb-KtgnnzWm41hWqMl7fFecoEbVxTljPCuoQ3Jkr73fa2eZ8WWSkalw5biBVeb4xBtWmdRISWORawFxHpZlXDgQMOMDgezhhlbcvo7zouuP07CnL-g3FRi9Z4oC4knADP58h-N6MsFb0E7i2QFN9ZO_s8jUL1CXlJijhAUMpVbQe0-mV62pfRRGYj?width=600&height=600&cropmode=none", description: "C3 LSV 5W-30 (M373)", url:"lowsapsfully.url1"},
    { option: "maplowsapsfully2", name: "namelowsapsfully2", imgUrl: "https://dsm01pap002files.storage.live.com/y4m18bgLECJH0kn4OMOFolk1aoaCKe7skdCp6wZRvdw2rWL2pc2SEyYP2k2x-SA-216lNah6GjQKGssTClpejjjMcknJs42TyjSQMeihMCsxw7oJUrtpJjGyTjcDStXfxjzd94iofYFAqAySEaF_NlPaBw_GjcmTwiy0vBLp8r0jCn1V7fETXh3q-Ptt4ez30lt?width=600&height=600&cropmode=none", description: "C3 LS 5W-30 (M404)", url:"lowsapsfully.url2" },
    { option: "maplowsapsfully3", name: "namelowsapsfully3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mgLCsPPlNwRVUuJS2iRGGvZeV7-W47tSnvr2rFe2uYB-7mB9v65kC9Xh5jFiiGpQZi3USuJ7cqnJ2LU1mZas-D-OCY6Vvjh-j8b_ady2sD5o9hLTmgGpYNsxtEXLzMjml_6kQvlJkB9gr4HSwJ0nNYPbEEiyrb3fuJ65Vu_2YFpF2E0bnLZaBPQyh3K187ItZ?width=600&height=600&cropmode=none", description: "C3 LSM 5W-30 (M377)", url:"lowsapsfully.url3" },
    { option: "maplowsapsfully4", name: "namelowsapsfully4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mQ2HQnG107P51qfSQ_p_Pwnf1DN_GjCPhKnBGEmeJQ-ziVJAHipXnlMniJRdaoDKmMw2Zg_gZy-Ypsaf25bLP8ydKwGcmEIjvyB2N3DPFChQgtXknzZYUmW4adsA-ooQbTFRggosSpqL2O4VJ7x47sXVkHtLgknqbved77UG3jzCazTliNmDxElEBPtnk5C6J?width=600&height=600&cropmode=none", description: "C3 LSG 5W-30 (M409)", url:"lowsapsfully.url4" },
    { option: "maplowsapsfully5", name: "namelowsapsfully5", imgUrl: "https://dsm01pap002files.storage.live.com/y4m88t4UzzTXW0gzR_9NKhWB89tUDFBoqBlLogNiZynfAloEx3jpT32sh8CHxFGgF9cnto5FVHt-lO5hy_fANfgHUOm4-YavAJaGjAtHMB0_k1eLvF3_E8GUwzCR9fQlRhM0oGTH4F18aVTeLEGoXDykBOgo932AdrzJRXsy-38wmRaF0h-86j9yoE_Hz-xwq2v?width=600&height=600&cropmode=none", description: "C5 VC 0W-20 (M472)", url:"lowsapsfully.url5" },
    { option: "maplowsapsfully6", name: "namelowsapsfully6", imgUrl: "https://dsm01pap002files.storage.live.com/y4mmn3CTTq9WgLjcyXDo-Sdh2vbaLnc9JMbQTQ9JlaybSuUuvBar7S7Dd12jUu_A13FWuOeA8AJatnV18UARtLze6FT91BEbqa-ior-YRA6MS5ZOh29yB7NuKg62OlLe6CrwodWvl5AFOJCKLYeMnfNfiRcQCAjnFImQZkMvz0qEFn_6k10XO0wz4Vc-kZHtVPL?width=600&height=600&cropmode=none", description: "C1 LSZ 5W-30 (M413)", url:"lowsapsfully.url6" },
    { option: "maplowsapsfully7", name: "namelowsapsfully7", imgUrl: "https://dsm01pap002files.storage.live.com/y4m69YaNIZBjrU6eLL5ZdZSIPMA2uM4IH1YNMlb6shrV6H56lrNdMwtr5l6G3ZxwwqpkP89DLFvqEyZmsNJd6E3hHOE39DtbxmFywi0cE9HgNcJULLj_rYYwtNZG-roFqHWGzYQa_FXT1bi5Qwcr7FCjPC0-QmBXgdLVfpOI2AxoqxIFV9Gytr1vlVHYN8w32Pz?width=600&height=600&cropmode=none", description: "C2 LSF 0W-30 (M462)", url:"lowsapsfully.url7" },
    { option: "maplowsapsfully8", name: "namelowsapsfully8", imgUrl: "https://dsm01pap002files.storage.live.com/y4miCosm5naeBlOXnInePPNPTvDicf_cFVZTiGxtQj6aL7HEzZtsWqhq76y9Wir4ghmi40z2J8CNOLi997XekHHEiJQNEbFLI6RjR4VPEF_wPd1P0aaoI8U-qm7drvF7PFSiCCa8If-yG2lAXSmNOujkaFG9dM4Rcd0vf32hmbbUd5RFR9nhSV6OuwMZ_qGV1c3?width=600&height=600&cropmode=none", description: "C2 LSP 5W-30 (M397)", url:"lowsapsfully.url8" },
    { option: "maplowsapsfully9", name: "namelowsapsfully9", imgUrl: "https://dsm01pap002files.storage.live.com/y4mjWgbkrHB8XVwjmijJAJhs9g1mSc1YSUcQNaBRo_mr2wpzXn2N862EaoJab1Zr9NO3PzDxx2QWdzbeiEM4yKTUSO4my8yocDsJQuUsvt2mPHkEPNnK8hwY-BFReY-D2yVDdgZ2GcpXLN0xS54HE4lUGWJ_STKMhIZqSBIq17R5HNqinEJZ-zDY0wNJ5JaZzhL?width=600&height=600&cropmode=none", description: "C2 LSC 0W-30 (M471)", url:"lowsapsfully.url9" },
    { option: "maplowsapsfully10", name: "namelowsapsfully10", imgUrl: "https://dsm01pap002files.storage.live.com/y4m--j7M5BL-8GZMzFdz085kJm-ys6vhQNU4ZSe_EnC3z4bL-aHXUaFuzptdFqFmWRFLWjsE7eOBeSWsvkjv4nI8zoQxuxV29YDFYzC7zCW6NgnIuoyD4u04wkRQufwUaqi-3-prMyoSKvwZy9tx0WN7VL01_sPc6gur2hpnQEzsjVAU2WfXn_q6ZnrYJBpa5tY?width=600&height=600&cropmode=none", description: "C4 LSR 5W-30 (M414)", url:"lowsapsfully.url10" },
    { option: "maplowsapsfully11", name: "namelowsapsfully11", imgUrl: "https://dsm01pap002files.storage.live.com/y4mgKDZSmr_GMO_TH40bQI2_xmLeJbrTPGYYZMF7P_92kyga6fSrtDpaU7RKGrF5P6QUC5flqZkCr6a3rMEQ4lnsHsVCQsiefr96BEq3KEr_o03Hd6QOXt2dH2URGaIPTMuWzgK6mTwSb8mdrMXDaqOnkTFxJXSGiC88VFWGT0kjMJDsaGUHFDVhikRFPd4QNl2?width=600&height=600&cropmode=none", description: "C3 LSX 5W-40 (M412)", url:"lowsapsfully.url11" },
    { option: "maplowsapsfully12", name: "namelowsapsfully12", imgUrl: "https://dsm01pap002files.storage.live.com/y4mRDOsJCYzWdZlYEz3Xf1G_65TN50as8-aY1X40YYhEx0_DZv0QdGmvSUA9nlf4nB3AUCiU2uGHQ8ZzsHnQr3ynG3JwB371Knt7kWNuE2hsi24RSnxcCqxPxlMfMrKu3cDeIIIFFRdBHZgDkFVCxiLVMga72Y9EFo-WDzwNTVEeg1qDDqxNCyVswT3Wr0-f_-A?width=600&height=600&cropmode=none", description: "C2 LSH 0W-30 (M421)", url:"lowsapsfully.url12" },
    { option: "maplowsapsfully13", name: "namelowsapsfully13", imgUrl: "https://dsm01pap002files.storage.live.com/y4m4T8x8HNw3EPa9bMV8MR8x1klZZ_Jt3Z9ko9T9D5mm02MtI6mYuWipAQSFF-orPAkhgklVI1-VSZEEXqEbXs_dcRzLlYdMIDjoumrf6dM_gEXV6YBqHAmYi96WbEvZA4br-GtlCVl7flCVSr_OP5DXVfrqgeCEIjoZXNWcR1gR8qIT2yCYsJ8aGZ6ujF1nk8c?width=600&height=600&cropmode=none", description: "C2 C3 LSGM 5W-30 (M490)", url:"lowsapsfully.url13" },
    { option: "maplowsapsfully14", name: "namelowsapsfully14", imgUrl: "https://dsm01pap002files.storage.live.com/y4mfTwoHsiDd_s9yyzy2ffGE3gCS7PSBPX7pQeWcAz0Y14fI2TpfQ9nhe6Uzrf0ERvHGSkklPEeJMxwh6ixNGisg2-wWKGlOjAYlkMq8_4z5aaKfyj4Zi6KtK8Xb4-7byqTkZsfIHAwNLHXU61kOp8Pr3wSux2isM2ufKMLWd3GHtNm2GWRcdNMsDQF3G30BYrD?width=600&height=600&cropmode=none", description: "LSVW 0W-20 (M506)", url:"lowsapsfully.url14" },
    { option: "maplowsapsfully15", name: "namelowsapsfully15", imgUrl: "https://dsm01pap002files.storage.live.com/y4mXBiHmsUzEu-ZWmKIyQXs0cy-KtAxadwA09GDfRAx8Wp8MRnf6FeEkuj5agz1cpBVUfyDBKC9kzZM8SY_U8fUyurNTt-rBvbLNvMftV4j19_ToY2qkamuih1mX5lrnnEk5t0glZJhK905c3sAGBYdt78TfwmDtIIBBv4bxLHuIzHz_F3UWan0pUqhxBkR01lt?width=600&height=600&cropmode=none", description: "LSBM 0W-30 (M514)", url:"lowsapsfully.url15" },
    { option: "maplowsapsfully16", name: "namelowsapsfully16", imgUrl: "https://dsm01pap002files.storage.live.com/y4mRrE_YL95z_VTFLBKuvqzKAQl06ZNXpHLJB6sGk4wSVoR4lvRksrCzVFooZ-aaNH9YjHU72LornJXvgo7MobsY9838yHnDAkT-4muXmJMvNCSjDAdsxa5My8GPZFDv8IuTatc_7C7opwn6LUDbg9iYB1IcxTJDjda_KiB9LeOob__lPFeD3wJm0xHrdt6Mb4W?width=600&height=600&cropmode=none", description: "C5 LSJ 0W-20 (M519)", url:"lowsapsfully.url16" },
    { option: "maplowsapsfully17", name: "namelowsapsfully17", imgUrl: "https://dsm01pap002files.storage.live.com/y4mMrrcI40tbLK7T1juBRpnvvZPAMNu8xGDJAJRGFo6AJQJKydhhvIohuBdfmTBFvKKbCUZhuGcCvK_WA95iRRpCJ-dEFrI8oFjwu5Wlb6U8yNrP58sfHGsFBWZBfOXj8mnOQuNpd610TX3j-MGwvt_QouwnQMge7CMzbsUsiaFJaDvRGGn69jqGwIJf8xE299w?width=600&height=600&cropmode=none", description: "C5 LSOV 0W-20 (M520)", url:"lowsapsfully.url17" },
    { option: "maplowsapsfully18", name: "namelowsapsfully18", imgUrl: "https://dsm01pap002files.storage.live.com/y4mBlJP3pNdEwZIfUcDmGuOFkKcu2Lp3GzPCRPS53hyrnv2R--AQaBXx552TbMxnG8KMsIpRUIQvHOhIlVfTiW214ULCgxqy5ISHbQ7Pf1waQ6H6G8VZ0YpWmurFS6tBVx3NuVhZok6vJufUS86CFQ7_rFjqu-0uVor-K0D5U_c2U7uNpeym51tJRyMCFMyGYmN?width=600&height=600&cropmode=none", description: "C3 LSRN 5W-30 (M521)", url:"lowsapsfully.url18" },
    { option: "maplowsapsfully19", name: "namelowsapsfully19", imgUrl: "https://dsm01pap002files.storage.live.com/y4mOfxBCwXvOHgL7VUKHSTcB9i4_BXShuuzT3iAXFyRkB8d6DM7dMKL5wD6U3yd0UQcpxSjYc3u1Gf4btld9nkpobMLG75yxYhjT-OPZzsO3DkCCI-OHgP0aPnVaLqAgAr0tRfIIalGg25ZMFjUNJrOsZZ6lamTPPImxruZa3XMhM6wLYUPNKG_eIK7TTXa_g-3?width=600&height=600&cropmode=none", description: "C5 C6 LSFE 0W-20 (M522)", url:"lowsapsfully.url19" },
    { option: "maplowsapsfully20", name: "namelowsapsfully20", imgUrl: "https://dsm01pap002files.storage.live.com/y4moP1HziaYeBXKg5wXakOUrQlUejHmcR7M-vymshGaXGQ__FxAjZsZYPUQYqm3MCQ4ULi35ZyvphX17B4ppm3oyQRW2jKzuYPg17NnvNFBNiej4LCdPCPmQGLOrrGIyehvxlvGjWXpo4d_KVUJ1sp40N7BK9FkLqIbBlBr7ZCvycwkthtA0NHWwa4cBk1fe1Qa?width=600&height=600&cropmode=none", description: "C3 LS 0W-30 (M523)", url:"lowsapsfully.url20" },
];
  
  export default listProductsLowSAPSFully;