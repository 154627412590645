const listProductsGearTransmissionOils = [

    { option: "mapgeartransmissionoils1", name: "namegeartransmissionoils1", imgUrl: "https://dsm01pap002files.storage.live.com/y4mXiQ9NZWC8VvwMRXki3wHCuAhkYM7CV259PwtKoguwgnirV7TE4drm-v9Kd6CuVpd2jUzKoVuM8_RR0u6FOENmhL1D9mERKTiQLDXDR6jDy7M1Mez8Iib8nYMWd7ES-lMpU5HnOt9V6XOWIgYqSqhGXnmg80KcfAURlwHf9VJEGBFT04UsuqjXEGUqrj3FQmD?width=600&height=600&cropmode=none", description: "FS 75W-90 (G159)", url:"geartransmissionoils.url1"},
    { option: "mapgeartransmissionoils2", name: "namegeartransmissionoils2", imgUrl: "https://dsm01pap002files.storage.live.com/y4my_uCF8DYnoflrZHPBoa3rD3VSFd8Z_Sq0lc7a9C7f74hq1apLgzZIefbBXEHFn7tksCXPxfwyWfnYofEUMsqQOmvJLAEoNofxT0WiW-6aUlLVBVj3PcsnNxLhxV5f8jQHxpqvT9kLAt0ZBupPcTPw3qXNPm8sUVSCaBB0uNFvfpYzV1UtT3yGBANtoDwweK9?width=600&height=600&cropmode=none", description: "SS 75W-90 (G160)", url:"geartransmissionoils.url2" },
    { option: "mapgeartransmissionoils3", name: "namegeartransmissionoils3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mgLd5UWWrHSCIZi-X3EFDDdwismdYSdBWSiCtLal2d18lrILX_nZ4oQtlk7tse_ViOMV7tc36DPjcEnl0PH9K4Nic4cdfd19aE5nlIwhRAfD9PR5b17_CjvrTCmj2j4JJJJx2LCVdiCGffReqzGgbzN11Gzh5-lLy5q2XdVmkDn_x0z9DvrIXV4_o2KRw27Rd?width=600&height=600&cropmode=none", description: "MARINE 2 STROKE (M154)", url:"geartransmissionoils.url3" },
    { option: "mapgeartransmissionoils4", name: "namegeartransmissionoils4", imgUrl: "https://dsm01pap002files.storage.live.com/y4m-GnMYH4dNtcvtsya9p-F5ywLn_Af73EZAy3pLiO1j_Xu-XW0fqYy50fyclPKPXqL_tZfmeryN61TBK2kaaF1uhco6BYy_DgFRx0kmLQ3nocWfInldECSKh91uWHoYZ-I-Vv3jWsJuYWMDp16YEaUWC_UUsIPe9W1iFl9hjfAToHS3h72N1OGMAAHMe7cCeXG?width=600&height=600&cropmode=none", description: "HD 80W-90 (G014)", url:"geartransmissionoils.url4" },
    { option: "mapgeartransmissionoils5", name: "namegeartransmissionoils5", imgUrl: "https://dsm01pap002files.storage.live.com/y4m5NfJ1KpR9uiFLn0Jgd8D1xQsiJ4ymDEXwKkKYF0GFqZglZd4do_W0SeRx5OP2XjfTOJPo1aXrFAnxF4w7RcH7KD05hRBFb5jB4SkujxGrLjLfyDhp477-m7urDKQpjPLAvrNuulAK7n10yvnS8YejjjY0I6pARBI1iaBlT8al4m2qKb177LTWFevxD2E3StB?width=600&height=600&cropmode=none", description: "HD 85W-140 (G017)", url:"geartransmissionoils.url5" },
    { option: "mapgeartransmissionoils6", name: "namegeartransmissionoils6", imgUrl: "https://dsm01pap002files.storage.live.com/y4m30ao_iszADyQcK8S3MspZxxPeZZ7AG1sB45oY4aChMvJtkPi_3z-XYJ0TAF4Qz_LrsxDQMAG_9mGnpu4jplOvgj5KEn4n8bfgJGYtIk77hwGDvaXbAYj8e4stYEMXQwajYqF67hF7JV81Dp05371r8htH995m0UJTRarR62Or5dteFlhs_Cbp4ok4Wp_E4j8?width=600&height=600&cropmode=none", description: "HD 80W (G015)", url:"geartransmissionoils.url6" },
    { option: "mapgeartransmissionoils7", name: "namegeartransmissionoils7", imgUrl: "https://dsm01pap002files.storage.live.com/y4mhwL1PnoJnSksPTlzQUvryG2VIWlv9GnPYmM7CwB71MTBriDP8X0bQ3w3xx3-zsDn19jczvHcDyTRHZQhBDaqqtwLuWAjumkFtrsr2_9uPGWTDogzo1xmuC0iWmD4SWSyONoFOxDKygHKrNNHVl4dIZfLQw5EkSYgZsdQM5zvaP-8Kn3EibA1sJsMzDkOKmHT?width=600&height=600&cropmode=none", description: "HD 90 (G016)", url:"geartransmissionoils.url7" },
    { option: "mapgeartransmissionoils8", name: "namegeartransmissionoils8", imgUrl: "https://dsm01pap002files.storage.live.com/y4m18qjFbKRPDO1vmzGALbYUJFHe-ep3WSRGGhzjwmlVnhhOJPNEwE3IPzDDT6G0ETXOJHSXM9yldx3k8-UPWk9vxVNaToe8jKM4H1RabtOWGSWB3aHkkrO5UiZpO_2d6TKLUD6bftPjmDdQuWqEiaBPkoleYDi89GSVADxC5juS9PShF7uEO00N3HK3k83pxxv?width=600&height=600&cropmode=none", description: "HD 140 (G036)", url:"geartransmissionoils.url8" },
    { option: "mapgeartransmissionoils9", name: "namegeartransmissionoils9", imgUrl: "https://dsm01pap002files.storage.live.com/y4mr2dIQs6q1FLD36w-6PjS3DlBaYymacsMGRhMrf4KY84Cv7uQPDWvTFBoWmX5l8H21yxO2Cab-Sv-YX056UpYj6gzD47uvKHLqqy122yNe0auLp6RR_-JoJ_uY2S2psOHR3CdXZdWOa0GsJ2MsCkR_xIsVRlCPGyJmQD-7y7zzQMZbMcvusQE2zSBFx0VHPzS?width=600&height=600&cropmode=none", description: "EP 80W-90 (G008)", url:"geartransmissionoils.url9" },
    { option: "mapgeartransmissionoils10", name: "namegeartransmissionoils10", imgUrl: "https://dsm01pap002files.storage.live.com/y4mjbViLBcochh8anIpFSrwMQkZKaEKxh1a2v0VVGlSI5G407jbNF_x-EP32IqFxN4lrwsLY8u5H68MCo-wCuDf-xaUHmTMHdnKi5JZ_Roc14N4YZkRLjEkj9EtA30tYV1MHRiK0ssCquj8dTF_To4l6ZQVwSRf2chJN52z-0eXBriADH0zOKRxcmbkB482TbRE?width=600&height=600&cropmode=none", description: "EP 80W (G009)", url:"geartransmissionoils.url10" },
    { option: "mapgeartransmissionoils11", name: "namegeartransmissionoils11", imgUrl: "https://dsm01pap002files.storage.live.com/y4m1yqW5uFTju0jGPOfe9MIDArB3MKjuT2rmAuihsWQcLkA90hYEKm0S2Ld37rPMNo9aE9qon_sFihjPe86uUtMnb9BZIjwLg-Bq8pSbUAT8u2o_6gNErdztxIuDdjBseHe1MAx102CHxfWHBBiL7ZmzOJBSkvBE_zoTg4xmN5MiIxl3eUnA4I_ran4PjTHF4H-?width=600&height=600&cropmode=none", description: "EP 90 (G010)", url:"geartransmissionoils.url11" },
    { option: "mapgeartransmissionoils12", name: "namegeartransmissionoils12", imgUrl: "https://dsm01pap002files.storage.live.com/y4m2qjE3s4SuDRoGUZsOe-vCaG-k93aqhQZCMDZFLTALzio0xme3lgFbpMlRqfiOOQc_AQ4ualYb3qOLi7rZ0DunEFjFq2y3qwqEkZMiiPqupmNsItRcXc4misOta5LwI6U8-hbiwQeZJ7fvzOscbHXftSSN9HBGNxdd-sUJr4jyvM8fbSdfMYzSz5WB6L8wyK_?width=600&height=600&cropmode=none", description: "EP 140 (G013)", url:"geartransmissionoils.url12" },
    { option: "mapgeartransmissionoils13", name: "namegeartransmissionoils13", imgUrl: "https://dsm01pap002files.storage.live.com/y4m1-Xc9y_rWoZmV38gaTTf_zfKiV-EREJlBWoQCajoBSdP8E1RpNmdpKSACQfWg_cJrpQLqmqtoQN45qYeBNZQJ8hhEY5VcfT1QNrABplvIDeb3wUUoBZZQZGPDHhyssZLwEcTRDI1J8skzku_Q0BJNLjAzo0Pnhkd2sQCOivjZu3H_ttnXIxPibWLoYq1yTto?width=600&height=600&cropmode=none", description: "LS 90 (G018)", url:"geartransmissionoils.url13" },
    { option: "mapgeartransmissionoils14", name: "namegeartransmissionoils14", imgUrl: "https://dsm01pap002files.storage.live.com/y4mtmkFd9MlnLE229aUXcjfbv866Lwj5sslx8cRVc_qelFVaypSN-RpzsjN8mXs0xB3ixn8KFbFycCevb1h1_Fib11vlPb07tNnBZK5UqC9_dkW2x9fadlWehbzk5jNUZDy04zNYPopt5n8xSsWSXZA8L8Z66esxp2b7lKgxTZfceECxh4bDsllV-NrRiswCFaZ?width=600&height=600&cropmode=none", description: "LS 80W-90 (G104)", url:"geartransmissionoils.url14" },
    { option: "mapgeartransmissionoils15", name: "namegeartransmissionoils15", imgUrl: "https://dsm01pap002files.storage.live.com/y4m4cARTIsZV4-O4FHEjIQ6HDN5iipJ1PS9FFUc4Vcnw0bi_2AfYLw-gY7XyQmvUn8_VAPdLRi4Ut19M4gXeQe6Aa_faZubumLxk1kLVE7v_oybj6Y02qdFi2aVvJwKooq9CzciRMNoax4kz8uwed8g4S3cq9Opc-InHjj2t1n4yWIVOFibaOcPps62U8Pjbjz2?width=600&height=600&cropmode=none", description: "LS 85W-140 (G127)", url:"geartransmissionoils.url15" },
    { option: "mapgeartransmissionoils16", name: "namegeartransmissionoils16", imgUrl: "https://dsm01pap002files.storage.live.com/y4moZR1OgnJ_39CK1IAjy3NpI_pZNTZ8WCK271_yofhVhN8g4R3Z_cONIdrNBpgnYKHl_ldq8sIMVG3i72Vcn9b9v870xNXINOg6vQAQEbrOCiG7mR42xSbkOv4yvgbw1VcGet7-z9l3kdREFiDmNnv1nXs9k0EFbpKQT4u0Ol_1JqsjV2HwOrscG-kSDp0gafn?width=600&height=600&cropmode=none", description: "SAE 80 (G020)", url:"geartransmissionoils.url16" },
    { option: "mapgeartransmissionoils17", name: "namegeartransmissionoils17", imgUrl: "https://dsm01pap002files.storage.live.com/y4mWi1MoWztIxd9hfbWtW8jSYPGVk7HvuIuvpPN63pyBxAhMFeIXkhYoAGm96l0SbJFq8zZuzvNECgD0kPrhPNwNXYvj8C9gCtm-CdJIhY63MDZu87JOmilMFZG9dHl-9cfAlloDCWdADxy49I9QW5eU7U094dtEJsmeAzvXxqhru5zNLj8kPnZXeaZlfMfh0QD?width=600&height=600&cropmode=none", description: "SAE 90 (G021)", url:"geartransmissionoils.url17" },
    { option: "mapgeartransmissionoils18", name: "namegeartransmissionoils18", imgUrl: "https://dsm01pap002files.storage.live.com/y4ma7MmOgyZjoLBARsauM2bJ3XqOjqJb3E76n00HVhFl1ZpUVe2lwxRETy7motBBSER2tTo-jAoUXzlVXL8aFfUZZO6ms9CeYwvhpxigYraOD7UE0Eb453h7k2LnBZN5MxSguZfsXr7h9saXriZnIxQxyO8SQ_zqoFzmLsNqMZbEmXrn_bdtg3oeh0U0jeRcwtr?width=600&height=600&cropmode=none", description: "SAE 140 (G022)", url:"geartransmissionoils.url18" },
    { option: "mapgeartransmissionoils19", name: "namegeartransmissionoils19", imgUrl: "https://dsm01pap002files.storage.live.com/y4mhJfwTEXDBoAMhU-65KgOlpgC7eVaI34VGK2UGd6e3DB6Tu8gWv_Bxwb3rCjt_9L9YOSJIfSYZeJgNWje8kb1TRCA9qR6FvwVHB9szgdvfCoXqIeuyiXZmbpPF3eQGwlJyzRAIECXR-RkALbolxH917f59OFixA_9b-l8LquqMEkOHZnWCjVELEr73Sad7LKN?width=600&height=600&cropmode=none", description: "MVA (A036)", url:"geartransmissionoils.url19" },
    { option: "mapgeartransmissionoils20", name: "namegeartransmissionoils20", imgUrl: "https://dsm01pap002files.storage.live.com/y4miqviFDxUS2rcKHbkxflSoQWb61u1lqF1EY1vsCtti3eBbQIvM-_W8UZ5DMDuwTrJtpKlOT5IGorJL78q2KvtceEWrcwbW-EwQ95aqddaKr9xZYxY0OwKcnaD5KK6HP3RcaKVqjK6TM4ElzjiGSO5ivqTgx91VtWq9A2zZ2XyfmWzxyOiFqotWVw0zeEuO1Xk?width=600&height=600&cropmode=none", description: "D III (A020)", url:"geartransmissionoils.url20" },
    { option: "mapgeartransmissionoils21", name: "namegeartransmissionoils21", imgUrl: "https://dsm01pap002files.storage.live.com/y4mAQOQmOTYEVevtlcTTmtwmxw2a4fwWIKt0kUytLsF3rH80BDNLo95J4B9Mbr5w8s9B76vOCXzGlh7mlWmlUyIBf7hrQ81GwgwbwRgw0KUYy_GqD6K4nl_kmlLF8I8AY2t0CBZokpRHbwPnBIyhCd9jkTumLCKWzy92CGnopw55MUH-0W_y5tzxLb2X5LBzBmR?width=600&height=600&cropmode=none", description: "D II (A009)", url:"geartransmissionoils.url21" },
    { option: "mapgeartransmissionoils22", name: "namegeartransmissionoils22", imgUrl: "https://dsm01pap002files.storage.live.com/y4mDGapQrS3uSd2P7yKKLDw8vMsfvgLjcZb8cbO0oKQQXPpgQF8o9nPfMDiwkbweIESBUnaRJ_X90Rmz_bJu9zV5f3v19JlgXspyRo68wMsxpqQvxpJU5AoL1lR3_v1FGmcjtopeeAlykYAjVzHe1xdEKRn47Y3jecE2s4kyNgrTE_le8ffwWdzS9LXX9o9nkXj?width=600&height=600&cropmode=none", description: "G (A002)", url:"geartransmissionoils.url22" },
    { option: "mapgeartransmissionoils23", name: "namegeartransmissionoils23", imgUrl: "https://dsm01pap002files.storage.live.com/y4mUYLdjDC6BKVgUtKD0upOuyFnIY2fXK7jGD9hDYAXt47tBv5TZFiBhkEgal3SADQ1Alxn3UeV2Vh1a2LImDK-xrHFYDiRebPrXg0F3t-TO2W8X1N9y3gjfdCH8xV4uAPaTHF1uI27aBc7zGujBBdGI-NcR22o4_subrEar6Ddxj19F7tP79ryZZMvKvgt7VTc?width=600&height=600&cropmode=none", description: "D VI (A041)", url:"geartransmissionoils.url23" },
    { option: "mapgeartransmissionoils24", name: "namegeartransmissionoils24", imgUrl: "https://dsm01pap002files.storage.live.com/y4mu08Wg9ekI7FOH_-xbPCo4Oh0VfDtu5tWrgsz-8YYfz12WeEuPzpZMY2SnLP4Dvr2KFPVO4PqAYRZS38Mao0hLBp8P4jcgiukkTHh49jukSiF4JWbqw9q41xzJ81ZbWtbIKFiQa5g4CkOZHdz92dmJUbVUXVCWKda7S_GNInT2EIV3FGmjyHUX9kUoFmv4BR3?width=600&height=600&cropmode=none", description: "CVT (A042)", url:"geartransmissionoils.url24" },
    { option: "mapgeartransmissionoils25", name: "namegeartransmissionoils25", imgUrl: "https://dsm01pap002files.storage.live.com/y4m0Se8ag8eflcTybS3fnzAtchFAXgLNYFY9fnNntLw5JCNR9A1Savy-Ka1JMiyXLT5GpEQHk_5Z3r6T-gibKo1X3ZDfestTMU3EReg_Y03LHhbSn7YZpcMLld8q9rTD3KFiiQo4UzK7SKlbUcMyWqj0TAsESlG-8O-FbQkce_dpoqyzJV8NAsvnC_WRPu5IY0F?width=600&height=600&cropmode=none", description: "ELC (A045)", url:"geartransmissionoils.url25" },
    { option: "mapgeartransmissionoils26", name: "namegeartransmissionoils26", imgUrl: "https://dsm01pap002files.storage.live.com/y4mTrrhjxSdq8uiaXubcd0Dso8NPVxczcIse0pZGCC8q_V4VZIsfLUIXve82kZAgDOx5v0ERMQgIZw3uDl12NoictOx2zf-ZKXPw1EqcCzMJPoydHp7ZyYNRXIR5Jzi37JXkubxMB_DFHEzayV8ZiXlKx9pywVJaq1JXOduaDZ5Fcn9trBFs1lXssqg-TUrlwm2?width=600&height=600&cropmode=none", description: "DCT (A043)", url:"geartransmissionoils.url26" },
    { option: "mapgeartransmissionoils27", name: "namegeartransmissionoils27", imgUrl: "https://dsm01pap002files.storage.live.com/y4meI87SrtjwJtqWx9wesLRla3GlqvMHd3TVos_HtXyhMO3B53fG3VKsxPreIVbEC63bQDTg9rADycSQX86RpNxWkcbSPEy4P5tXtxZRsi0iXTe6sa4vdUNLOhn7TM8SV2juB7jwyRRiEoyuveTK6pHey9q-AtfWq0TqOEJSV2VWoGFKEFny_VmSJuM8BgO9VdU?width=600&height=600&cropmode=none", description: "75W (G167)", url:"geartransmissionoils.url27" },
    { option: "mapgeartransmissionoils28", name: "namegeartransmissionoils28", imgUrl: "https://dsm01pap002files.storage.live.com/y4mOccA7whb12e6G-PVOG1gq8K2MxUn5H9EfulY_578rGaA0mdc1LVrpw9yjezufODr9v-JwgM0dVmhU42VXHCWtxTfFrVaqhFdnqyRb-dZMn0WIOLmTlJurYrU53bvKN4CDytpxh4v7S2h3kgPsdfRW_W82A0mlIewpgUWhkXyLijJSld7Hu8GRvw7wKG426cG?width=600&height=600&cropmode=none", description: "LVA (A050)", url:"geartransmissionoils.url28" },
    { option: "mapgeartransmissionoils29", name: "namegeartransmissionoils29", imgUrl: "https://dsm01pap002files.storage.live.com/y4mD3mjefziOyBk64pjxt4utvHHOW7PofNfr9DI4aqQ38hLUkr8WgA3by-MfwrDL0QeIu8U_5MKjTaY_c4zoRh7xp0yqY8qOdlE4L-K-yC0C2CwDTHypxrjuMCV5FuqsAnchhCZe39mbqAIn9z3jbDRPjOxEG6HWIcFja1a9ddnPCjvjRRtyqvKBhiuEjkXagQF?width=600&height=600&cropmode=none", description: "FS 75W-85 (G172)", url:"geartransmissionoils.url29" },
    { option: "mapgeartransmissionoils30", name: "namegeartransmissionoils30", imgUrl: "https://dsm01pap002files.storage.live.com/y4mVeaZEimsDhZq4n0iSBcELrftVSF48ISHTffGdaHEjXo600YwiyTpDQO489KRYn8qtIh-8Rz52ZC6Qg7Hj4mld6EHMQq8hsvvk1sUJBHNicaFfvfgDDZhWGT_5TiqgO_xzu5VC5c1wLfMCaLKRWbfTpgsiKkNoBpXRA8dTtOOLUjt8bPoluRyPYGgv9DaE6Tk?width=600&height=600&cropmode=none", description: "MTF LV (G173)", url:"geartransmissionoils.url30" },
    { option: "mapgeartransmissionoils31", name: "namegeartransmissionoils31", imgUrl: "https://dsm01pap002files.storage.live.com/y4meFxOsYG6Y6DdZZjtjK7s3-E2KzkUSiLz2WEu62Is0fVmapEN02TBD-hZQTpniY5XwEOVQpQ4rG_uHqkSajrtHAuBbZdfxRH8CrJ168b2DI8WO1PsGQfOomNR9aa-4JV3RjDqmU_RYXNJgsyPGQDUgL8lVSbJsan_6UKZ6Xxezl0V1cln9mfxTQVsCnZdp374?width=600&height=600&cropmode=none", description: "FS 75W-80 (G174)", url:"geartransmissionoils.url31" },
];
  
  export default listProductsGearTransmissionOils;