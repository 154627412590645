import React, { useContext } from "react";
import "../assets/css/pages/gasoline.css";
import { Container, Row, Col } from "react-bootstrap";
import listProductsGasoline from "./listProductsGasoline";
import { Products } from "../components/Products";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

export const Gasoline = () => {
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "gasoline.title",
    defaultMessage:
      "Aditivos: ¿que es gasolina y que hace el aditivo para gasolina?", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "gasoline.description",
    defaultMessage:
      "Conoce la importancia de los aditivos para la gasolina y aumenta la potencia del motor", //Cambia Descripcion Pagina
  });

  return (
    <section className="gasoline">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-gasoline">
        <div className="head-gasoline-img"></div>
        <h1 className="head-gasoline-text">
          <FormattedMessage id="gasoline.h1" defaultMessage="GASOLINA" />
        </h1>
      </div>
      <Container>
        <Row className="product-gasoline-row1">
          <h2>
            <FormattedMessage id="gasoline.row1-h2x1" defaultMessage="¿Que es gasolina?"/>
          </h2>
          <p>
            <FormattedMessage
              id="gasoline.row1-p1"
              defaultMessage="La gasolina es un tipo de combustible líquido utilizado en motores de combustión interna, especialmente en vehículos de motor. Se obtiene a través del refinamiento del petróleo crudo y se utiliza como fuente de energía para alimentar los motores de gasolina en automóviles, motocicletas y otros equipos. La gasolina se caracteriza por su alta volatilidad y su capacidad para producir energía a través de la combustión en los motores, generando movimiento y potencia."
            />
          </p>
          <h2>
            <FormattedMessage id="gasoline.row1-h2x2" defaultMessage="¿Que hace el aditivo para gasolina?"/>
          </h2>
          <p>
            <FormattedMessage
              id="gasoline.row1-p2"
              defaultMessage="Los aditivos para la gasolina interactúan con el sistema de inyección del vehículo, purificando los componentes del motor y previniendo la acumulación de sedimentos. Esto evita que el motor realice esfuerzos innecesarios que consuman más combustible. En síntesis, los aditivos de gasolina mejoran la eficiencia de la combustión y contribuyen a emisiones más limpias del vehículo, ofreciendo un pequeño aporte a la preservación del medio ambiente."
            />
          </p>
        </Row>
        <Row className="product-gasoline-row2">
          <h2>
            <FormattedMessage
              id="gasoline.row2-h2"
              defaultMessage="Productos para Gasolina"
            />
          </h2>
          <Col>
            <div className="container-listProductsGasoline">
              {listProductsGasoline.map((product, index) => (
                <a
                  key={index}
                  href={intl.formatMessage({
                    id: product.url,
                    defaultMessage: product.url,
                  })}
                  className="product-link-gasoline"
                >
                  <div className="map-listProductGasoline">
                    <h2>{product.name}</h2>
                    <img src={product.imgUrl} alt="Biobor JF gasoline" />
                    <p>
                      <FormattedMessage
                        id={product.option}
                        defaultMessage={product.option}
                      />
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="product-gasoline-row3">
          <h2>
            <FormattedMessage
              id="gasoline.row3-h2x1"
              defaultMessage="¿Por qué usar nuestros mejores aditivos para gasolina?"
            />
          </h2>
          <p>
            <FormattedMessage
              id="gasoline.row3-p1"
              defaultMessage="En resumen, la gasolina que se adquiere en las estaciones de servicio hoy en día puede resultar perjudicial para los motores. Las mezclas de etanol pueden causar daños en las mangueras y sellos, provocar la formación de orificios en los pistones e incluso llevar a la destrucción total del motor debido a la presencia de agua y etanol. Además, se espera que las mezclas actuales del 10% aumenten al 15% en un futuro cercano. Esta situación amenaza con anular las garantías de los fabricantes y deja a muchos equipos antiguos sin opciones de combustible adecuado. Para evitar estos problemas, confíe en Biobor EB, un producto que nivelará el terreno y protegerá su equipo de posibles desastres. Biobor EB es la solución confiable para todo tipo de vehículos que funcionan con gasolina."
            />
          </p>
          <h2>
            <span>
              <FormattedMessage
                id="gasoline.row3-h2x2"
                defaultMessage="Ver Otros Productos"
              />
            </span>
          </h2>
        </Row>
        <Products />
      </Container>
    </section>
  );
};
