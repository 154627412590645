import img1 from '../../../assets/img/exol/products/industrial/hydraulic-oils/1-H001-600x600.webp'
import img2 from '../../../assets/img/exol/products/industrial/hydraulic-oils/2-H015-600x600.webp'
import img3 from '../../../assets/img/exol/products/industrial/hydraulic-oils/3-H002-600x600.webp'
import img4 from '../../../assets/img/exol/products/industrial/hydraulic-oils/4-H017-600x600.webp'
import img5 from '../../../assets/img/exol/products/industrial/hydraulic-oils/5-H003-600x600.webp'
import img6 from '../../../assets/img/exol/products/industrial/hydraulic-oils/6-H004-600x600.webp'
import img7 from '../../../assets/img/exol/products/industrial/hydraulic-oils/7-H005-600x600.webp'
import img8 from '../../../assets/img/exol/products/industrial/hydraulic-oils/8-H006-600x600.webp'
import img9 from '../../../assets/img/exol/products/industrial/hydraulic-oils/9-H008-600x600.webp'
import img10 from '../../../assets/img/exol/products/industrial/hydraulic-oils/10-H009-600x600.webp'
import img11 from '../../../assets/img/exol/products/industrial/hydraulic-oils/11-H010-600x600.webp'
import img12 from '../../../assets/img/exol/products/industrial/hydraulic-oils/12-H011-600x600.webp'
import img13 from '../../../assets/img/exol/products/industrial/hydraulic-oils/13-H084-600x600.webp'
import img14 from '../../../assets/img/exol/products/industrial/hydraulic-oils/14-H046-600x600.webp'
import img15 from '../../../assets/img/exol/products/industrial/hydraulic-oils/15-H012-600x600.webp'
import img16 from '../../../assets/img/exol/products/industrial/hydraulic-oils/16-h197-600x600.webp'
import img17 from '../../../assets/img/exol/products/industrial/hydraulic-oils/17-H193-600x600.webp'
import img18 from '../../../assets/img/exol/products/industrial/hydraulic-oils/18-H107-600x600.webp'
import img19 from '../../../assets/img/exol/products/industrial/hydraulic-oils/19-H177-600x600.webp'
import img20 from '../../../assets/img/exol/products/industrial/hydraulic-oils/20-H018-600x600.webp'
import img21 from '../../../assets/img/exol/products/industrial/hydraulic-oils/21-H190-600x600.webp'
import img22 from '../../../assets/img/exol/products/industrial/hydraulic-oils/22-H020-600x600.webp'
import img23 from '../../../assets/img/exol/products/industrial/hydraulic-oils/23-H021-600x600.webp'
import img24 from '../../../assets/img/exol/products/industrial/hydraulic-oils/24-H070-600x600.webp'
import img25 from '../../../assets/img/exol/products/industrial/hydraulic-oils/25-H022-600x600.webp'
import img26 from '../../../assets/img/exol/products/industrial/hydraulic-oils/26-H024-600x600.webp'
import img27 from '../../../assets/img/exol/products/industrial/hydraulic-oils/27-H025-600x600.webp'
import img28 from '../../../assets/img/exol/products/industrial/hydraulic-oils/28-H026-600x600.webp'
import img29 from '../../../assets/img/exol/products/industrial/hydraulic-oils/29-H073-600x600.webp'
import img30 from '../../../assets/img/exol/products/industrial/hydraulic-oils/30-H047-600x600.webp'
import img31 from '../../../assets/img/exol/products/industrial/hydraulic-oils/31-H203-600x600.webp'
import img32 from '../../../assets/img/exol/products/industrial/hydraulic-oils/32-h215-600x600.webp'
import img33 from '../../../assets/img/exol/products/industrial/hydraulic-oils/33-h213-600x600.webp'

const listProductsHydraulicOilsIn = [

    { option: "maphydraulicoilsin1", name: "namehydraulicoilsin1", imgUrl: img1, description: "", url:"hydraulicoilsin.url1"},
    { option: "maphydraulicoilsin2", name: "namehydraulicoilsin2", imgUrl: img2, description: "", url:"hydraulicoilsin.url2" },
    { option: "maphydraulicoilsin3", name: "namehydraulicoilsin3", imgUrl: img3, description: "", url:"hydraulicoilsin.url3" },
    { option: "maphydraulicoilsin4", name: "namehydraulicoilsin4", imgUrl: img4, description: "", url:"hydraulicoilsin.url4" },
    { option: "maphydraulicoilsin5", name: "namehydraulicoilsin5", imgUrl: img5, description: "", url:"hydraulicoilsin.url5" },
    { option: "maphydraulicoilsin6", name: "namehydraulicoilsin6", imgUrl: img6, description: "", url:"hydraulicoilsin.url6" },
    { option: "maphydraulicoilsin7", name: "namehydraulicoilsin7", imgUrl: img7, description: "", url:"hydraulicoilsin.url7" },
    { option: "maphydraulicoilsin8", name: "namehydraulicoilsin8", imgUrl: img8, description: "", url:"hydraulicoilsin.url8" },
    { option: "maphydraulicoilsin9", name: "namehydraulicoilsin9", imgUrl: img9, description: "", url:"hydraulicoilsin.url9" },
    { option: "maphydraulicoilsin10", name: "namehydraulicoilsin10", imgUrl: img10, description: "", url:"hydraulicoilsin.url10" },
    { option: "maphydraulicoilsin11", name: "namehydraulicoilsin11", imgUrl: img11, description: "", url:"hydraulicoilsin.url11" },
    { option: "maphydraulicoilsin12", name: "namehydraulicoilsin12", imgUrl: img12, description: "", url:"hydraulicoilsin.url12" },
    { option: "maphydraulicoilsin13", name: "namehydraulicoilsin13", imgUrl: img13, description: "", url:"hydraulicoilsin.url13" },
    { option: "maphydraulicoilsin14", name: "namehydraulicoilsin14", imgUrl: img14, description: "", url:"hydraulicoilsin.url14" },
    { option: "maphydraulicoilsin15", name: "namehydraulicoilsin15", imgUrl: img15, description: "", url:"hydraulicoilsin.url15" },
    { option: "maphydraulicoilsin16", name: "namehydraulicoilsin16", imgUrl: img16, description: "", url:"hydraulicoilsin.url16" },
    { option: "maphydraulicoilsin17", name: "namehydraulicoilsin17", imgUrl: img17, description: "", url:"hydraulicoilsin.url17" },
    { option: "maphydraulicoilsin18", name: "namehydraulicoilsin18", imgUrl: img18, description: "", url:"hydraulicoilsin.url18" },
    { option: "maphydraulicoilsin19", name: "namehydraulicoilsin19", imgUrl: img19, description: "", url:"hydraulicoilsin.url19" },
    { option: "maphydraulicoilsin20", name: "namehydraulicoilsin20", imgUrl: img20, description: "", url:"hydraulicoilsin.url20" },
    { option: "maphydraulicoilsin21", name: "namehydraulicoilsin21", imgUrl: img21, description: "", url:"hydraulicoilsin.url21" },
    { option: "maphydraulicoilsin22", name: "namehydraulicoilsin22", imgUrl: img22, description: "", url:"hydraulicoilsin.url22" },
    { option: "maphydraulicoilsin23", name: "namehydraulicoilsin23", imgUrl: img23, description: "", url:"hydraulicoilsin.url23" },
    { option: "maphydraulicoilsin24", name: "namehydraulicoilsin24", imgUrl: img24, description: "", url:"hydraulicoilsin.url24" },
    { option: "maphydraulicoilsin25", name: "namehydraulicoilsin25", imgUrl: img25, description: "", url:"hydraulicoilsin.url25" },
    { option: "maphydraulicoilsin26", name: "namehydraulicoilsin26", imgUrl: img26, description: "", url:"hydraulicoilsin.url26" },
    { option: "maphydraulicoilsin27", name: "namehydraulicoilsin27", imgUrl: img27, description: "", url:"hydraulicoilsin.url27" },
    { option: "maphydraulicoilsin28", name: "namehydraulicoilsin28", imgUrl: img28, description: "", url:"hydraulicoilsin.url28" },
    { option: "maphydraulicoilsin29", name: "namehydraulicoilsin29", imgUrl: img29, description: "", url:"hydraulicoilsin.url29" },
    { option: "maphydraulicoilsin30", name: "namehydraulicoilsin30", imgUrl: img30, description: "", url:"hydraulicoilsin.url30" },
    { option: "maphydraulicoilsin31", name: "namehydraulicoilsin31", imgUrl: img31, description: "", url:"hydraulicoilsin.url31" },
    { option: "maphydraulicoilsin32", name: "namehydraulicoilsin32", imgUrl: img32, description: "", url:"hydraulicoilsin.url32" },
    { option: "maphydraulicoilsin33", name: "namehydraulicoilsin33", imgUrl: img33, description: "", url:"hydraulicoilsin.url33" },
];
  
  export default listProductsHydraulicOilsIn;