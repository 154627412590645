import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import listCategoryAutomorive from "./listCategoryAutomotive";
import "../../assets/css/pages/Exol/Category/Automotive/automotive.css";
// import { Products } from "../../components/Products";

export const Automotive = () => {
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "automotive.title",
    defaultMessage: "Exol: Lubricantes de Calidad para Autos", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "automotive.description",
    defaultMessage:
      "Exol ofrece una amplia variedad de lubricantes para vehículos que brindan una protección superior a motores y transmisiones, garantizando una vida útil prolongada y sin complicaciones. Nuestra gama incluye aceites de motor de alta calidad de baja emisión de cenizas (low SAPS), fórmulas semisintéticas y a base de aceites minerales, así como fluidos para transmisiones manuales y automáticas. Descubre cómo nuestros lubricantes automotrices satisfacen las necesidades de tu vehículo con rendimiento y calidad que necesitas", //Cambia Descripcion Pagina
  });

  return (
    <section className="automotive">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-automotive">
        <div className="head-automotive-img"></div>
        <div>
          <h1 className="head-automotive-text">
            <FormattedMessage
              id="automotive.h1"
              defaultMessage="LUBRICANTES PARA AUTOMOTRIZ"
            />
          </h1>
        </div>
      </div>
      <Container>
        <Row className="product-automotive-row1">
          <p>
            <FormattedMessage
              id="automotive.row1-p1"
              defaultMessage="Exol ofrece una amplia variedad de lubricantes para vehículos que brindan una protección superior a motores y transmisiones, garantizando una vida útil prolongada y sin complicaciones. Nuestra gama incluye aceites de motor de alta calidad de baja emisión de cenizas (low SAPS), fórmulas semisintéticas y a base de aceites minerales, así como fluidos para transmisiones manuales y automáticas. Descubre cómo nuestros lubricantes automotrices satisfacen las necesidades de tu vehículo con rendimiento y calidad que necesitas."
            />
          </p>
        </Row>
        <Row className="product-automotive-row2">
          <h2>
            <FormattedMessage
              id="automotive.row2-h2"
              defaultMessage="Productos para Automotriz"
            />
          </h2>
          <Col>
            <div className="container-listproductsautomotive">
              {listCategoryAutomorive.map((product, index) => (
                <a
                  key={index}
                  href={intl.formatMessage({
                    id: product.url,
                    defaultMessage: product.url,
                  })}
                  className="product-link-automotive"
                  // onClick={scrollToTop}
                >
                  <div className="map-listproductautomotive">
                    <h2>
                      <FormattedMessage
                        id={product.name}
                        defaultMessage={product.name}
                      />
                    </h2>
                    <img src={product.imgUrl} alt="Lubricants Automotive" />
                    <p>
                      <FormattedMessage
                        id={product.option}
                        defaultMessage={product.option}
                      />
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="product-automotive-row3">
          <h2>
            <FormattedMessage
              id="automotive.row3-h2x1"
              defaultMessage="¿Por qué usar nuestros productos Exol para Automotriz?"
            />
          </h2>
          <ol>
            <li>
              <FormattedMessage
                id="automotive.ul-li1"
                defaultMessage="Protección del motor: Los lubricantes Exol Automotive están diseñados para brindar una protección superior al motor de tu vehículo. Contienen aditivos de alta calidad que ayudan a reducir la fricción, el desgaste y la corrosión, lo que prolonga la vida útil del motor y mejora su rendimiento."
              />
            </li>
            <li>
              <FormattedMessage
                id="automotive.ul-li2"
                defaultMessage="Mejor eficiencia del combustible: Los lubricantes Exol Automotive están formulados para ofrecer una lubricación eficiente y reducir la resistencia interna del motor. Esto puede resultar en una mejor eficiencia del combustible y, en última instancia, en un ahorro de costos a largo plazo."
              />
            </li>
            <li>
              <FormattedMessage
                id="automotive.ul-li3"
                defaultMessage="Mantenimiento adecuado: Utilizar los lubricantes adecuados es esencial para el mantenimiento adecuado de tu vehículo. Los lubricantes Exol Automotive están diseñados para cumplir con las especificaciones y recomendaciones de los fabricantes de automóviles, lo que garantiza un rendimiento óptimo y evita problemas de funcionamiento y desgaste prematuro."
              />
            </li>
            <li>
              <FormattedMessage
                id="automotive.ul-li4"
                defaultMessage="Amplia gama de productos: Exol Automotive ofrece una amplia gama de lubricantes para adaptarse a diferentes tipos de motores, transmisiones y sistemas. Ya sea que tengas un automóvil de pasajeros, un vehículo comercial o incluso una flota de vehículos, Exol tiene la solución adecuada para tus necesidades."
              />
            </li>
            <li>
              <FormattedMessage
                id="automotive.ul-li5"
                defaultMessage="Calidad y confiabilidad: Exol es reconocido en la industria por su calidad y confiabilidad. Sus lubricantes son fabricados utilizando los mejores ingredientes y tecnología de vanguardia, lo que garantiza un producto de alto rendimiento y durabilidad."
              />
            </li>
          </ol>
          {/* <h2>
            <span>
              <FormattedMessage
                id="automotive.row3-h2x2"
                defaultMessage="Ver Otros Productos"
              />
            </span>
          </h2> */}
        </Row>
        {/* <Row className="product-automotive-row4">
          <h2>
            <FormattedMessage
              id="automotive.row4-h2"
              defaultMessage="Biobor JF® El único biocida disponible para uso en aviación."
            />
          </h2>
        </Row> */}
      </Container>
    </section>
  );
};
