import img1 from '../../../assets/img/exol/products/industrial/multi-functional-oils/1-L258-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/multi-functional-oils/2-L260-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/multi-functional-oils/3-L262-600x600.webp';
import img4 from '../../../assets/img/exol/products/industrial/multi-functional-oils/4-L264-600x600.webp';
import img5 from '../../../assets/img/exol/products/industrial/multi-functional-oils/5-L266-600x600.webp';
import img6 from '../../../assets/img/exol/products/industrial/multi-functional-oils/6-L268-600x600.webp';
import img7 from '../../../assets/img/exol/products/industrial/multi-functional-oils/7-L270-600x600.webp';
import img8 from '../../../assets/img/exol/products/industrial/multi-functional-oils/8-L272-600x600.webp';



const listProductsMultiFunctionalOilsIn = [

  { option: "mapmultifunctionaloilsin1", name: "namemultifunctionaloilsin1", imgUrl: img1, description: "", url: "multifunctionaloilsin.url1" },
  { option: "mapmultifunctionaloilsin2", name: "namemultifunctionaloilsin2", imgUrl: img2, description: "", url: "multifunctionaloilsin.url2" },
  { option: "mapmultifunctionaloilsin3", name: "namemultifunctionaloilsin3", imgUrl: img3, description: "", url: "multifunctionaloilsin.url3" },
  { option: "mapmultifunctionaloilsin4", name: "namemultifunctionaloilsin4", imgUrl: img4, description: "", url: "multifunctionaloilsin.url4" },
  { option: "mapmultifunctionaloilsin5", name: "namemultifunctionaloilsin5", imgUrl: img5, description: "", url: "multifunctionaloilsin.url5" },
  { option: "mapmultifunctionaloilsin6", name: "namemultifunctionaloilsin6", imgUrl: img6, description: "", url: "multifunctionaloilsin.url6" },
  { option: "mapmultifunctionaloilsin7", name: "namemultifunctionaloilsin7", imgUrl: img7, description: "", url: "multifunctionaloilsin.url7" },
  { option: "mapmultifunctionaloilsin8", name: "namemultifunctionaloilsin8", imgUrl: img8, description: "", url: "multifunctionaloilsin.url8" },

];

  
  export default listProductsMultiFunctionalOilsIn;