import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import listProductsMineralOilBasedEngineOils from "../ProductsExolAutomotive/listProductsMineralOilBasedEngineOils";
import "../../../assets/css/pages/Exol/Category/Automotive/categoryAutomotive.css";
import { AskInformationProducts } from "../../../components/Modal/AskInformationProducts";

export const MineralOilBasedEngineOils = () => {
  
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  // Inicio Modal Solicitud de Informacion Productos

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Fin Modal Solicitud de Informacion Productos

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "mineraloilbasedengineoils.title",
    defaultMessage:
      "Exol - Aceites de motor a base de aceite mineral | OilFluid", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "mineraloilbasedengineoils.description",
    defaultMessage:
      "Los aceites de motor de Exol a base de aceite mineral brindan una lubricación confiable y protección efectiva para motores convencionales. Con su base de aceite mineral de alta calidad, ofrecen resistencia al desgaste y rendimiento confiable. Confíe en Exol para mantener su motor en óptimas condiciones.", //Cambia Descripcion Pagina
  });

  return (
    <section className="categoryAutomotive">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-categoryAutomotive">
        <div className="head-categoryAutomotive-img"></div>
        <div></div>
      </div>
      <Container>
        <Row className="product-categoryAutomotive-row1"></Row>
        <Row className="product-categoryAutomotive-row2">
          <h1>
            <FormattedMessage
              id="mineraloilbasedengineoils.row2-h1"
              defaultMessage="Productos | Aceites de motor a base de aceite mineral"
            />
          </h1>
          <Col>
            <div className="container-listProductsAutomotive">
              {listProductsMineralOilBasedEngineOils.map((product, index) => (
                <div
                  key={index}
                  className="product-link-categoryAutomotive"
                  onClick={handleOpenModal}
                  // onClick={scrollToTop}
                >
                  <div className="map-listProductAutomotive">
                    <h2>
                      <FormattedMessage
                        id={product.name}
                        defaultMessage={product.name}
                      />
                    </h2>
                    <img
                      src={product.imgUrl}
                      alt="Optiguard Exol Lubricantes"
                    />
                    <p>
                      <FormattedMessage
                        id={product.option}
                        defaultMessage={product.option}
                      />
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <AskInformationProducts
              showModal={showModal}
              onHide={handleCloseModal}
            />{" "}
            {/* Modal Escondido */}
          </Col>
        </Row>
        <Row className="product-categoryAutomotive-row3"></Row>
      </Container>
    </section>
  );
};
