import img1 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/1-S091-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/2-S095-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/3-S118-600x600.webp';
import img4 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/4-S016-600x600.webp';
import img5 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/5-S024-600x600.webp';
import img6 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/6-S047-600x600.webp';
import img7 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/7-S123-600x600.webp';
import img8 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/8-S015-600x600.webp';
import img9 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/9-S110-600x600.webp';
import img10 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/10-S043-600x600.webp';
import img11 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/11-S007-600x600.webp';
import img12 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/12-S077-600x600.webp';
import img13 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/13-S031-600x600.webp';
import img14 from '../../../assets/img/exol/products/industrial/soluble-cutting-grinding-fluids/14-s042-600x600.webp';


const listProductsSolubleCuttingGrindingFluidsIn = [

  { option: "mapsolublecuttinggrindingfluidsin1", name: "namesolublecuttinggrindingfluidsin1", imgUrl: img1, description: "", url: "solublecuttinggrindingfluidsin.url1" },
  { option: "mapsolublecuttinggrindingfluidsin2", name: "namesolublecuttinggrindingfluidsin2", imgUrl: img2, description: "", url: "solublecuttinggrindingfluidsin.url2" },
  { option: "mapsolublecuttinggrindingfluidsin3", name: "namesolublecuttinggrindingfluidsin3", imgUrl: img3, description: "", url: "solublecuttinggrindingfluidsin.url3" },
  { option: "mapsolublecuttinggrindingfluidsin4", name: "namesolublecuttinggrindingfluidsin4", imgUrl: img4, description: "", url: "solublecuttinggrindingfluidsin.url4" },
  { option: "mapsolublecuttinggrindingfluidsin5", name: "namesolublecuttinggrindingfluidsin5", imgUrl: img5, description: "", url: "solublecuttinggrindingfluidsin.url5" },
  { option: "mapsolublecuttinggrindingfluidsin6", name: "namesolublecuttinggrindingfluidsin6", imgUrl: img6, description: "", url: "solublecuttinggrindingfluidsin.url6" },
  { option: "mapsolublecuttinggrindingfluidsin7", name: "namesolublecuttinggrindingfluidsin7", imgUrl: img7, description: "", url: "solublecuttinggrindingfluidsin.url7" },
  { option: "mapsolublecuttinggrindingfluidsin8", name: "namesolublecuttinggrindingfluidsin8", imgUrl: img8, description: "", url: "solublecuttinggrindingfluidsin.url8" },
  { option: "mapsolublecuttinggrindingfluidsin9", name: "namesolublecuttinggrindingfluidsin9", imgUrl: img9, description: "", url: "solublecuttinggrindingfluidsin.url9" },
  { option: "mapsolublecuttinggrindingfluidsin10", name: "namesolublecuttinggrindingfluidsin10", imgUrl: img10, description: "", url: "solublecuttinggrindingfluidsin.url10" },
  { option: "mapsolublecuttinggrindingfluidsin11", name: "namesolublecuttinggrindingfluidsin11", imgUrl: img11, description: "", url: "solublecuttinggrindingfluidsin.url11" },
  { option: "mapsolublecuttinggrindingfluidsin12", name: "namesolublecuttinggrindingfluidsin12", imgUrl: img12, description: "", url: "solublecuttinggrindingfluidsin.url12" },
  { option: "mapsolublecuttinggrindingfluidsin13", name: "namesolublecuttinggrindingfluidsin13", imgUrl: img13, description: "", url: "solublecuttinggrindingfluidsin.url13" },
  { option: "mapsolublecuttinggrindingfluidsin14", name: "namesolublecuttinggrindingfluidsin14", imgUrl: img14, description: "", url: "solublecuttinggrindingfluidsin.url14" },

];

  
  export default listProductsSolubleCuttingGrindingFluidsIn;