const listProductsMineralOilBasedEngineOils = [

    { option: "mapmineraloilbasedengineoils1", name: "namemineraloilbasedengineoils1", imgUrl: "https://dsm01pap002files.storage.live.com/y4m6N9-9t0VfyMKJ3Ij-fhRu4BqFTVTOwTwVTvUzLN36pQmcDIXlOT3b9dwCpAevI_A-GWHk9vaEeyQkgEbVSqcMS5NPqe2DLtjLAHbjjme87sRFqVU6gH0ZbdACYw6XNlUtod3_EF8H7LCVuy1Q3QWmA16DcbO1YylFOwE5e5jSaqVgPrnoTsTGviMdqsjlvbC?width=600&height=600&cropmode=none", description: "10W (M306)", url:"mineraloilbasedengineoils.url1"},
    { option: "mapmineraloilbasedengineoils2", name: "namemineraloilbasedengineoils2", imgUrl: "https://dsm01pap002files.storage.live.com/y4mqNzhLc7xarMN9syjFztmLuOcFBxBUFOMKdFv8vskBeCAKaxjhIczJY9DCQHrCTx1KtnBztL_i8BJl7qUv-ThsL1cBdFHekmDY7gv_ltOmyccuY3w8QCwgCfnLWWb63QQrOy56DVs9V76nhSqLqd7eaDhpr2v_MbwFPcBFpchnT827z_RhkRDXFYxQ8YHn2Q4?width=600&height=600&cropmode=none", description: "20W (M394)", url:"mineraloilbasedengineoils.url2" },
    { option: "mapmineraloilbasedengineoils3", name: "namemineraloilbasedengineoils3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mpUefXmPTje6EsWptdYmIwXY9IkW8hI5b1UM2OP8lMIGUkZbYLuqz1gb371zt5_payKksG8caANzqUyp75kKJPKMKl6ky2SDLpUSH5rrLrrrhrMq9fW1tJZp70LtMjQ5gS5ZSYdrSKpNVGzbxfgGog6xb_z2SCGGx0HW_XBhdm86yUWynlbWAzMJrlgcslIEX?width=600&height=600&cropmode=none", description: "30 (M305)", url:"mineraloilbasedengineoils.url3" },
    { option: "mapmineraloilbasedengineoils4", name: "namemineraloilbasedengineoils4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mODC2l_tWsCUYi4MO9AuWXy_pslJD5gEc7hCNI4aCsPT69yfc0TQ_kt7jK6-roOlCuyBDK6ws9V_War1NH-5lPU7om5i24Y1sNpwzXcsay5T42f38a6YjwdaMs-LBOmw60-MmuskzLClu81Pz43tmKGUjBJumT3-CCRnstPQ091QyacE2jXnX4FFaNn8pHp8U?width=600&height=600&cropmode=none", description: "40 (M304)", url:"mineraloilbasedengineoils.url4" },
    { option: "mapmineraloilbasedengineoils5", name: "namemineraloilbasedengineoils5", imgUrl: "https://dsm01pap002files.storage.live.com/y4m5PJwnmOgDfVhqrz2N366TW2jSj2BbocdSPRSXT-6HtwTDodvmVy79WTSyV058zUuy2-AVe3vQ5w317hhaCxuLyv0aJkDL02zLialr45mWt8TvnBAj4cJNprkSSE5tCGN2q-RyOwJnWbCRTu2pd0JOfOFx04BYIvmRQAtB1axLyfGDH8tlJ6eTuLtCnf3j832?width=600&height=600&cropmode=none", description: "50 (M303)", url:"mineraloilbasedengineoils.url5" },
    { option: "mapmineraloilbasedengineoils6", name: "namemineraloilbasedengineoils6", imgUrl: "https://dsm01pap002files.storage.live.com/y4m5pNobgRyBMMG8Ke1Qy1q-U5BCtzeg13GVVotJEzEah_m1ADmWb4WtA8TAp7Nw3B-ukSu6pcDqVv78T9bR-bRj8jzkxtGEluQ7AcQJiBf8TQp4vEcCCFsMZL5oYGu6yqcsakSiPQqXGHsETzi6HDuPyWDzmOK3B7_889Vfy1RddfymWndXOdeClvlPweds_jM?width=600&height=600&cropmode=none", description: "15W-40 (M099)", url:"mineraloilbasedengineoils.url6" },
    { option: "mapmineraloilbasedengineoils7", name: "namemineraloilbasedengineoils7", imgUrl: "https://dsm01pap002files.storage.live.com/y4mIeNkIrJEpRVeoevEz3hnb1c-TChTdXNECrzoCrzFtPFSE8h0VtIpXYvdpn5q5e0xqOTwK2Mrk0QNwpqQ7IK0FjYjPHNpjGyXVtVsIU9n2A9aLqsHQF4P0b2Xt-7CLSWplylYdyCkMVyx_-aHqky0soQfeT1jawyycSlQs1wzYROBEpiGN0mOTChc6ydD_s3K?width=600&height=600&cropmode=none", description: "10W-30 (M111)", url:"mineraloilbasedengineoils.url7" },
    { option: "mapmineraloilbasedengineoils8", name: "namemineraloilbasedengineoils8", imgUrl: "https://dsm01pap002files.storage.live.com/y4msxbyukjaqux-PRdJe0NtVgCvwr75pbkoFPZAEkEEzXGUJ4HQ8FfHzKBL1vhwNYcGkEzgUKGZahD9wa3pV8xWDLURv-sGHrFk7YVr4EQ4Is8QS0CzisbOJfHtTKnBVcT6UglXsy1CIHMMYw0EayiLr8P6qvSAWH_t_NdLYE6yc04xKE7pGy37OlMrytMdlioH?width=600&height=600&cropmode=none", description: "10W-40 (M102)", url:"mineraloilbasedengineoils.url8" },
    { option: "mapmineraloilbasedengineoils9", name: "namemineraloilbasedengineoils9", imgUrl: "https://dsm01pap002files.storage.live.com/y4mhswgFvB5iDgOwqpLNR39KpFD7T86K6SC9hp_bZj9ph4lo6Z7SvuC9dW0qRc4Pff5N19-f--lSsVnZBgU5oBXCYMJJ0aZnidXML7QuMc9Hazs1szeAsktKZdEmBxWahzR468whDIeb99EMCzhFGc-TC6T3F5x1lIN1u8U_ku4iDblAQx0qRWeDldb3-x05gRd?width=600&height=600&cropmode=none", description: "20W-50 (M166)", url:"mineraloilbasedengineoils.url9" },
    { option: "mapmineraloilbasedengineoils10", name: "namemineraloilbasedengineoils10", imgUrl: "https://dsm01pap002files.storage.live.com/y4mpnfmuoKpcTfUd3nofuq8ujVSqLi57VrGDiCo80t6F199p6snkdTkCXS-BdMUs2Oo5Giv6dAEh-MpJHt-zjKRMAW-XzDeziesDn_D04qveWeifgsoBffSTkqUJo4kFbEbmO7uBsc7xSwOa6qRXNr7QSoMgZo8xWj9qVLpvY0bUQ4_JxxXSw1xn12Nonnq_QVv?width=600&height=600&cropmode=none", description: "15W-40 (M330)", url:"mineraloilbasedengineoils.url10" },
    { option: "mapmineraloilbasedengineoils11", name: "namemineraloilbasedengineoils11", imgUrl: "https://dsm01pap002files.storage.live.com/y4mnxQZjGslVcp79Rgz9GJSzdOShZGUGJhWt3CqvDQPr01Ev2-7OrFO4lpF8U9X-13EN7GFYNRcA_ht-UFkLrqV8pkbXZl83cGtFuWURi8kq8OiUwTOX0xA_tmbu_7Fnq1YPZ0dmF2sZpVv3dQqtEMSmzLuRyFeKJp4isKKtbZuSuuY6qpwcQjjKzVsONwj0OUM?width=600&height=600&cropmode=none", description: "10W (M049)", url:"mineraloilbasedengineoils.url11" },
    { option: "mapmineraloilbasedengineoils12", name: "namemineraloilbasedengineoils12", imgUrl: "https://dsm01pap002files.storage.live.com/y4m-prP-PnE14xERCFNKFFrqhYsxx_oNZpSP3J2_iLBUo6XmzsLNTRQKfPKC7_0xdcYRJSK0XeeDFO-8U-YuDV2aDSKR1TmgYtezv3NlTmeMAG0PIACCSxux6FNUCPRsbfuuuswuCorXhxLmR4keBeb0Zu3MHnvsptS2aaLkFhIagvAm0lk2EQKfoBT0t5JfYLn?width=600&height=600&cropmode=none", description: "30 (M048)", url:"mineraloilbasedengineoils.url12" },
    { option: "mapmineraloilbasedengineoils13", name: "namemineraloilbasedengineoils13", imgUrl: "https://dsm01pap002files.storage.live.com/y4mgnjqHeTVDjLmpK9XZptsZe_jkP9rM7oONH55IGuQXYidMEhB9fNqLRRN389O5jbfWucvZYLbZJaqsM4TSD34A655-8T-DeFY2Tvy2u9Etp5FERsHzRRZwSO3ewPL3bFpJ-ZexGAriBe__XbmioUzhKmy7LlW5hWNCQXxAllccrv7sYf4joibfJrhfptR4jvk?width=600&height=600&cropmode=none", description: "40 (M050)", url:"mineraloilbasedengineoils.url13" },
    { option: "mapmineraloilbasedengineoils14", name: "namemineraloilbasedengineoils14", imgUrl: "https://dsm01pap002files.storage.live.com/y4mofpjDjS_mrI9sHFPbZBQhOLVv27PXHFbl8fdK7NoYaqyf0LvXiaqkY_tX_qmeh3MbbdR6oecDiCmvoMLXE0HCyN_QsOxQXH483z3m-qVlFIU68Bai-qCRM38ZSAhtYfc5MSrcFnvMKMgmrKS7HpruUpu0R6LpakydRl-Djm7z5hG2D7dgZzTEs9ybPkbjK19?width=600&height=600&cropmode=none", description: "10 (M012)", url:"mineraloilbasedengineoils.url14" },
    { option: "mapmineraloilbasedengineoils15", name: "namemineraloilbasedengineoils15", imgUrl: "https://dsm01pap002files.storage.live.com/y4mQvrgxhD9NW-LZ9icYzJmB1i90OyWpw28UyPdyz97_4vixFvE5ZNTfhYFiLFwwzsCJIvGXT7eWnpLF0sUrOjxyCOYxBGD5qBMgoQPpsW6aF4_HHYGxcE-cgwdu9qrAQacVrqEu7O1b-YY3jPnQqcrWqZWpd3Rqusf-42MaQajb6rz-j0_H27xYMSV_iYrY5J9?width=600&height=600&cropmode=none", description: "20 (M013)", url:"mineraloilbasedengineoils.url15" },
    { option: "mapmineraloilbasedengineoils16", name: "namemineraloilbasedengineoils16", imgUrl: "https://dsm01pap002files.storage.live.com/y4mx_KTHhjeWCBH6OJUYAEZCU11gDyQCZdgiCdgG8Lg3IKJc_gaYw6UJC9v1UChbAG3B4ADBqsfsE0Rzh5em4vENa7dgp-SkkOSFN1PzLjozgRX-QyvcAzCqYhAatA8mtANCyD5Ecou9w81fw_eKZR2YaxIWRQX2_CToQH_41UUr5XyaScOHmSEaGuYd7Lw-3UZ?width=600&height=600&cropmode=none", description: "30 (M014)", url:"mineraloilbasedengineoils.url16" },
    { option: "mapmineraloilbasedengineoils17", name: "namemineraloilbasedengineoils17", imgUrl: "https://dsm01pap002files.storage.live.com/y4m7m8q3n7d-rE0qlOOfS3k34mi9BssY_mScPMKAtVZw_KEPjsr-a_n8OnJs4PrLi8gzMpc5xYay-XeVspAKm6r4EoHC0HUkRFkb-0Fs5T2xyT76uqh7Tro7yOVND5QGKhL1VScA6_iNPQVOROvQullqSCXL_g3rMSLLNtGALdfYwY1soXSr5a7N80x5aw47UMI?width=600&height=600&cropmode=none", description: "40 (M018)", url:"mineraloilbasedengineoils.url17" },
    { option: "mapmineraloilbasedengineoils18", name: "namemineraloilbasedengineoils18", imgUrl: "https://dsm01pap002files.storage.live.com/y4mG3ZqCGG5UW2rKBtnk8y2ZDr0aiYVWej9w7yk4TPlbH7VO6s_BdGfjuGUmIBHLHdmqj3HkLKJzCa9HYPjOukA-rESeE3p286rqm0qL9UiHBoPLL21g2qrxebk7U6w9FBrrHTYQYys2h0ip-4ltvJ0ahbwbb9qn8WE_k85uDqNWBJNX6LhUe154UgERyFVq41v?width=600&height=600&cropmode=none", description: "50 (M019)", url:"mineraloilbasedengineoils.url18" },
    { option: "mapmineraloilbasedengineoils19", name: "namemineraloilbasedengineoils19", imgUrl: "https://dsm01pap002files.storage.live.com/y4mlSH8mXMPy_LcbCJEyfeMC4FViU068fsGUueRxIyVLM54DrTRe6r0b3spx2JEmsvx0bZiUoGw49jR05_hCfmpq6C5DgJ8cN4UcS2ZgCQ3hTtoD1gYSww1Z7_gzDGpLossLjzfspEbTtvYA6DTl_bAHVozCySi-48Yn9Sm-GVsv5BW9XjKWc02rI42twbWvvuu?width=600&height=600&cropmode=none", description: "15W-40 (M016)", url:"mineraloilbasedengineoils.url19" },
    { option: "mapmineraloilbasedengineoils20", name: "namemineraloilbasedengineoils20", imgUrl: "https://dsm01pap002files.storage.live.com/y4mriIdEfoy59apEG-fdWqg-tGW6_XPP6R1EDf4-nufRai-QylVPdqsEvj9k76hih0Xwa1eojJXQwE3Z1ebVmDr8XxLpgWevZFKvb64wHei9JncU1Sgx2QyuUMDtB0pR1_OJnsM94Sn_glirvmN_pGueUU2VEBUvLqn1PzVNvBRoq9NYwFvcNFIkFQ-iqPCh6OF?width=600&height=600&cropmode=none", description: "10W (M006)", url:"mineraloilbasedengineoils.url20" },
    { option: "mapmineraloilbasedengineoils21", name: "namemineraloilbasedengineoils21", imgUrl: "https://dsm01pap002files.storage.live.com/y4m3O749xEXX40iAEguRr4uYBM8Rec2s_ookdVO5vSd0bwEBIqBpqyceRk6YWP61LW8yHF5DcOf80r7XSn5tw4GzhLD2FlW9m1f3i5OxVKX8fJ71pBjn9GdGd7EeEuZjAA0hO6nKQyXOvjzy-R1fNsmquW-HPG4b82sRaunKg9s7itsuCfCJvGRFLCrUVBJ3gXz?width=600&height=600&cropmode=none", description: "20W (M007)", url:"mineraloilbasedengineoils.url21" },
    { option: "mapmineraloilbasedengineoils22", name: "namemineraloilbasedengineoils22", imgUrl: "https://dsm01pap002files.storage.live.com/y4myCbRddJ_LglzNPRHSaJCtGJfgGBd_Ji6Ir4Nsvla71-JHHU8wTc5v3D-KcQ1algIY6_eD5R0-jHoa-D5-Nfaflp0lAeo-eBG0qjWE42Vl6SOAo3XmbzIKXZuPXkpaIxjfwwLeJKNYS4UQhRcEr_yiMrz4GD8wxEhbWPYHICznYCqj-suqDE0hDNLUSpatmSc?width=600&height=600&cropmode=none", description: "30 (M008)", url:"mineraloilbasedengineoils.url22" },
    { option: "mapmineraloilbasedengineoils23", name: "namemineraloilbasedengineoils23", imgUrl: "https://dsm01pap002files.storage.live.com/y4mbr6e1es6mTosxDkx-vRA8lkZ1q5kazT85V_YQAMGybJWa7FaoL0ACc6iGom5NeafA3zNz6EkKI9Z-UcNq5RLvGiDv-S7TQevNVMfFGBAr-l4Y0rPPfLYQt9VvBZxkyDzp9Hpm0Sn34EiqXJwaz5Fj9m5j0eSnEfkQngVWzlXNZY2QVSBQTBWgvQ-A58a9RU6?width=600&height=600&cropmode=none", description: "40 (M009)", url:"mineraloilbasedengineoils.url23" },
    { option: "mapmineraloilbasedengineoils24", name: "namemineraloilbasedengineoils24", imgUrl: "https://dsm01pap002files.storage.live.com/y4mufldvO21dOprvPyCwzvktIABUtY_mmE8eUZ-x3ByJhuEQ3XQefKrfKYJkIGBFP-1qqOMiuc18_JkC1JuuylVjR_3Bdymh4O6gB0hU6qYqkjGffUkBVo7rQ-lryUJOzAE3cWTBXiBBAUxl3Kjag0_WbHmXbJ4YtFg-pCinT34POs8RL991yFwBnKWBPmejPID?width=600&height=600&cropmode=none", description: "50 (M010)", url:"mineraloilbasedengineoils.url24" },
    { option: "mapmineraloilbasedengineoils25", name: "namemineraloilbasedengineoils25", imgUrl: "https://dsm01pap002files.storage.live.com/y4maTeZ9O95mox_P-izMqHsRRBtT38eIprdPPLn7dATSxy0GfXH3G3NqfEdu6XU2T38pH7G0ZXPzP_adQ85Br6gb7IRRmKN4Quv-VYffe5BeO0FhkRxxFe5ryktGCJBTxyowUFUieVbTfK9aQScXJ59TS2I38o7pa49tzZzLFH2iWc8d1vHE_BXZet-QbuPOsxq?width=600&height=600&cropmode=none", description: "15W-40 (M004)", url:"mineraloilbasedengineoils.url25" },
    { option: "mapmineraloilbasedengineoils26", name: "namemineraloilbasedengineoils26", imgUrl: "https://dsm01pap002files.storage.live.com/y4mSMCd8FY8gTH9CHzsIrWyJhA5RupZCZuixmdLrA0N-7UMFm2yFM3DuxkhhlR8rFCWDrTAcTTVciufeqM05TUXv2msY9GDbtJ7PhZA15NxKY-oJGqyXR8zuTqCQXTrF4DduXJ2um8wYpPmIrJ6n-Z5Ma6IHyoKKrz3X-p4NlRrPqh-FTRf4XQRaGA348nTTyVM?width=600&height=600&cropmode=none", description: "20W-50 (M005)", url:"mineraloilbasedengineoils.url26" },
    { option: "mapmineraloilbasedengineoils27", name: "namemineraloilbasedengineoils27", imgUrl: "https://dsm01pap002files.storage.live.com/y4mQbJYjoLlL4cv3i2TzxTCzTUkL9B-FoS667DnyXS7ViA3q2bdTjh5l4lijiJGh9Rt5xKg_e2A17usUHZHJvWr_GhO8jVEJ_Ty3cHJxW8eC5QsStTbkHI41iipqOKetFZLiOWRZn-yV6dBUeAnGEJJIXDUuDmFA9ec44wTmbZY0lnBuMc3aljP-5-aXOyIx3u5?width=600&height=600&cropmode=none", description: "30 (M123)", url:"mineraloilbasedengineoils.url27" },
    { option: "mapmineraloilbasedengineoils28", name: "namemineraloilbasedengineoils28", imgUrl: "https://dsm01pap002files.storage.live.com/y4m5IkOZX89p2toLqZjXjk0HCcjuq7UUfsbauZ59GH3XuA5shsw3-VT1HFoLg4JhMtX6L3KeiAZXLLck9ZDyz8hIG4iuDuIaa-D3n3Izfp0LmwhKWJjG4qDV4j3ie71EztdDGf_Pm8dbC_L8JJlNfcp0IJqdvHy_TpZLdD7pDjUpkwpd8GQJdWWhWdLoZ4HZVGD?width=600&height=600&cropmode=none", description: "40 (M141)", url:"mineraloilbasedengineoils.url28" },
    { option: "mapmineraloilbasedengineoils29", name: "namemineraloilbasedengineoils29", imgUrl: "https://dsm01pap002files.storage.live.com/y4mNhNTvHoumAGTeFd9QCFYuBBxCA50Gu4HYQJ3JaEPZyBOP18Itu0yFIjS9o9ukniWUXJa97i1pIy1xY6QpKvKOMuGY_VGDMHc3zUn_gseDvUE24gJAYTo-ugGwX2AT7bcutZkWnpeEimNoKvtG-b6nRtRJUhHZUctJ0twdergo1_V-ohWblwh-Jo_qzUN_-Cd?width=600&height=600&cropmode=none", description: "50 (M142)", url:"mineraloilbasedengineoils.url29" },
];
  
  export default listProductsMineralOilBasedEngineOils;