import React, { useState } from "react";
import "./sliderOtherMark.css";
import { AskInformationProducts } from "../Modal/AskInformationProducts";

export const SliderOtherMark = () => {
  // Inicio Modal Solicitud de Informacion Productos

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Fin Modal Solicitud de Informacion Productos

  // Imagenes Slider Mark

  const milesLubricantsLogo =
    "https://dsm01pap002files.storage.live.com/y4mTwODWe8UDiQF5O9I0AydchzDlHQzNgg-7wUpRK5iOhdhI5D0iUcnqS4WP7L6CWI7tl4_vrjzomxfYkjD7x6N1XulKtm4r6jIrdgXK8ugCXv0e7IHgXTNDEq1WDT6rIGEZNfvzCeAWcnQtn8qnN-25W9WDheFGXzAppVt-v5sqi08Wtk4bBUy7En6yh5sU8Nr?width=500&height=200&cropmode=none";
  const lubriPlateLogo =
    "https://dsm01pap002files.storage.live.com/y4m4b9vybpt1JurwyifN7zQD23xgN2-8eYuP3rAGZw6irtuvapjIEEw-4kMohLC-4fUV_0MHxXB-LTKcSCgwOrlCkVghFug5JOK4hHTgUYMR-dIfDS2426xls_iqQzHmb1SeFBr1M4fYcl_xlb_cdn0BX5LHf4rZhdz6uHt-KBF7EcxfdNjEXyrRP1bbE38qX-Z?width=500&height=200&cropmode=none";
  const summitLogo =
    "https://dsm01pap002files.storage.live.com/y4my6DL6-79nr5KKnaakFfMAADndcAnnD7-7woxuSfYhTwEyC9VTK-EBP4jZNeLbsgtg0Yo0hZwmlkhQj3qo2Ky41uYK7qOZ-X7arNNcoxWb-Qxrtbnz5W43oJHjP94RXXeLXKXr60TxKZa-oqE491amshTF1LlrbivbjDRWMghP2uQe2Ah_LaWm2_0cWSRSx2s?width=500&height=200&cropmode=none";
  const shellLogo =
    "https://dsm01pap002files.storage.live.com/y4mSwA02sE_1-T2vCs2-3erIgHTaib3Vkim8FPh8QKprg2QXSUWJrpR6rzzOyUZYIYRggXuuqUMx9kZHW9tZG64HpM0bmOBBhVXpo42zhZm-Gd6wA4zeDOQywXlcfhcH_dK7IPuolHeq587Pjk0hIDg9ocx5G_hUwunn9-XfzGVdneN2ZRCd4QJ5p-sFLK7sdxW?width=500&height=200&cropmode=none";
  const citgoLogo =
    "https://dsm01pap002files.storage.live.com/y4m3VCP8tLIubz262-riZjvsgMvtSti_a6vEddzMh4gek2ZZ5BXFJwxQhc4Illdd8nVqlbdLCQsFtjlPk3teEvsr_gbG3Ic2J75pPOMAZpLsCA4KU-hMe7_sau4rHTcpBrvwhRGrIBawINIY22C3646RDbQHv9UIEeRcieMMKkbSWYjilg1wM7q_e000BGChinc?width=500&height=200&cropmode=none";
  const gulfLogo =
    "https://dsm01pap002files.storage.live.com/y4mwDLR9nHx0bVQAS_PihUR1ZDEsY2zi0rnwm6cyFZvN2eFK1J5IhRB6gzJedqbzq3zpVOB1wPTzyTnEyQYVaoeWvg8lxllit8ssEXLw0ciRrOj0xV2AgMQ03814oUyOqi5w16uZ0XpKvIm3mlkOS2UugbNvBg7lcq9gwi7OVMB1CyS9K9SRTTDPbCpa6JHxTuw?width=500&height=200&cropmode=none";
  const arconol =
    "https://dsm01pap002files.storage.live.com/y4mqm0PwsTAA4HKs_Pt8W8Ul99Sgl2OCoVQdtsUOAQDnyfoiUc6XzFr4zeS0JHaY0mkLWZxH5DDSN6cv2PbF00Y0JUeSJaNvMSYK3UUgOMj7ApD2Y_4oQ5caQ1V-8gtoRsLnLOLjzuvpbC6mpnDWCJuTxa6Wg5C01dW9BpLMQb4X3SuDO2EHYqDhBSgoUhkvnw-?width=500&height=200&cropmode=none";
  const engis =
    "https://dsm01pap002files.storage.live.com/y4mBAeprlD8Ugh4d3MtS5e5oAqcds_AxdKS7sL3rB5ZH4ZHke-MSKCC6hNsaXNjgg44V9QEl6pw1so6NWO8ifM3WbYCGYr-DkA4E6dHwdCNkyHxcB12JWM13vmYByCfjvGQ7FE3n7W8-XNx9kuuYKkfPy2HVbPriMukw73xlFBS5C5onmVStAGEFf8TUzqVsJQv?width=500&height=200&cropmode=none";
  const skf =
    "https://dsm01pap002files.storage.live.com/y4muJ6o-KJFv6VZ33WkSjp6g_86EPLFgncYPwKuqd7fl5pjWcyUvsxqKbn4E48wdd9R4t_bgti5lU8z5IYTijvbMgetF3fHj3aVbKohTdzWQx-tFbEybzXpzKVIOMyedNuLzzZ-3ijPXvBbWArxzM7PxI3h2H0uRA5G-spT9GCp9Uqn7V1Khu-MVtjrLM3JcxZ9?width=500&height=200&cropmode=none";
  const kluber =
    "https://dsm01pap002files.storage.live.com/y4m9nQCnzKEC27BcEBvWBc2YKbXGfYT31Uv122tEd7JXfXU15JtXbzutn-dF_VZaO7W0ljgIecq7SoRs_7D2nrFiWtQuxy0TU467tvNXnEiWN0EOxEaypBSQ089JEdXA7TWWJJhVssZB_-ga0bzmc-WDj-vXKwy1eqWyegTPAHCLs4V0IuIqUoC_Rc_4Jc_huGM?width=500&height=200&cropmode=none";
  const shellGadus =
    "https://dsm01pap002files.storage.live.com/y4m0GsQnGYPcfVpUEQEQ6WLbNhhvhKd0AhHhGO-86MSRZBS6XxQcUmkhkvjJIxPXjE5onjiERt9IlOneaBQ4RRSv1JFD8sSz5up0kh1RCr40ajKOjBQ08FEhYmFZJ2OjmWGjJ9OR0JTQ1Rc6LL2DPVt03Nw81Lc6EMeuzXwOEkx80hrhviIntz6_MyAGwrLhxSq?width=500&height=200&cropmode=none";

  return (
    <div className="container-sliderothermark">
      <div className="sliderothermark" onClick={handleOpenModal}>
        <div className="sliderothermarkrun">
          <img src={milesLubricantsLogo} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={shellLogo} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={arconol} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={summitLogo} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={skf} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={engis} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={lubriPlateLogo} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={kluber} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={citgoLogo} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={shellGadus} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={gulfLogo} height="100" width="250" alt="" />
        </div>
        {/* Aqui se repite las imagenes */}
        <div className="sliderothermarkrun">
          <img src={milesLubricantsLogo} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={shellLogo} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={arconol} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={summitLogo} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={skf} height="100" width="250" alt="" />
        </div>
          <div className="sliderothermarkrun">
          <img src={engis} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={lubriPlateLogo} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={kluber} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={citgoLogo} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={shellGadus} height="100" width="250" alt="" />
        </div>
        <div className="sliderothermarkrun">
          <img src={gulfLogo} height="100" width="250" alt="" />
        </div>
      </div>
      <AskInformationProducts showModal={showModal} onHide={handleCloseModal} />{" "}
      {/* Modal Escondido */}
    </div>
  );
};
