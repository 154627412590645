import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import listProductsHydraulicOilsIn from "../ProductsExolIndustrial/listProductsHydraulicOilsIn";
import "../../../assets/css/pages/Exol/Category/Industrial/CategoryIndustrial.css";
import { AskInformationProducts } from "../../../components/Modal/AskInformationProducts";

export const HydraulicOilsIn = () => {
  
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  // Inicio Modal Solicitud de Informacion Productos

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Fin Modal Solicitud de Informacion Productos

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "hydraulicoilsin.title",
    defaultMessage: "Exol - Aceites Hidráulicos | Industrial", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "hydraulicoilsin.description",
    defaultMessage:
      "Aceites hidráulicos industriales de Exol: lubricación eficiente, protección confiable y alto rendimiento en maquinarias industriales.", //Cambia Descripcion Pagina
  });

  return (
    <section className="categoryindustrial">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-categoryindustrial">
        <div className="head-categoryindustrial-img"></div>
        <div></div>
      </div>
      <Container>
        <Row className="product-categoryindustrial-row1"></Row>
        <Row className="product-categoryindustrial-row2">
          <h1>
            <FormattedMessage
              id="hydraulicoilsin.row2-h1"
              defaultMessage="Productos | Aceites Hidráulicos"
            />
          </h1>
          <Col>
            <div className="container-listproductsindustrial">
              {listProductsHydraulicOilsIn.map((product, index) => (
                <div
                  key={index}
                  className="product-link-categoryindustrial"
                  onClick={handleOpenModal}
                  // onClick={scrollToTop}
                >
                  <div className="map-listproductindustrial">
                    <h2>
                      <FormattedMessage
                        id={product.name}
                        defaultMessage={product.name}
                      />
                    </h2>
                    <img
                      src={product.imgUrl}
                      alt="Lubricantes Industriales - Aceites Hidráulicos"
                    />
                    <p>
                      <FormattedMessage
                        id={product.option}
                        defaultMessage={product.option}
                      />
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <AskInformationProducts
              showModal={showModal}
              onHide={handleCloseModal}
            />{" "}
            {/* Modal Escondido */}
          </Col>
        </Row>
        <Row className="product-categoryindustrial-row3"></Row>
      </Container>
    </section>
  );
};
