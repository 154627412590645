import React from "react";
import "../assets/css/productSubMenu.css";
import { Col, Container, Row } from "react-bootstrap";
import BioborSubMenuImg from "../assets/img/mark/biobor_submenu_logo.png";
import ExolSubMenuImg from "../assets/img/mark/exol_submenu_logo.png";
import FlechaDerecha from "../assets/img/icon/arrow_right.png";
import { FormattedMessage, useIntl } from "react-intl";

export const ProductSubMenu = () => {
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  return (
    <section className="productsubmenu">
      <Container>
        <Row>
          <Col size="12" sm="6" className="productsubmenucol1">
            <img
              src={BioborSubMenuImg}
              alt="The best additives to protect your engine with Biobor"
              className="product_submenu_logo"
            />
            <span>
              {" "}
              <FormattedMessage
                id="biobor.submenu-additives"
                defaultMessage="  Aditivos"
              />
            </span>
            <ul>
              <li>
                <a
                  href={intl.formatMessage({
                    id: "aviation.products-url",
                  })}
                >
                  <img
                    src={FlechaDerecha}
                    alt="biobor_arrow_right"
                    className="submenu_arrow_right"
                  />
                  <FormattedMessage
                    id="product.aviation"
                    defaultMessage="  PRODUCTOS DE AVIACION"
                  />
                </a>
              </li>
              <li>
                <a
                  href={intl.formatMessage({
                    id: "diesel.products-url",
                  })}
                >
                  <img
                    src={FlechaDerecha}
                    alt="biobor_arrow_right"
                    className="submenu_arrow_right"
                  />
                  <FormattedMessage
                    id="product.diesel"
                    defaultMessage="  PRODUCTOS DIESEL"
                  />
                </a>
              </li>
              <li>
                <a
                  href={intl.formatMessage({
                    id: "gasoline.products-url",
                  })}
                >
                  <img
                    src={FlechaDerecha}
                    alt="biobor_arrow_right"
                    className="submenu_arrow_right"
                  />
                  <FormattedMessage
                    id="product.gasoline"
                    defaultMessage="  PRODUCTOS DE GASOLINA"
                  />
                </a>
              </li>
              <li>
                <a
                  href={intl.formatMessage({ id: "accessories.products-url" })}
                >
                  <img
                    src={FlechaDerecha}
                    alt="biobor_arrow_right"
                    className="submenu_arrow_right"
                  />
                  <FormattedMessage
                    id="product.accessories"
                    defaultMessage="  ACCESORIOS"
                  />
                </a>
              </li>
            </ul>
          </Col>
          <Col size="12" sm="6" className="productsubmenucol2">
            <img
              src={ExolSubMenuImg}
              alt="The Best Lubricants for Your Engines"
              className="product_submenu_logo"
            />
            <span>
              <FormattedMessage
                id="biobor.submenu-lubricants"
                defaultMessage="  Lubricantes"
              />
            </span>
            <ul>
              <li>
                <a
                  href={intl.formatMessage({
                    id: "exol.automotive-url",
                  })}
                >
                  <img
                    src={FlechaDerecha}
                    alt="biobor_arrow_right"
                    className="submenu_arrow_right"
                  />
                  <FormattedMessage
                    id="exol.automotive"
                    defaultMessage="  Automotive"
                  />
                </a>
              </li>
              <li>
                <a
                  href={intl.formatMessage({
                    id: "exol.commercial-url",
                  })}
                >
                  <img
                    src={FlechaDerecha}
                    alt="biobor_arrow_right"
                    className="submenu_arrow_right"
                  />
                  <FormattedMessage
                    id="exol.commercial"
                    defaultMessage="  Commercial"
                  />
                </a>
              </li>
              <li>
                <a
                  href={intl.formatMessage({
                    id: "exol.industrial-url",
                  })}
                >
                  <img
                    src={FlechaDerecha}
                    alt="biobor_arrow_right"
                    className="submenu_arrow_right"
                  />
                  <FormattedMessage
                    id="exol.industrial"
                    defaultMessage="  Industrial"
                  />
                </a>
              </li>
              {/* <li>
                <a href={intl.formatMessage({ id: "exol.products-url" })}>
                  <img
                    src={FlechaDerecha}
                    alt="biobor_arrow_right"
                    className="submenu_arrow_right"
                  />
                  <FormattedMessage
                    id="exol.marine"
                    defaultMessage="  Marine"
                  />
                </a>
              </li>
              <li>
                <a href={intl.formatMessage({ id: "exol.products-url" })}>
                  <img
                    src={FlechaDerecha}
                    alt="biobor_arrow_right"
                    className="submenu_arrow_right"
                  />
                  <FormattedMessage
                    id="exol.power-generation"
                    defaultMessage="  Power Generation"
                  />
                </a>
              </li>
              <li>
                <a href={intl.formatMessage({ id: "exol.products-url" })}>
                  <img
                    src={FlechaDerecha}
                    alt="biobor_arrow_right"
                    className="submenu_arrow_right"
                  />
                  <FormattedMessage
                    id="exol.antifreeze"
                    defaultMessage="  Antifreeze"
                  />
                </a>
              </li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
