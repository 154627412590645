import Carousel from "react-bootstrap/Carousel";
// Importar Language / FormatJS
import { FormattedMessage, useIntl } from "react-intl";

export const Banner = () => {

  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Fondos de Banner */

  const bannerAviation = "https://dsm01pap002files.storage.live.com/y4mn7DrPtfPPSDbZDtS7XIkyM7I2EJH5FDBBppyKSpO_U2SVa-Tk2ECFsfETxKrftlbVddpG-ENWgVbFh0KMJa5McdFoi1F4FZETULFuOqT8k6NgvRAa0zX-LURUbd1J8JmzaKlY6kfBGAy-Q5ZMsFWo4qEy_kzo3BLRJhFQcW6e9aWi4r1Z8qvHYlC85X6SnZ0?width=1500&height=1003&cropmode=none";
  const bannerOil = "https://dsm01pap002files.storage.live.com/y4msvmDUVJm5nzPyZ2RqWwNzEOIOLr2CkIq3T4E7JcAjR_LKqrbVPXlfYI-AmDGnzQeBjS6vPHdZm8njgAizjkxuy7avsIcTRKviOEJZ4-i-Uv9KIZ9oDPtfoXggHOjqSguGIScLCilTT9PAZvf5LqieMg0V_nNd03jtXfRLtKDJocsmP0NnfNshScvx3Tkbg2V?width=1600&height=1202&cropmode=none";
  const bannerTrucks = "https://dsm01pap002files.storage.live.com/y4mFWJQ5WamqFCkNpPv-rxhzk2Zljk3UL3M7WQX0dhQ_3us-obGT3MMH2nam_NIHqpVJhzu6KkNJISaBMHLbqQQwi5xkzgNIz-Zqc68GWIKm0iUaxRxR74MZSxrCSuLxW97vCEODimrCmhPFkfm8BOrCd2iB16hXcZvxVWZG2aHEhxdmdQU4R_sU9OaWOJ5qO0h?width=1500&height=1000&cropmode=none";
  const bannerGasoline = "https://dsm01pap002files.storage.live.com/y4mLihdC2U1Qw1WVC_AkSmmyJAmB_emTKFKICcIwh5PGlUe6fF-Dq0ty6xaVnDOQVgHhCDDT3s3moY84GNWLxtPjFtd-UQ_DW00XefC6xSrkvv-KBQUKK0ZUXpNyrSlkpzcoDUeQ-1mTgCfJ4skxdqNz7tiGqLdbL11adzzajPIQhaaDOCe1cz0D7EWcALNVoa0?width=1500&height=842&cropmode=none";
  const bannerStation = "https://dsm01pap002files.storage.live.com/y4mpcPQuEPCT1-jgvkGj6OtpYgRC6xWOGlunOEayWKSZkn0x4TXVb-7njLh2Gf2IYjIVa3gIEUdT_e2xNI0GuEzA553-4vBkDZgLqgZP4tnxzWc2Dglm03fz_Bj_vNFWgNL0nRu0ydFXLkOjxz12FtVXtj29pmvURAxkoOT3xlfzqAtbOfqqVEQOE6cw29Uy23X?width=1500&height=1000&cropmode=none";

  return (
    <div className="banner" id="home">
      <Carousel>
        <Carousel.Item>
          <div className="git statu">
            <img
              className="d-block w-100"
              src={bannerAviation}
              alt="First slide"
            />
            <Carousel.Caption>
              <h2>Biobor®JF Aviation</h2>
              <span className="carousel-span1">
                <FormattedMessage
                  id="banner1.span"
                  defaultMessage="La Opción Aprobada por el Fabricante Original desde 1965"
                />
              </span>
              <br />
              <h3 className="carousel-h3">
                {/* <FormattedMessage id="navbar.closedSesion" defaultMessage="Cerrar Sesion" /> */}
                <FormattedMessage
                  id="banner1.h3"
                  defaultMessage="El ÚNICO Combustible Biocida Disponible para Aviación"
                />
              </h3>
              <div className="container-carousel-btn1">
                <button
                  className="carousel-btn1"
                  onClick={() =>
                    window.open(intl.formatMessage({ id: "aviation.url1" }), "_blank")
                  }
                >
                  <span className="carousel-span3">
                    <FormattedMessage
                      id="banner.seemore"
                      defaultMessage="Ver Más"
                    />
                  </span>
                </button>
              </div>
            </Carousel.Caption>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bannerOil}
            alt="Second slide"
            height="auto"
          />
          <Carousel.Caption>
            <h2>Biobor®JF</h2>
            <span className="carousel-span1">
              <FormattedMessage
                id="banner2.span"
                defaultMessage="La Opción Aprobada por el Fabricante Original desde 1965"
              />
            </span>
            <br />
            <h3 className="carousel-h3">
              <FormattedMessage
                id="banner2.h3"
                defaultMessage="El Principal Biocida para Diesel: Líder en Eficacia y Calidad"
              />
            </h3>
            <div className="container-carousel-btn1">
              <button
                className="carousel-btn1"
                onClick={() =>
                  window.open(intl.formatMessage({ id: "diesel.url1" }), "_blank")
                }
              >
                <span className="carousel-span3">
                  <FormattedMessage
                    id="banner.seemore"
                    defaultMessage="Ver Más"
                  />
                </span>
              </button>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bannerTrucks}
            alt="Third slide"
            height="auto"
          />
          <Carousel.Caption>
            <span className="carousel-span1">
              <FormattedMessage
                id="banner3.span"
                defaultMessage="La Opción Aprobada por el Fabricante Original desde 1965"
              />
            </span>
            <br />
            <h3 className="carousel-h3">
              <FormattedMessage
                id="banner3.h3"
                defaultMessage="Rendimiento y Calidad del Combustible Diésel para Flotas Marinas, Almacenamiento y Camiones"
              />
            </h3>
            <div className="container-carousel-btn1">
              <button
                className="carousel-btn1"
                onClick={() =>
                  window.open(intl.formatMessage({ id: "diesel.products-url" }), "_blank")
                }
              >
                <span className="carousel-span3">
                  <FormattedMessage
                    id="banner.seemore"
                    defaultMessage="Ver Más"
                  />
                </span>
              </button>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bannerGasoline}
            alt="Fourth slide"
            height="auto"
          />
          <Carousel.Caption>
            <span className="carousel-span1">
              <FormattedMessage
                id="banner4.span"
                defaultMessage="La Opción Aprobada por el Fabricante Original desde 1965"
              />
            </span>
            <br />
            <h3 className="carousel-h3">
              <FormattedMessage
                id="banner4.h3"
                defaultMessage="Potencia tus Equipos con Nuestro Tratamiento de Calidad y Aditivos para Gasolina"
              />
            </h3>
            <div className="container-carousel-btn1">
              <button
                className="carousel-btn1"
                onClick={() =>
                  window.open(intl.formatMessage({ id: "gasoline.products-url" }), "_blank")
                }
              >
                <span className="carousel-span3">
                  <FormattedMessage
                    id="banner.seemore"
                    defaultMessage="Ver Más"
                  />
                </span>
              </button>
            </div>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block w-100"
            src={bannerStation}
            alt="Third slide"
            height="auto"
          />
          <Carousel.Caption>
            <span className="carousel-span1">
              <FormattedMessage
                id="banner5.span"
                defaultMessage="La Opción Aprobada por el Fabricante Original desde 1965"
              />
            </span>
            <br />
            <h3 className="carousel-h3">
              <FormattedMessage
                id="banner5.h3"
                defaultMessage="Maximiza Equipos con Aditivos de Petróleo: Eficiencia, Rendimiento y Protección Duradera"
              />
            </h3>
            <div className="container-carousel-btn1">
              <button
                className="carousel-btn1"
                onClick={() =>
                  window.open(intl.formatMessage({ id: "diesel.products-url" }), "_blank")
                }
              >
                <span className="carousel-span3">
                  <FormattedMessage
                    id="banner.seemore"
                    defaultMessage="Ver Más"
                  />
                </span>
              </button>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
