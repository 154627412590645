import img1 from '../../../assets/img/exol/products/industrial/forming-oils/1-N189-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/forming-oils/2-F004-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/forming-oils/3-F044-600x600.webp';

const listProductsFormingOilsIn = [

  { option: "mapformingoilsin1", name: "nameformingoilsin1", imgUrl: img1, description: "", url: "formingoilsin.url1" },
  { option: "mapformingoilsin2", name: "nameformingoilsin2", imgUrl: img2, description: "", url: "formingoilsin.url2" },
  { option: "mapformingoilsin3", name: "nameformingoilsin3", imgUrl: img3, description: "", url: "formingoilsin.url3" },

];
  
  export default listProductsFormingOilsIn;