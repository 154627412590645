import React from "react";
import "../assets/css/info3.css";
import { Container, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export const Info3 = () => {
  return (
    <section className="info3">
      <Container>
        <Row className="container1-info3">
          <h2>
            <FormattedMessage
              id="info3.row1-h2"
              defaultMessage="Nuestro aditivo para motores está diseñado para elevar el octanaje de la gasolina y mejorar el rendimiento del motor"
            />
          </h2>
          <p>
            <FormattedMessage
              id="info3.row1-p1"
              defaultMessage="Biobor es el aditivo de combustible líder para aviación, diésel y gasolina."
            />
          </p>
          {/* <div className="container-btn-info3">
            <button>TIENDA EN LINEA</button>
            <button>ENCUENTRA UNA TIENDA</button>
            <button>GRANEL/MAYORISTA</button>
          </div> */}
        </Row>
      </Container>
    </section>
  );
};
