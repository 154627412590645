const listProductsDiesel = [

    { option: "mapdiesel1", name: "Biobor JF", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/06/Biobor-JF-NA-16-oz-cropped-168x300.png", description: "FUEL biocida y aditivo lubricante – El estándar MUNDIAL", url:"diesel.url1"},
    { option: "mapdiesel2", name: "Biobor MD", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/01/BioborMD32-oz-bottle-167x300.png", description: "Potenciador del rendimiento y la eficiencia diésel", url:"diesel.url2" },
    { option: "mapdiesel3", name: "Biobor DC + Cetane", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/01/biobor-dc-plus-cetane-1-gallon-jug-162x300.png", description: "Acondicionador diésel y aditivo de rendimiento", url:"diesel.url3" },
    { option: "mapdiesel4", name: "Biobor DC + Cetane Winter", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/02/biobor-dc-plus-cetane-winter-1-gallon-jug-162x300.png", description: "Acondicionador diésel y rendimiento con protección invernal", url:"diesel.url4" },
    { option: "mapdiesel5", name: "Coldflo with Lubribor", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/01/ColdFlo32-oz-bottle-162x300.png", description: "Combustible diesel anti-gel y descongelante más lubricidad", url:"diesel.url5" },
    { option: "mapdiesel6", name: "Lubribor", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/01/Lubribor-16-Oz-162x300.png", description: "Lubricante de alta tecnología para combustibles bajos en azufre", url: "diesel.url6"},
    { option: "mapdiesel7", name: "Hum-bug Detector® kit", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/01/Hum-Bug-Kit-Bottle-Copy-768x895.png", description: "¡Advertencia temprana! detecta el crecimiento microbiano en el combustible", url: "diesel.url7"},
    { option: "mapdiesel8", name: "FuelStat® Plus", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/03/fuelstat-resinae-plus-450x454-1-297x300.png", description: "Detección fácil, precisa y rápida del crecimiento microbiano en el combustible", url: "diesel.url8"}
  ];
  
  export default listProductsDiesel;
  