import img1 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/1-z018-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/2-exol-montage1-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/3-L118-600x600.webp';
import img4 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/4-L132-600x600.webp';
import img5 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/5-g137-600x600.webp';
import img6 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/6-g126-600x600.webp';
import img7 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/7-g129-600x600.webp';
import img8 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/8-g170-600x600.webp';
import img9 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/9-h199-600x600.webp';
import img10 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/10-h182-600x600.webp';
import img11 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/11-h169-600x600.webp';
import img12 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/12-h178-600x600.webp';
import img13 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/13-h207-600x600.webp';
import img14 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/14-c076-600x600.webp';
import img15 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/15-c062-600x600.webp';
import img16 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/16-h219-600x600.webp';
import img17 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/17-h220-600x600.webp';
import img18 from '../../../assets/img/exol/products/industrial/food-grade-oils-and-lubricants/18-h221-600x600.webp';

const listProductsFoodGradeOilLubricantsIn = [

  { option: "mapfoodgradeoillubricantsin1", name: "namefoodgradeoillubricantsin1", imgUrl: img1, description: "", url: "foodgradeoillubricantsin.url1" },
  { option: "mapfoodgradeoillubricantsin2", name: "namefoodgradeoillubricantsin2", imgUrl: img2, description: "", url: "foodgradeoillubricantsin.url2" },
  { option: "mapfoodgradeoillubricantsin3", name: "namefoodgradeoillubricantsin3", imgUrl: img3, description: "", url: "foodgradeoillubricantsin.url3" },
  { option: "mapfoodgradeoillubricantsin4", name: "namefoodgradeoillubricantsin4", imgUrl: img4, description: "", url: "foodgradeoillubricantsin.url4" },
  { option: "mapfoodgradeoillubricantsin5", name: "namefoodgradeoillubricantsin5", imgUrl: img5, description: "", url: "foodgradeoillubricantsin.url5" },
  { option: "mapfoodgradeoillubricantsin6", name: "namefoodgradeoillubricantsin6", imgUrl: img6, description: "", url: "foodgradeoillubricantsin.url6" },
  { option: "mapfoodgradeoillubricantsin7", name: "namefoodgradeoillubricantsin7", imgUrl: img7, description: "", url: "foodgradeoillubricantsin.url7" },
  { option: "mapfoodgradeoillubricantsin8", name: "namefoodgradeoillubricantsin8", imgUrl: img8, description: "", url: "foodgradeoillubricantsin.url8" },
  { option: "mapfoodgradeoillubricantsin9", name: "namefoodgradeoillubricantsin9", imgUrl: img9, description: "", url: "foodgradeoillubricantsin.url9" },
  { option: "mapfoodgradeoillubricantsin10", name: "namefoodgradeoillubricantsin10", imgUrl: img10, description: "", url: "foodgradeoillubricantsin.url10" },
  { option: "mapfoodgradeoillubricantsin11", name: "namefoodgradeoillubricantsin11", imgUrl: img11, description: "", url: "foodgradeoillubricantsin.url11" },
  { option: "mapfoodgradeoillubricantsin12", name: "namefoodgradeoillubricantsin12", imgUrl: img12, description: "", url: "foodgradeoillubricantsin.url12" },
  { option: "mapfoodgradeoillubricantsin13", name: "namefoodgradeoillubricantsin13", imgUrl: img13, description: "", url: "foodgradeoillubricantsin.url13" },
  { option: "mapfoodgradeoillubricantsin14", name: "namefoodgradeoillubricantsin14", imgUrl: img14, description: "", url: "foodgradeoillubricantsin.url14" },
  { option: "mapfoodgradeoillubricantsin15", name: "namefoodgradeoillubricantsin15", imgUrl: img15, description: "", url: "foodgradeoillubricantsin.url15" },
  { option: "mapfoodgradeoillubricantsin16", name: "namefoodgradeoillubricantsin16", imgUrl: img16, description: "", url: "foodgradeoillubricantsin.url16" },
  { option: "mapfoodgradeoillubricantsin17", name: "namefoodgradeoillubricantsin17", imgUrl: img17, description: "", url: "foodgradeoillubricantsin.url17" },
  { option: "mapfoodgradeoillubricantsin18", name: "namefoodgradeoillubricantsin18", imgUrl: img18, description: "", url: "foodgradeoillubricantsin.url18" },

];


export default listProductsFoodGradeOilLubricantsIn;
