import React, { useContext } from "react";
import "../assets/css/pages/aviation.css";
import { Container, Row, Col } from "react-bootstrap";
import listProductsAviation from "./listProductsAviation";
import { Products } from "../components/Products";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

export const Aviation = () => {
  
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "aviation.title",
    defaultMessage:
      "Aditivos para Aviación: Potencia y Protección Aérea | OilFluid", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "aviation.description",
    defaultMessage:
      "Descubre los aditivos Biobor de aviación: protección confiable para motores de aviones. Optimiza el rendimiento y previene problemas como la corrosión y la contaminación microbiana. Mejora la eficiencia y prolonga la vida útil. ¡Eleva la calidad y seguridad en tus vuelos con Biobor!", //Cambia Descripcion Pagina
  });

  return (
    <section className="aviation">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-aviation">
        <div className="head-aviation-img"></div>
        <div>
          <h1 className="head-aviation-text">
            <FormattedMessage
              id="aviation.h1"
              defaultMessage="ADITIVOS DE AVIACIÓN"
            />
          </h1>
        </div>
      </div>
      <Container>
        <Row className="product-aviation-row1">
          <p>
            <FormattedMessage
              id="aviation.row1-p1"
              defaultMessage="Desde 1965, los productos BIOBOR han sido líderes en la industria de la aviación, brindando soluciones efectivas contra el crecimiento microbiano en combustibles de aviación y equipos de apoyo en tierra. Biobor JF se destaca como el biocida de combustible más reconocido a nivel mundial. Nuestros aditivos Biobor protegen y mejoran el rendimiento de todos los equipos con motor diésel y gasolina utilizados en la industria de la aviación. Confía en BIOBOR para garantizar un funcionamiento óptimo y seguro de tus aviones y equipos de apoyo en tierra."
            />
          </p>
        </Row>
        <Row className="product-aviation-row2">
          <h2>
            <FormattedMessage
              id="aviation.row2-h2"
              defaultMessage="Productos de Combustible de Aviación"
            />
          </h2>
          <Col>
            <div className="container-listProductsAviation">
              {listProductsAviation.map((product, index) => (
                <a
                  key={index}
                  href={intl.formatMessage({
                    id: product.url,
                    defaultMessage: product.url,
                  })}
                  className="product-link-aviation"
                  // onClick={scrollToTop}
                >
                  <div className="map-listProductAviation">
                    <h2>{product.name}</h2>
                    <img src={product.imgUrl} alt="Biobor JF Aviation" />
                    <p>
                      <FormattedMessage
                        id={product.option}
                        defaultMessage={product.option}
                      />
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="product-aviation-row3">
          <h2>
            <FormattedMessage
              id="aviation.row3-h2x1"
              defaultMessage="¿Por qué usar nuestros productos de aviación?"
            />
          </h2>
          <p>
            <FormattedMessage
              id="aviation.row3-p1"
              defaultMessage="Pocos productos pueden presumir de ser los primeros y los originales. En los primeros días de la aviación comercial a reacción, las aerolíneas y los fabricantes de aeronaves se encontraron con un grave problema: el crecimiento de microorganismos en el combustible. Este crecimiento no solo obstruía las líneas de combustible y los filtros, sino que también causaba corrosión que ponía en peligro la integridad estructural de las aeronaves. En colaboración con U.S. Borax y SOHIO, científicos e ingenieros desarrollaron el primer biocida específicamente diseñado para el entorno aeronáutico: Biobor JF®. A lo largo de la historia de los combustibles de aviación, solo se han aprobado dos biocidas. Actualmente, Biobor JF® es el único biocida de combustible disponible a nivel mundial, después de que el otro biocida previamente aprobado, responsable de varios incidentes de seguridad, haya sido retirado permanentemente del mercado."
            />
          </p>
          <p>
            <FormattedMessage
              id="aviation.row3-p2"
              defaultMessage="Desde su lanzamiento en 1965, Biobor JF® ha establecido su reputación como el biocida preferido en la industria de la aviación. Sin embargo, su influencia no se limita a la aviación, ya que también ha dominado otros sectores como la industria marítima, los generadores de energía de reserva, la agricultura y los equipos de construcción. A lo largo de todos estos años, Biobor JF® ha demostrado ser el líder indiscutible en la protección de combustibles y lubricantes hidrocarburos. Como el primero en su categoría, Biobor JF® sigue siendo la opción preferida y la referencia en la industria."
            />
          </p>
          <h2>
            <span>
              <FormattedMessage
                id="aviation.row3-h2x2"
                defaultMessage="Ver Otros Productos"
              />
            </span>
          </h2>
        </Row>
        {/* {!isAuthenticated ? (
          <Products /> ) : (
                  <Info1 />
                )} */}
        <Products />
        <Row className="product-aviation-row4">
          <h2>
            <FormattedMessage
              id="aviation.row4-h2"
              defaultMessage="Biobor JF® El único biocida disponible para uso en aviación."
            />
          </h2>
        </Row>
      </Container>
    </section>
  );
};
