import img1 from '../../../assets/img/exol/products/industrial/quenchants/1-Q001-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/quenchants/2-Q016-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/quenchants/3-Q005-600x600.webp';
import img4 from '../../../assets/img/exol/products/industrial/quenchants/4-Q017-600x600.webp';
import img5 from '../../../assets/img/exol/products/industrial/quenchants/5-q009-600x600.webp';
import img6 from '../../../assets/img/exol/products/industrial/quenchants/6-q022-600x600.webp';
import img7 from '../../../assets/img/exol/products/industrial/quenchants/7-exol-montage2-600x600.webp';
import img8 from '../../../assets/img/exol/products/industrial/quenchants/8-exol-montage2-600x600.webp';

const listProductsQuenchantsIn = [

  { option: "mapquenchantsin1", name: "namequenchantsin1", imgUrl: img1, description: "", url: "quenchantsin.url1" },
  { option: "mapquenchantsin2", name: "namequenchantsin2", imgUrl: img2, description: "", url: "quenchantsin.url2" },
  { option: "mapquenchantsin3", name: "namequenchantsin3", imgUrl: img3, description: "", url: "quenchantsin.url3" },
  { option: "mapquenchantsin4", name: "namequenchantsin4", imgUrl: img4, description: "", url: "quenchantsin.url4" },
  { option: "mapquenchantsin5", name: "namequenchantsin5", imgUrl: img5, description: "", url: "quenchantsin.url5" },
  { option: "mapquenchantsin6", name: "namequenchantsin6", imgUrl: img6, description: "", url: "quenchantsin.url6" },
  { option: "mapquenchantsin7", name: "namequenchantsin7", imgUrl: img7, description: "", url: "quenchantsin.url7" },
  { option: "mapquenchantsin8", name: "namequenchantsin8", imgUrl: img8, description: "", url: "quenchantsin.url8" },

];

export default listProductsQuenchantsIn;