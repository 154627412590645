const listProductsGearTransmissionOilsCo = [

    { option: "mapgeartransmissionoilsco1", name: "namegeartransmissionoilsco1", imgUrl: "https://dsm01pap002files.storage.live.com/y4mz29zkf6M4QXoDphW4bbncCvkUmpHuZUspdtotS5wWAZvjM2Oi0_aO5YGU7hbRzWMXi19J1foz6TZNvkB0t7kMjNEcilKF-KaS0xktr0xfRCOx2a-IuJRFGzV6IY2SvR31QYA4MxnW0P64IW8tYcwzXyvR0eZNhVHHZxLh3JWa91rIOGHsf2uv8x0_6DJ7JyG?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url1"},
    { option: "mapgeartransmissionoilsco2", name: "namegeartransmissionoilsco2", imgUrl: "https://dsm01pap002files.storage.live.com/y4m1ucw-20snWGfmrUTijKyZfAv2Z1ljZYVfB9YlaEP7qayBlKWUjPwTx3BrOC5c7Q52S461rS78tb8YS5GJGQ3KiUlyGfFNJsFuc2tqvJfEHeqF4_Ma-QyrC6Io_ZEOVFvWjBjwiTTLzUg-9mpa9HEppod4a_XHDaaaRjHB5ZkONOG23UbT5vQ2QUIkN7R8qxI?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url2" },
    { option: "mapgeartransmissionoilsco3", name: "namegeartransmissionoilsco3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mTtR60SIYeeHdsFRzvk_8J9DF-hTzJU4UnqltiqrwDAPi3bAQ-ssDGgQngQP8Xu9qnEcZsZQuxUY_egV76gStEmR_Bp2gyP4Z0Gsf8QbZU9Mjg7eJ3dciRqoyK-ra9UCJbZIEudz1V8jezs8dQ8ns5gu2jiSHdNVYiCZsN98toGSMLXq40hnwFMpq-E7rjC99?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url3" },
    { option: "mapgeartransmissionoilsco4", name: "namegeartransmissionoilsco4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mYasEVrHiQZKrzGBHtC9nvR-RJwk568OjlOV4KQ4VZtGX1Xzo4SdEyrnyvcVHHcO-ut0PwiiJdzzjN2MkRzHqdJRWFgfq0ZBI7vildRXZtQVrRaPo7Td1z0QR4ys0pcCrZFd_NB9y1npz4GVqxNootxcHmKnlHDqa7-PL7UtICqBYJZi_UNyjmQyc4tvH3AZz?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url4" },
    { option: "mapgeartransmissionoilsco5", name: "namegeartransmissionoilsco5", imgUrl: "https://dsm01pap002files.storage.live.com/y4mHzplEUlJsa5XetB7s9JUE2HxyGfqhGM5rr4nhhFhouFW0QWeG0zHy9meLqSiGXlsdjT0POi7Tl559-wOB8zCky6qvx34l-gV0zSTO_Ol-ejc1HZMcV7u1QEOZzSS2EX4CDVxpCFxk7Hgr1qdJMOvPes6ewmOyj1n_gFyc5dASmGWPyoBFsBT420wKns6dWrD?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url5" },
    { option: "mapgeartransmissionoilsco6", name: "namegeartransmissionoilsco6", imgUrl: "https://dsm01pap002files.storage.live.com/y4mTcfisZ6sCXAeUf7Esijxj4shJy8ojPjNbrZhvaBVZgVVGY8k2Ps5A4gf1M3D5MxL3z-steM-QEtMPOVXJRftz9p6S6-f40p-Us1c_YVJxWX3HgVQFowS1AERzNC36kmfEmuAQoDaknbYCq5c000n76kIn3Ks-_3XzJVaE2thAcyrTZKquDdn_CXngzeIMX1b?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url6" },
    { option: "mapgeartransmissionoilsco7", name: "namegeartransmissionoilsco7", imgUrl: "https://dsm01pap002files.storage.live.com/y4mP0y0fvMUvSn-JkAiyMK86nz3-8cOhfVGkRBuiodYOckjgyw3uoAWX2WwZ9_siA2eb6SlFeZlLPe3pPGBrogOggt0R1FDAAWP1HzzOhCNobrjHoES_rs3_OLkQhXt0fLqeyML5nlrkOvImBm0era-RDB3rAjIFb79-ao8DzQ0HhnfSO6hy8QGYhE8teXfyvTA?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url7" },
    { option: "mapgeartransmissionoilsco8", name: "namegeartransmissionoilsco8", imgUrl: "https://dsm01pap002files.storage.live.com/y4mximNtbQSlguF7Q6HtLnXM-jnireBs3iHTJDjxcJWynV54olclF7gZD2O1eJUV8imXbiMW8pm4iUZpwCKr_VE3XGlnqc7t6_rCUAJkAvKyOMxRv5dW2rMUiLr8SjCOJQjgEMK5sv6UtDU_IqcpshlOn5fX82Y--2404nCn8Fh9pAHvR-PUqJ4ZX8BDpBk4xZS?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url8" },
    { option: "mapgeartransmissionoilsco9", name: "namegeartransmissionoilsco9", imgUrl: "https://dsm01pap002files.storage.live.com/y4m8ck0zPlzjUfEx_77MDKqOEt_8ShhRrov2nqgBRGtE42o5y6EsxOxQJiZLhtawMqLnLRxVmjyEbvTV-YRTAEdlApGHzGvevbM_10M8IwARQuJK02rPxEEMIi8IFQkrIeN14R5A82CqStK9eMIup3LpRcvORPx0oX39gp_HQoLPTD50X17qZ0XSMrLhQInlXi2?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url9" },
    { option: "mapgeartransmissionoilsco10", name: "namegeartransmissionoilsco10", imgUrl: "https://dsm01pap002files.storage.live.com/y4mazDptyrnPA_EFXGv8ycuJU_B6MMDBzcCGHSpElKAj2BvgBwe20ewUyEZpj6MLwyz0bftQlanCXAcOLdangYEcGokKjKv-icboCDuqUmVE0MAmCVZN59KFMYGJgVO6j9sifYj7rpjCHc79K2jAH14kB4ytnkhOx-QSAi6mcSK4u7YHSa0aUcP4rDXMC5QN68S?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url10" },
    { option: "mapgeartransmissionoilsco11", name: "namegeartransmissionoilsco11", imgUrl: "https://dsm01pap002files.storage.live.com/y4m0JBtb1PDh8EHl2B-68J8xi8N4h0sXSRXu9aXrTRFSCsMH799MR8MMobgY4gAb8cxomlTruD1HW5lr8W362QWOD5amxKbM-5ViOH_F7C18XIYrT7Ym9RuGSu5KeGwUeUDccyCYLXZqzI58d0_3uJmN8hP-vNhIknElM_U-hX33D7LFrrk5N_Mghv4ck6UhK26?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url11" },
    { option: "mapgeartransmissionoilsco12", name: "namegeartransmissionoilsco12", imgUrl: "https://dsm01pap002files.storage.live.com/y4mYz-0jWSg_u_3s0dpHuqOq0x-OP62OL7PjarfhrkrTgnJ8HKcwItU6HG0gBu8cjQT2hhfbxs9hc4gwa3Pp7P3Obz7n6VvfHeqH1hRfLZokpGKYlEPDbmAROkEC_EQsgkdNvXZoHlMgzJ1g4GCWpZDCfr2EgeovAlrOZfeqLZ9ML1-MEaDQI7d3yjKmqfrHuXW?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url12" },
    { option: "mapgeartransmissionoilsco13", name: "namegeartransmissionoilsco13", imgUrl: "https://dsm01pap002files.storage.live.com/y4m4EZlq6Fjm4f8F8IBh4n0K8l2Ze-z7efYK08aOf9o0e8iJrFNCA7WI-WOu1LVRt8xzyVZvSuUa_meQ0441ktgDV7vlbngHyG_RCo2eaithyj1sf2w-pfTAx7NtUBc0npE7YmHAUwn8JLiFIEbHqVnmF1UBEXtgDfLDLtk79PJIVliEfh1-iOxleL6X6m0r7Sc?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url13" },
    { option: "mapgeartransmissionoilsco14", name: "namegeartransmissionoilsco14", imgUrl: "https://dsm01pap002files.storage.live.com/y4mr9S-M9nTmB3TWzzYneJYYna8_pb0FI4II-Ke9KGPGNBAFsRRUVs9n5iHQTRkckKstex4LL1rINsM6eX_pB6lEkxZytGdbZPlD0oSor2xmW17dIkynnUzUzqNg0n2Bud3fHRb8OJcXOhMSToElS-JLUTfpEeMSY-uHiVxAibglwYMHrgEd0VDUs1d4mSs3-Hj?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url14" },
    { option: "mapgeartransmissionoilsco15", name: "namegeartransmissionoilsco15", imgUrl: "https://dsm01pap002files.storage.live.com/y4mirPm7kAMyBPPonFkOxKcWPoW0_TsNINw7exf1Ke7kdNzZ2yOL6Iri9x20B52UM9_h4HSTlxUWGF9otY9r50f_MrU7SBgBs4XQLfkKJFom7Bd4bqMfjEy8GEe_78UugblQav3X-1OyDuq3RNk6W0bdSDUpU4h5aU_UzmROsPrQNch_KtFvXJbDHKCv57xhIVB?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url15" },
    { option: "mapgeartransmissionoilsco16", name: "namegeartransmissionoilsco16", imgUrl: "https://dsm01pap002files.storage.live.com/y4miTSIHKzDiRGKekZM6grGMgF8r9p0G7e-RnxdFXmK_cHaQPqfZ4MwyEli9I8KOaDilcLv0aOZsGYEl6G-JQF5Ehiy8O0LxvfUlceKwN4Scb7Qry9STR11c8ekRlTqI00tV6ZWkMz3sb7tW-xj4-wf4PZr07QTYHrAGyhMQRB0nHMUr0dl1SMbwV-SymypcNAm?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url16" },
    { option: "mapgeartransmissionoilsco17", name: "namegeartransmissionoilsco17", imgUrl: "https://dsm01pap002files.storage.live.com/y4mQTbJ7RPDPkKo-BYgNLhagvOfeddrSEpJB3Eq60KsD3zCGatly_2C-rhbiZTRu0SxibhiKzaB3MCBJ5CG3tQU-13RWxXpqIjR6i0Z_tGv1jOx8LiOmy3zBTy22frVGqBiR0_ABrmitjAKL2AUF5NAuJ3qfgjjpip2oMJOdkGM7IIXBroxQcR5O30VDqhAdbF6?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url17" },
    { option: "mapgeartransmissionoilsco18", name: "namegeartransmissionoilsco18", imgUrl: "https://dsm01pap002files.storage.live.com/y4mh706mNH58G2xTIsxWWuAJKWrkQD2SLXkAHZijKFJAv5iOPVhkT0ANn96LR5uqb4n_bWDIuJ4VAGvtDidIWr817NADMT1zmJlGVdP58EZryCDSjcw8HhVAjxS_eAA3_TdWDI53bKiojAnnsEzpq0utrPwPkk6lBqF6Lu6jP7Q_AedDFuRQCeivHD_eHtM7TpC?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url18" },
    { option: "mapgeartransmissionoilsco19", name: "namegeartransmissionoilsco19", imgUrl: "https://dsm01pap002files.storage.live.com/y4mtQw-YbVzyD0MeXIcdoQ9tz1i8I58Bu-Yz3S1mAYGkAKexeFvseBrWdjvEDR0Lxg2ZKx8xCqIWZPf7tpHltdj5cYCgOm_7r9QYTJ3AKo9bPte7C6DWy0ij49DA0uFzcfLHwagf3U76s4en0EWfPonOwNZ9pRfb1WqkDueMsxu6vlll5pDxglLvNUHrBnaUF_w?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url19" },
    { option: "mapgeartransmissionoilsco20", name: "namegeartransmissionoilsco20", imgUrl: "https://dsm01pap002files.storage.live.com/y4mRActnNx_7ogHm8kNrYevX2w-H-wxgf9QViIMtoboF_tNrTnN0TM3M_Q1Lt_jClseL78Vgk5IhiGL5vjamOgZ_292DD3I5yTof3exieYrTDCV6afGBika1XQAXlQmCwpmuShIRPKEn0R78t1uIVl6X48dc4Zyb-XrC4KxxaGcLffjlTIpuT0wGqb_zgpzALkK?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url20" },
    { option: "mapgeartransmissionoilsco21", name: "namegeartransmissionoilsco21", imgUrl: "https://dsm01pap002files.storage.live.com/y4m0VLiCbLtHwuYP2mCJilW7LWo3tiwesFF0cqm31z8PnXnEIHKcRfOyeCgtRIp3RAdkASGEll4Ozs7DwGz6EoyBTahn6ExbTSXMGMfOfk7Nuj8Mi82YHmRjI71xwuIV3pjDyK4OWMztDNkU_vTLdppJwzPJPg4eAQP-CNEJwGhECn2kf9ftyCHuTfpoajVbV-J?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url21" },
    { option: "mapgeartransmissionoilsco22", name: "namegeartransmissionoilsco22", imgUrl: "https://dsm01pap002files.storage.live.com/y4mBO-OVFG9_2i-nzCP3KlJilqXRvuHRVoBtvndGA_wfspiVw7190ig8-kjLc9ZSMuzS5lxLFh4DdEXVYzxvr8uwB7rdnILUqPJBvggEtmK6ke42g9Iah0eYJXc6FIDoxsPfFP1eMEnVWxyoh-TQi76z4Nzv2ySXoLS42IvnFUvf4uCxlGB8MZYaxVZSBKvKHa6?width=600&height=600&cropmode=none", description: "", url:"geartransmissionoilsco.url22" },
   
];
  
  export default listProductsGearTransmissionOilsCo;