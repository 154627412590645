import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import listCategoryCommercial from "./listCategoryCommercial";
import "../../assets/css/pages/Exol/Category/Commercial/commercial.css";

export const Commercial = () => {

  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "commercial.title",
    defaultMessage: "Exol - Commercial | OilFluid", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "commercial.description",
    defaultMessage:
      "Exol Commercial es la división especializada en lubricantes y productos relacionados para uso comercial e industrial. Ofrecemos una amplia gama de lubricantes de alta calidad diseñados para satisfacer las necesidades específicas de diversos sectores. Nuestros lubricantes garantizan un rendimiento óptimo, protección y eficiencia en equipos y maquinaria comercial e industrial. Confía en Exol Commercial para obtener soluciones confiables y de calidad que optimizarán el funcionamiento de tus equipos comerciales.", //Cambia Descripcion Pagina
  });

  return (
    <section className="commercial">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-commercial">
        <div className="head-commercial-img"></div>
        <div>
          <h1 className="head-commercial-text">
            <FormattedMessage
              id="commercial.h1"
              defaultMessage="LUBRICANTES COMERCIALES"
            />
          </h1>
        </div>
      </div>
      <Container>
        <Row className="product-commercial-row1">
          <p>
            <FormattedMessage
              id="commercial.row1-p1"
              defaultMessage="Los lubricantes para vehículos comerciales de Exol satisfacen las necesidades de los camiones y autobuses más modernos, así como de los modelos más antiguos. La amplia gama incluye aceites de motor de baja emisión de cenizas (low SAPS), totalmente sintéticos, semisintéticos de cambio prolongado y grados tradicionales de aceite mineral, así como fluidos sofisticados para transmisiones automáticas, aceites de engranajes manuales y productos complementarios."
            />
          </p>
        </Row>
        <Row className="product-commercial-row2">
          <h2>
            <FormattedMessage
              id="commercial.row2-h2"
              defaultMessage="Productos para Vehículos Comerciales"
            />
          </h2>
          <Col>
            <div className="container-listproductscommercial">
              {listCategoryCommercial.map((product, index) => (
                <a
                  key={index}
                  href={intl.formatMessage({
                    id: product.url,
                    defaultMessage: product.url,
                  })}
                  className="product-link-commercial"
                  // onClick={scrollToTop}
                >
                  <div className="map-listproduct-commercial">
                    <h2>
                      <FormattedMessage
                        id={product.name}
                        defaultMessage={product.name}
                      />
                    </h2>
                    <img
                      src={product.imgUrl}
                      alt="Lubricantes para vehículos comerciales"
                    />
                    <p>
                      <FormattedMessage
                        id={product.option}
                        defaultMessage={product.option}
                      />
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="product-commercial-row3">
          {/* <h2>
            <FormattedMessage
              id="commercial.row3-h2x1"
              defaultMessage="¿Por qué usar nuestros productos Exol para Automotriz?"
            />
          </h2>
          <ol>
            <li>
              <FormattedMessage
                id="commercial.ul-li1"
                defaultMessage="Protección del motor: Los lubricantes Exol Automotive están diseñados para brindar una protección superior al motor de tu vehículo. Contienen aditivos de alta calidad que ayudan a reducir la fricción, el desgaste y la corrosión, lo que prolonga la vida útil del motor y mejora su rendimiento."
              />
            </li>
            <li>
              <FormattedMessage
                id="commercial.ul-li2"
                defaultMessage="Mejor eficiencia del combustible: Los lubricantes Exol Automotive están formulados para ofrecer una lubricación eficiente y reducir la resistencia interna del motor. Esto puede resultar en una mejor eficiencia del combustible y, en última instancia, en un ahorro de costos a largo plazo."
              />
            </li>
            <li>
              <FormattedMessage
                id="commercial.ul-li3"
                defaultMessage="Mantenimiento adecuado: Utilizar los lubricantes adecuados es esencial para el mantenimiento adecuado de tu vehículo. Los lubricantes Exol Automotive están diseñados para cumplir con las especificaciones y recomendaciones de los fabricantes de automóviles, lo que garantiza un rendimiento óptimo y evita problemas de funcionamiento y desgaste prematuro."
              />
            </li>
            <li>
              <FormattedMessage
                id="commercial.ul-li4"
                defaultMessage="Amplia gama de productos: Exol Automotive ofrece una amplia gama de lubricantes para adaptarse a diferentes tipos de motores, transmisiones y sistemas. Ya sea que tengas un automóvil de pasajeros, un vehículo comercial o incluso una flota de vehículos, Exol tiene la solución adecuada para tus necesidades."
              />
            </li>
            <li>
              <FormattedMessage
                id="commercial.ul-li5"
                defaultMessage="Calidad y confiabilidad: Exol es reconocido en la industria por su calidad y confiabilidad. Sus lubricantes son fabricados utilizando los mejores ingredientes y tecnología de vanguardia, lo que garantiza un producto de alto rendimiento y durabilidad."
              />
            </li>
          </ol>
          <h2>
            <span>
              <FormattedMessage
                id="commercial.row3-h2x2"
                defaultMessage="Ver Otros Productos"
              />
            </span>
          </h2> */}
        </Row>
        {/* <Row className="product-commercial-row4">
          <h2>
            <FormattedMessage
              id="commercial.row4-h2"
              defaultMessage="Biobor JF® El único biocida disponible para uso en aviación."
            />
          </h2>
        </Row> */}
      </Container>
    </section>
  );
};
