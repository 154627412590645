import img1 from '../../../assets/img/exol/products/industrial/rust-preventatives/1-R001-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/rust-preventatives/2-R004-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/rust-preventatives/3-R007-600x600.webp';
import img4 from '../../../assets/img/exol/products/industrial/rust-preventatives/4-R010-600x600.webp';
import img5 from '../../../assets/img/exol/products/industrial/rust-preventatives/5-R017-600x600.webp';
import img6 from '../../../assets/img/exol/products/industrial/rust-preventatives/6-R036-600x600.webp';
import img7 from '../../../assets/img/exol/products/industrial/rust-preventatives/7-r002-600x600.webp';

const listProductsRustPreventativesIn = [

  { option: "maprustpreventativesin1", name: "namerustpreventativesin1", imgUrl: img1, description: "", url: "rustpreventativesin.url1" },
  { option: "maprustpreventativesin2", name: "namerustpreventativesin2", imgUrl: img2, description: "", url: "rustpreventativesin.url2" },
  { option: "maprustpreventativesin3", name: "namerustpreventativesin3", imgUrl: img3, description: "", url: "rustpreventativesin.url3" },
  { option: "maprustpreventativesin4", name: "namerustpreventativesin4", imgUrl: img4, description: "", url: "rustpreventativesin.url4" },
  { option: "maprustpreventativesin5", name: "namerustpreventativesin5", imgUrl: img5, description: "", url: "rustpreventativesin.url5" },
  { option: "maprustpreventativesin6", name: "namerustpreventativesin6", imgUrl: img6, description: "", url: "rustpreventativesin.url6" },
  { option: "maprustpreventativesin7", name: "namerustpreventativesin7", imgUrl: img7, description: "", url: "rustpreventativesin.url7" },

];
  
  export default listProductsRustPreventativesIn;