import img1 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/1-L001-600x600.webp'
import img2 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/2-L093-600x600.webp'
import img3 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/3-L002-600x600.webp'
import img4 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/4-L003-600x600.webp'
import img5 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/5-L004-600x600.webp'
import img6 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/6-L005-600x600.webp'
import img7 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/7-L006-600x600.webp'
import img8 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/8-L007-600x600.webp'
import img9 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/9-L008-600x600.webp'
import img10 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/10-L009-600x600.webp'
import img11 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/11-L010-600x600.webp'
import img12 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/12-L011-600x600.webp'
import img13 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/13-L041-600x600.webp'
import img14 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/14-L049-600x600.webp'
import img15 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/15-L037-600x600.webp'
import img16 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/16-L042-600x600.webp'
import img17 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/17-L039-600x600.webp'
import img18 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/18-L040-600x600.webp'
import img19 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/19-L053-600x600.webp'
import img20 from '../../../assets/img/exol/products/industrial/machine-slideway-oils/20-exol-montage1-600x600.webp'

const listProductsMachineSlidewayOilsIn = [

    { option: "mapmachineslidewayoilsin1", name: "namemachineslidewayoilsin1", imgUrl: img1, description: "", url:"machineslidewayoilsin.url1"},
    { option: "mapmachineslidewayoilsin2", name: "namemachineslidewayoilsin2", imgUrl: img2, description: "", url:"machineslidewayoilsin.url2" },
    { option: "mapmachineslidewayoilsin3", name: "namemachineslidewayoilsin3", imgUrl: img3, description: "", url:"machineslidewayoilsin.url3" },
    { option: "mapmachineslidewayoilsin4", name: "namemachineslidewayoilsin4", imgUrl: img4, description: "", url:"machineslidewayoilsin.url4" },
    { option: "mapmachineslidewayoilsin5", name: "namemachineslidewayoilsin5", imgUrl: img5, description: "", url:"machineslidewayoilsin.url5" },
    { option: "mapmachineslidewayoilsin6", name: "namemachineslidewayoilsin6", imgUrl: img6, description: "", url:"machineslidewayoilsin.url6" },
    { option: "mapmachineslidewayoilsin7", name: "namemachineslidewayoilsin7", imgUrl: img7, description: "", url:"machineslidewayoilsin.url7" },
    { option: "mapmachineslidewayoilsin8", name: "namemachineslidewayoilsin8", imgUrl: img8, description: "", url:"machineslidewayoilsin.url8" },
    { option: "mapmachineslidewayoilsin9", name: "namemachineslidewayoilsin9", imgUrl: img9, description: "", url:"machineslidewayoilsin.url9" },
    { option: "mapmachineslidewayoilsin10", name: "namemachineslidewayoilsin10", imgUrl: img10, description: "", url:"machineslidewayoilsin.url10" },
    { option: "mapmachineslidewayoilsin11", name: "namemachineslidewayoilsin11", imgUrl: img11, description: "", url:"machineslidewayoilsin.url11" },
    { option: "mapmachineslidewayoilsin12", name: "namemachineslidewayoilsin12", imgUrl: img12, description: "", url:"machineslidewayoilsin.url12" },
    { option: "mapmachineslidewayoilsin13", name: "namemachineslidewayoilsin13", imgUrl: img13, description: "", url:"machineslidewayoilsin.url13" },
    { option: "mapmachineslidewayoilsin14", name: "namemachineslidewayoilsin14", imgUrl: img14, description: "", url:"machineslidewayoilsin.url14" },
    { option: "mapmachineslidewayoilsin15", name: "namemachineslidewayoilsin15", imgUrl: img15, description: "", url:"machineslidewayoilsin.url15" },
    { option: "mapmachineslidewayoilsin16", name: "namemachineslidewayoilsin16", imgUrl: img16, description: "", url:"machineslidewayoilsin.url16" },
    { option: "mapmachineslidewayoilsin17", name: "namemachineslidewayoilsin17", imgUrl: img17, description: "", url:"machineslidewayoilsin.url17" },
    { option: "mapmachineslidewayoilsin18", name: "namemachineslidewayoilsin18", imgUrl: img18, description: "", url:"machineslidewayoilsin.url18" },
    { option: "mapmachineslidewayoilsin19", name: "namemachineslidewayoilsin19", imgUrl: img19, description: "", url:"machineslidewayoilsin.url19" },
    { option: "mapmachineslidewayoilsin20", name: "namemachineslidewayoilsin20", imgUrl: img20, description: "", url:"machineslidewayoilsin.url20" },
    
];
  
  export default listProductsMachineSlidewayOilsIn;