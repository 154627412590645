import img1 from '../../../assets/img/exol/products/industrial/new-generation-soluble-metalworking-fluids/1-s129-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/new-generation-soluble-metalworking-fluids/2-s132-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/new-generation-soluble-metalworking-fluids/3-s131-600x600.webp';
import img4 from '../../../assets/img/exol/products/industrial/new-generation-soluble-metalworking-fluids/4-s130-600x600.webp';
import img5 from '../../../assets/img/exol/products/industrial/new-generation-soluble-metalworking-fluids/5-S023-600x600.webp';
import img6 from '../../../assets/img/exol/products/industrial/new-generation-soluble-metalworking-fluids/6-S072-600x600.webp';
import img7 from '../../../assets/img/exol/products/industrial/new-generation-soluble-metalworking-fluids/7-S007-600x600.webp';
import img8 from '../../../assets/img/exol/products/industrial/new-generation-soluble-metalworking-fluids/8-S031-600x600.webp';
import img9 from '../../../assets/img/exol/products/industrial/new-generation-soluble-metalworking-fluids/9-s133-600x600.webp';


const listProductsNGSolubleMetalWorkingFluidsIn = [

  { option: "mapngsolublemetalworkingfluidsin1", name: "namengsolublemetalworkingfluidsin1", imgUrl: img1, description: "", url: "ngsolublemetalworkingfluidsin.url1" },
  { option: "mapngsolublemetalworkingfluidsin2", name: "namengsolublemetalworkingfluidsin2", imgUrl: img2, description: "", url: "ngsolublemetalworkingfluidsin.url2" },
  { option: "mapngsolublemetalworkingfluidsin3", name: "namengsolublemetalworkingfluidsin3", imgUrl: img3, description: "", url: "ngsolublemetalworkingfluidsin.url3" },
  { option: "mapngsolublemetalworkingfluidsin4", name: "namengsolublemetalworkingfluidsin4", imgUrl: img4, description: "", url: "ngsolublemetalworkingfluidsin.url4" },
  { option: "mapngsolublemetalworkingfluidsin5", name: "namengsolublemetalworkingfluidsin5", imgUrl: img5, description: "", url: "ngsolublemetalworkingfluidsin.url5" },
  { option: "mapngsolublemetalworkingfluidsin6", name: "namengsolublemetalworkingfluidsin6", imgUrl: img6, description: "", url: "ngsolublemetalworkingfluidsin.url6" },
  { option: "mapngsolublemetalworkingfluidsin7", name: "namengsolublemetalworkingfluidsin7", imgUrl: img7, description: "", url: "ngsolublemetalworkingfluidsin.url7" },
  { option: "mapngsolublemetalworkingfluidsin8", name: "namengsolublemetalworkingfluidsin8", imgUrl: img8, description: "", url: "ngsolublemetalworkingfluidsin.url8" },
  { option: "mapngsolublemetalworkingfluidsin9", name: "namengsolublemetalworkingfluidsin9", imgUrl: img9, description: "", url: "ngsolublemetalworkingfluidsin.url9" },

];

export default listProductsNGSolubleMetalWorkingFluidsIn;