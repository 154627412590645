import React, { useContext } from "react";
import "../../assets/css/pages/ItemsBiobor/itemsBiobor.css";
import { Container, Row, Col } from "react-bootstrap";
// import listProductsAviation from "./listProductsAviation";
import { Products } from "../../components/Products";
import { useAuth0 } from "@auth0/auth0-react";
import BioborDieselImg from "../../assets/img/diesel/bioborjf_diesel/bioborjf_diesel_industrial_biocide.webp";
/* Imagenes PDF */
import sdsEn from "../../assets/img/aviation/biobor-jf-aviation/bioborjf_SDS_safety_data_sheet_pdf.webp";
import specSheetEn from "../../assets/img/aviation/biobor-jf-aviation/bioborjf_specification_sheets_pdf.webp";
import sdsEs from "../../assets/img/aviation/biobor-jf-aviation/bioborjf_SDS_safety_data_sheet_pdf.webp"; /* Tiene Miniatura en Ingles */
import specSheetEs from "../../assets/img/aviation/biobor-jf-aviation/bioborjf_specification_sheets_pdf.webp"; /* Tiene Miniatura en Ingles */
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

export const BioborjfDiesel = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "bioborjfdiesel.title",
    defaultMessage:
      "BioborJF: Biocida para Diésel e Industria - Protección y Mantenimiento | OilFluid", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para description */

  const description = intl.formatMessage({
    id: "bioborjfdiesel.description",
    defaultMessage:
      "BioborJF Diesel and Industrial Biocide es la solución definitiva y el aditivo ideal para el tratamiento de combustibles. Mata eficazmente hongos y bacterias en todos los tipos de combustible, previniendo la obstrucción del filtro y la corrosión. Su fórmula avanzada dispersa y evita la formación de lodos, mientras añade lubricidad para una protección adicional. Este potente biocida y aditivo actúa tanto en la fase de agua como en la de combustible, garantizando una protección completa. Recomendado por los fabricantes de equipos, BioborJF es la elección confiable para mantener tus sistemas de combustible limpios y libres de contaminantes. ¡Compra nuestros productos ahora y protege tu equipo de forma efectiva!", //Cambia Descripcion Pagina
  });

  /* Link PDF */

  const sdsEnLink =
    "https://drive.google.com/file/d/1VOsDxicRFKoqO_WW2yARyfjikig72LhU/view";
  const sdsEsLink =
    "https://drive.google.com/file/d/1H_ArZU3DnxsK0AOGOLc7UMVprBs8754p/view";
  const specSheetEsLink =
    "https://drive.google.com/file/d/1-gZ56QbaoyjRCne0JSVD7wtAKSgi9Hhk/view";
  const specSheetEnLink =
    "https://drive.google.com/file/d/1vOLULRdF3wJyUJ55UMT5fcIroRuh3uXY/view";

  return (
    <section className="itemsBiobor" id="bioborjfdiesel">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-itemsBiobor">
        <div className="head-itemsBiobor-img"></div>
        <h1 className="head-itemsBiobor-text">
          <FormattedMessage
            id="bioborjfdiesel.h1"
            defaultMessage="Aditivo Biobor JF Diesel"
          />
        </h1>
      </div>
      <Container>
        <Row className="itemsBiobor-row1">
          <Col size={12} sm={8}>
            <h2>
              <FormattedMessage
                id="bioborjfdiesel.row1-col1-h2x1"
                defaultMessage="Biocida para combustible y aditivo de lubricidad"
              />
            </h2>
            <ul>
              <li>
                <FormattedMessage
                  id="bioborjfdiesel.row1-col1-ulli1"
                  defaultMessage="Mata hongos y bacterias en todos los tipos de combustible"
                />
              </li>
              <li>
                <FormattedMessage
                  id="bioborjfdiesel.row1-col1-ulli2"
                  defaultMessage="Ayuda a prevenir la obstrucción del filtro y la corrosión"
                />
              </li>
              <li>
                <FormattedMessage
                  id="bioborjfdiesel.row1-col1-ulli3"
                  defaultMessage="Dispersa y evita los lodos"
                />
              </li>
              <li>
                <FormattedMessage
                  id="bioborjfdiesel.row1-col1-ulli4"
                  defaultMessage="Añade lubricidad para mayor protección"
                />
              </li>
              <li>
                <FormattedMessage
                  id="bioborjfdiesel.row1-col1-ulli5"
                  defaultMessage="Mata tanto en fase de agua como de combustible"
                />
              </li>
              <li>
                <FormattedMessage
                  id="bioborjfdiesel.row1-col1-ulli6"
                  defaultMessage="Recomendado por los fabricantes de equipos"
                />
              </li>
              <li>
                <FormattedMessage
                  id="bioborjfdiesel.row1-col1-ulli7"
                  defaultMessage="Reduce el desgaste del inyector y del motor"
                />
              </li>
              <li>
                <FormattedMessage
                  id="bioborjfdiesel.row1-col1-ulli8"
                  defaultMessage="Aprobado para uso militar (MIL-S53021A)"
                />
              </li>
            </ul>
            <h2>
              <FormattedMessage
                id="bioborjfdiesel.row1-col1-h2x2"
                defaultMessage="Descripción del Producto"
              />
            </h2>
            <p>
              <FormattedMessage
                id="bioborjfdiesel.row1-col1-p1"
                defaultMessage="Biobor JF es el biocida de combustible y aditivo de lubricidad más ampliamente utilizado y altamente recomendado. Avalado por reconocidos fabricantes de motores, Biobor JF es la solución confiable para eliminar y prevenir el crecimiento microbiano en el combustible, al mismo tiempo que prolonga significativamente la vida útil de los filtros del motor. Además, Biobor JF restaura la lubricidad del combustible diésel ultra bajo en azufre (ULSD), brindando una protección adicional a los inyectores y las bombas de combustible, al tiempo que previene la corrosión. Confíe en Biobor JF para asegurar la longevidad de su motor y la calidad de su combustible. Desde 1965, Biobor JF ha sido el estándar mundial en su categoría."
              />
            </p>
          </Col>
          <Col size={12} sm={4}>
            <img
              src={BioborDieselImg}
              alt="Biobor JF: un biocida y aditivo lubricante de categoría mundial. Mejora la calidad y el rendimiento de los combustibles al establecer un estándar global. Descubre cómo Biobor JF está revolucionando la industria y maximizando el rendimiento de los combustibles"
            />
          </Col>
        </Row>
        <Row className="itemsBiobor-row2">
          <h2>
            <FormattedMessage
              id="bioborjfdiesel.row2-h2"
              defaultMessage="Recursos de Biobor JF (PDF)"
            />
          </h2>
          <br />
          <div className="notice-to-register">
            {!isAuthenticated ? (
              <div className="notice1-to-register">
                <span>
                  ( <i className="fa-solid fa-lock fa-lock2"></i>
                </span>{" "}
                <span>
                  <FormattedMessage
                    id="notice.to-register1"
                    defaultMessage="Registrarte"
                  />{" "}
                  <button
                    onClick={loginWithRedirect}
                    className="noticetoregister-aqui"
                  >
                    <FormattedMessage
                      id="notice.to-register2"
                      defaultMessage="AQUI"
                    />
                  </button>{" "}
                  <FormattedMessage
                    id="notice.to-register3"
                    defaultMessage="para descargar cualquier informacion )"
                  />{" "}
                  )
                </span>
              </div>
            ) : (
              <div className="notice2-to-register">
                <span>
                  ( <i className="fa-solid fa-lock-open"></i>
                </span>
                <span>
                   {" "}
                  <FormattedMessage
                    id="notice.to-register4"
                    defaultMessage="Descarga cualquier informacion haciendo clic en la imagen )"
                  />{" "}
                  )
                </span>
              </div>
            )}
          </div>
          <br />
          <br />
        </Row>
        <div className="itemsBiobor-row3">
          <div className="container-lock-on-off">
            <h4>
              SDS (EN) <br />
              (Safety Data Sheet)
            </h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={sdsEn}
                  alt="Biobor JF SDS - Safety Data Sheet PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={sdsEnLink} target="alt_">
                  <img
                    src={sdsEn}
                    alt="Biobor JF SDS - Safety Data Sheet PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                  <p>Click para Descargar</p>
                </div> */}
              </div>
            )}
          </div>
          <div className="container-lock-on-off">
            <h4>Specification Sheets (EN)</h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={specSheetEn}
                  alt="Biobor JF - Specification Sheets PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={specSheetEnLink} target="alt_">
                  <img
                    src={specSheetEn}
                    alt="Biobor JF - Specification Sheets PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                  <p>Click para Descargar</p>
                </div> */}
              </div>
            )}
          </div>
          <div className="container-lock-on-off">
            <h4>
              SDS (ES) <br />
              (Hoja de Datos de Seguridad)
            </h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={sdsEs}
                  alt="Biobor JF SDS - Hija de Datos de Seguridad PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={sdsEsLink} target="alt_">
                  <img
                    src={sdsEs}
                    alt="Biobor JF SDS - Hija de Datos de Seguridad PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                  <p>Click para Descargar</p>
                </div> */}
              </div>
            )}
          </div>
          <div className="container-lock-on-off">
            <h4>Hoja de especificaciones (ES)</h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={specSheetEs}
                  alt="Biobor JF - Hoja de Especificaciones PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={specSheetEsLink} target="alt_">
                  <img
                    src={specSheetEs}
                    alt="Biobor JF - Hoja de Especificaciones PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                  <p>Click para Descargar</p>
                </div> */}
              </div>
            )}
          </div>
        </div>
        {/* {!isAuthenticated ? (
              <Products /> ) : (
                      <Info1 />
                    )} */}

        {/* <Row className="biobor-jf-aviation-row4">
            <h2>¿Por qué usar Biobor JF Aviation?</h2>
            <p>
              Biobor JF ha sido el estándar mundial para el tratamiento del
              crecimiento microbiano en el combustible para aviones de aviación
              desde 1965. Originalmente desarrollado para su uso en las industrias
              de aviación comercial y militar, Biobor JF ha seguido brindando un
              historial de confiabilidad y eficacia comprobadas en numerosas
              industrias para más de 50 años. Para agregar a este impresionante
              pedigrí, Biobor JF es el único biocida de combustible de aviación
              recomendado por su nombre por numerosos fabricantes importantes de
              motores de turbina y fuselajes en todo el mundo.
            </p>
            <h4>El ÚNICO biocida de combustible disponible para la aviación:</h4>
            <p>
              Solo ha habido dos biocidas aprobados en la historia de los
              combustibles de aviación. Ahora, solo Biobor JF® está disponible.
              Después de varios incidentes de aeronavegabilidad causados por el
              otro biocida previamente aprobado que se retiró permanentemente del
              mercado, Biobor JF® es el único biocida de combustible disponible en
              todo el mundo.
            </p>
            <h4>La elección obvia para eliminar el crecimiento microbiano:</h4>
            <p>
              Cuando se discuten problemas relacionados con el combustible de
              aviación, se sabe ampliamente que el crecimiento microbiano es la
              causa más común de fallas en los motores relacionadas con el
              combustible. Intrínsecamente, el combustible de aviación y los
              tanques de combustible tienen la tendencia a acumular agua, ya sea
              por condensación del tanque, agua arrastrada por el suministro de
              combustible o simplemente por recibir un lote de combustible
              defectuoso. Los hum-bugs viven en la fase de agua de un tanque de
              combustible y se alimentan del combustible desarrollando esteras
              gelatinosas oscuras y viscosas. A medida que crecen estas colonias
              de bacterias y hongos (potencialmente a una tasa de duplicación cada
              20 minutos), pueden obstruir rápidamente los filtros de combustible
              y provocar acumulaciones excesivas de crecimiento. Además, sus
              productos de desecho son una de las principales causas de corrosión
              en tanques de combustible y componentes metálicos. Con el uso
              continuado de Biobor JF, se eliminará el crecimiento microbiano del
              sistema de combustible, lo que dará lugar a un sistema de
              combustible estéril, lo que extenderá la vida útil de los filtros de
              combustible y evitará problemas de corrosión.
            </p>
          </Row> */}
        <Products />
      </Container>
    </section>
  );
};
