import img1 from '../../../assets/img/exol/products/industrial/turbine-oils/1-C011-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/turbine-oils/2-C018-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/turbine-oils/3-C012-600x600.webp';
import img4 from '../../../assets/img/exol/products/industrial/turbine-oils/4-C015-600x600.webp';
import img5 from '../../../assets/img/exol/products/industrial/turbine-oils/5-C013-600x600.webp';
import img6 from '../../../assets/img/exol/products/industrial/turbine-oils/6-C017-600x600.webp';


const listProductsTurbineOilsIn = [

  { option: "mapturbineoilsin1", name: "nameturbineoilsin1", imgUrl: img1, description: "", url: "turbineoilsin.url1" },
  { option: "mapturbineoilsin2", name: "nameturbineoilsin2", imgUrl: img2, description: "", url: "turbineoilsin.url2" },
  { option: "mapturbineoilsin3", name: "nameturbineoilsin3", imgUrl: img3, description: "", url: "turbineoilsin.url3" },
  { option: "mapturbineoilsin4", name: "nameturbineoilsin4", imgUrl: img4, description: "", url: "turbineoilsin.url4" },
  { option: "mapturbineoilsin5", name: "nameturbineoilsin5", imgUrl: img5, description: "", url: "turbineoilsin.url5" },
  { option: "mapturbineoilsin6", name: "nameturbineoilsin6", imgUrl: img6, description: "", url: "turbineoilsin.url6" },

];

  
  export default listProductsTurbineOilsIn;