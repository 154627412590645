const listProductsGreasesCo = [

    { option: "mapgreasesco1", name: "namegreasesco1", imgUrl: "https://dsm01pap002files.storage.live.com/y4mY7BlwcT97oxL4DEwP3R8uXjulmRJZMIcYCQgSPIaGgecdc94vMriCVDgOgXufe9ArFHhUAu0axpP2OrG7BNKGj-LmNTA7Aoz1Rxf-Kmz1KYTsvRxGM8X5ehXXm4YLIvUTrZyd4Naem6V03Q7kqI_rPvhDw0pNBGWP60qt1pvCq8IwmdalqWqsH012JRlJCyP?width=600&height=600&cropmode=none", description: "", url:"greasesco.url1"},
    { option: "mapgreasesco2", name: "namegreasesco2", imgUrl: "https://dsm01pap002files.storage.live.com/y4mzw7bw_FC3Uqe_LUXAmMOEwFpf_LmSNA8CyW5S3bCxLMcAgy3wQijjZVpuuHf5aibrYY-Plwp7aK6g0riPOx1vrDy8kcvZGb74l0jFUGmlU2MdT9Wp53lwWxpoCxiOC-Mr8xoQ21aqKoyIFJ4pVwHIxUmdRFqsHLwBygO1lzg01Ae51UBi7hE6p3mQqSi-mG8?width=600&height=600&cropmode=none", description: "", url:"greasesco.url2" },
    { option: "mapgreasesco3", name: "namegreasesco3", imgUrl: "https://dsm01pap002files.storage.live.com/y4m-t1sFmSVv8UEmE6EDLwWN2hORJDxJkYGd2s1kcsg61TEfd_9UMJnIbbCnCUVo45qog7suN3uqV2wmjuFRtIp5a0N9M5th0sUQ4vMXufmgg-S6tsH8ikFcsy5fhyynWf19w1mjB6aJuCK59L6TlD5c6ny9GTk9A85r-d-VY1mDuLl0m6-TjExjw71Ipr4BQr5?width=600&height=600&cropmode=none", description: "", url:"greasesco.url3" },
    { option: "mapgreasesco4", name: "namegreasesco4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mTbwe-BC5PLKAgHtvtAoMavgscvZg3AgZEJ-uDH3QC1SEpZ08W267OvSa8c6tdYUhZZ2iK6kF24XfA9LUCbs_N72Q6WVsCaM9UR9Bzr7e9zTJZC8njBJdFtTiAI4EwHzHBRzYJtpmUjfTsmEcXJManqMj5UHr13l7_AjVzLsfmB-EeBT0jP7hvZjE9wlwAfwn?width=600&height=600&cropmode=none", description: "", url:"greasesco.url4" },
    { option: "mapgreasesco5", name: "namegreasesco5", imgUrl: "https://dsm01pap002files.storage.live.com/y4mi05FuPfViutDFVpQqZeV_gRMWs8ynbeq5FMTNyiQ61dbctz8ltFsK70OgYau2s76rwZ_L1CdUBcehKKB-S595b5v9yo94pBgYmgxUv3J3NVVvrJXVW3n7pBDQaVEnWJZMLjwyaI0kOTRMEzoR-6pO943y4mkf8Vd9mGnnNEDYql-t-j5iAbkxzPv3Jd_7v8w?width=600&height=600&cropmode=none", description: "", url:"greasesco.url5" },
    { option: "mapgreasesco6", name: "namegreasesco6", imgUrl: "https://dsm01pap002files.storage.live.com/y4mQ001nKe8lDWFSs8DMnkXKdPeGRSoV-ooERoomkyZhKEoYRbB9pefcjK69Evkj9CFNn-N9d8uvqa87AqCDcg8MxnHpEr7MnARmABXhd7VNNrX9FSYFCBK0_2lVIEm6zwpraccX_qVj5KHejG4XkI_l-3Viocm19SuTLRimH5RgBv9GYIsI7j0IaKzqAQvWqZJ?width=600&height=600&cropmode=none", description: "", url:"greasesco.url6" },
    { option: "mapgreasesco7", name: "namegreasesco7", imgUrl: "https://dsm01pap002files.storage.live.com/y4m_zO96VgHdPs4Okecu6lGZBIHn7nfCNL2ZAgeFdoDvRWTH1get9PUj5Sz6_TKR087hJu3zlfPtJVx8D4OQOWfDjzklp5xEoxm8pbAf-ElOpAkupVv4OASMQBziGwtO7xRebWh6FJjmt7PqDodvF-Z5x9D3DnYGJigvrrsnnSB-27ytgyhqgJoBWDs_Fna5nWE?width=600&height=600&cropmode=none", description: "", url:"greasesco.url7" },
    { option: "mapgreasesco8", name: "namegreasesco8", imgUrl: "https://dsm01pap002files.storage.live.com/y4m21LZDw6WlxuMb_G5_AMXynPn6xNNtiVtnPThYlpFSoqnS6_9UjUAjFRDJpu7e5RbRYkt7Sr-bJH4BdecyjWgdNN0CFr4I1GGsyVxj2rVarosHJxtH2F2UBuouG6SCTboNgCj2rxrNcwXwRXpvHuK59vRdje3ykapeNeNGJ43mn-u3wbnrTftiK1heSSQMv4j?width=600&height=600&cropmode=none", description: "", url:"greasesco.url8" },
    { option: "mapgreasesco9", name: "namegreasesco9", imgUrl: "https://dsm01pap002files.storage.live.com/y4mHi01X9rN-jnvEeMHSqM2LEYfMO1Hws6gPoYMN6wdidKJLaUPcQXutrKl_C7DnPXkpNdwxYq5rNDuSGDzANJ2E3odHSCuYwYy0d1otWUz8LU_YO56zaV1KkWNWmqixEnrlpH4_q9Q3kSeY4UCZorv7DkFGvqE3AQGS1X43xYZNH5lBkkGBuZ6rHS4-1MTAh8f?width=600&height=600&cropmode=none", description: "", url:"greasesco.url9" },
    { option: "mapgreasesco10", name: "namegreasesco10", imgUrl: "https://dsm01pap002files.storage.live.com/y4mHm4sNFqeUEaKkVaJcmGgNmaVDCNi7Xc36wEehEqUv51M4HzsVezIIf6C4gEGX1-XkynNirRzecpBQszJBrelpufm11ulUrnGwHFvIe9KAll23vKL-mwwlTDCQe6uWykfCdLU3z_bDqtkj6DzUVwWvFSlL2s8CuMLeQ1ihR3zAzLbMq89V-a3GnjdYiAkgRhj?width=600&height=600&cropmode=none", description: "", url:"greasesco.url10" },
    { option: "mapgreasesco11", name: "namegreasesco11", imgUrl: "https://dsm01pap002files.storage.live.com/y4mkIEpK3uuUzKU2k-SzEgxrjccQzm6ViF8ivruOnsBVg3KqP56N1dDH_Nyqn90SbF0vI1ij0m1bSRYnak3_Fl4RFuQOhrNhm0H7kxb4I96E01dTNLmKHClVbWMLY18tjke68ytQtq_LeamLinPV2ecgYRzafDR60IVlTBG6RDZaRB3172eHp9Waoo9CVQSnwfK?width=600&height=600&cropmode=none", description: "", url:"greasesco.url11" },
    { option: "mapgreasesco12", name: "namegreasesco12", imgUrl: "https://dsm01pap002files.storage.live.com/y4m6GBv2SXMxImRt5BhFVqGlr6SL5cOyDX1GLz9qFotiCOO99OrVAh86YguQTBLLUYwmEfbU8Kqe29ERl2PopzRu92CH46mKxi136eILK2E_2XgsPqNSJzkr-o-V_O5HiJuoua0zMP6Y7LWQ-D4jJ6RWxO_1R-jppQ0CJsaECSsR13eMa1xH-Ai14a4jYCy26kC?width=600&height=600&cropmode=none", description: "", url:"greasesco.url12" },
    { option: "mapgreasesco13", name: "namegreasesco13", imgUrl: "https://dsm01pap002files.storage.live.com/y4mR21Q2KotXKeQ0qhAU0qDYEABIaok6JTTXzLqyO5xmXXtc6oa-vJnor6m1TefEvj5lUSpdC3Rhme2q4JEnC0skLp_SmGr80k2eo0t2yks1EMVYJbD0Us1jTba9Gh9AmN-5fodQvMBqR3B4AlX9nHGyMFO17zr3XfKkPq2zRAP0LqgJgUHe-csN5-Vxz07_NX0?width=600&height=600&cropmode=none", description: "", url:"greasesco.url13" },
    { option: "mapgreasesco14", name: "namegreasesco14", imgUrl: "https://dsm01pap002files.storage.live.com/y4mEySkD4Tm-zGzuCQaLK9_6oxVTiqJe_4GhNRHk4z6uk5bSPWgsgav0al4heQZTj4Ker88x5I075KvagCUnCcMgJeblQhaCDF9a3osumWBcg7BFxWzkJb1K_d5zPnEoR-IvtXM71xDf3RiQn5bMxBXjk7m94LQHMZR70CB6h2NbphNDlZyDtmo2Bt6wmNcGZEa?width=600&height=600&cropmode=none", description: "", url:"greasesco.url14" },
    { option: "mapgreasesco15", name: "namegreasesco15", imgUrl: "https://dsm01pap002files.storage.live.com/y4m-diw0EcqQQgt1tF2phRIjdyl2fHkSFeGf1VTDnY3OxMRzNgD23j0VjqwpIlaWa87ZTAa1X1r3G_6da9943_buVzNr4xYvpmuhN6dIRAaJQF02yAXy00ZDuWSCBCASYeY7hWpWxXqb1LMClX6L8mTyXcDEvtDqgnJPCA09RfiImigcJlC6-UIc_eQhaM5blAx?width=600&height=600&cropmode=none", description: "", url:"greasesco.url15" },
    { option: "mapgreasesco16", name: "namegreasesco16", imgUrl: "https://dsm01pap002files.storage.live.com/y4m_VTNIGUyZO0dF07PnhhVQkEzmYy7_NOR_kciKw0ZIf6DQaZn9X97Fbk3E7HHDtj8VlawPRwMf6wcUqemFvscMXNLI64AeadKEDp-JDS0mgqEtYl39iMxFdgsDIN_L8OI_nHwplsDcLwvXZebV53o6huBhn-tLGgyo5U8B8liLZsWTb4xNMAREBSOoMTosgzD?width=600&height=600&cropmode=none", description: "", url:"greasesco.url16" },
    
];
  
  export default listProductsGreasesCo;