import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import emailjs from "@emailjs/browser";
// import "animate.css";

export const Contact = () => {
  
  /* Imagen de Contact */

  const supportContactImg = "https://dsm01pap002files.storage.live.com/y4mluIPQoQLJRFaRK24l7HTcOpmvz7RuwIeBvk4bDC_Ucc98D5V4qC0bQROO_g0xkvXRIY6eiXqvGwAImN5YFRS52Q9BSIA0_IMc5_RjUj4YieadBRSXSgbCpDvojUpmdVdZv8R5vHil9zCB3Cvyja4d2hVg2pTfL3EBxqKrKEMTaVQHu-GDIP090vEESd4Uz85?width=500&height=599&cropmode=none";
 
  /* Direcciones Maps */

  // const mapPE = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5517.287968612267!2d-77.03605651956178!3d-12.090798357426333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c9e55d0307ed%3A0x33005c0c275a5c59!2sTECHNO%20STANDARD%20SAC!5e0!3m2!1ses!2spe!4v1681949547553!5m2!1ses!2spe";

  
  /* EmailJS */

  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID;

  const formInitialDetails = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    country: "",
    message: "",
  };
  
  /* Inicio Traduccion Boton Enviar */

  const intl = useIntl();
  
  const contactBtnSend = intl.formatMessage({
    id: "contact.btn-send",
    defaultMessage: "Enviar",
  });

  const [buttonText, setButtonText] = useState(contactBtnSend);

  useEffect(() => {
    setButtonText(contactBtnSend);
  }, [contactBtnSend]);

  /* Fin Traduccion Boton Enviar */

  const [formDetails, setFormDetails] = useState(formInitialDetails);

  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText(
      <FormattedMessage
        id="contact.sending-message"
        defaultMessage="Enviando.........."
      />
    );

    try {
      await emailjs.sendForm(serviceId, templateId, e.target, userId);
      setFormDetails(formInitialDetails);
      setStatus({
        success: true,
        message: (
          <FormattedMessage
            id="contact.message-sent"
            defaultMessage="Mensaje Enviado Correctamente"
          />
        ),
      });
    } catch (error) {
      console.error("Error sending email:", error);
      setStatus({
        success: false,
        message: "Something went wrong, please try again later.",
      });
    }

    setButtonText(contactBtnSend);
  };

  /* Traduccion placeHolder Contacto */

  const placeHolderFirstName = intl.formatMessage({
    id: "contact.placeHolder-firstName",
    defaultMessage: "Nombre",
  });

  const placeHolderLastName = intl.formatMessage({
    id: "contact.placeHolder-lastName",
    defaultMessage: "Apellido",
  });

  const placeHolderEmail = intl.formatMessage({
    id: "contact.placeHolder-email",
    defaultMessage: "Correo",
  });

  const placeHolderCellphone = intl.formatMessage({
    id: "contact.placeHolder-cellphone",
    defaultMessage: "Celular",
  });

  const placeHolderCompany = intl.formatMessage({
    id: "contact.placeHolder-company",
    defaultMessage: "Compañia",
  });

  const placeHolderCountry = intl.formatMessage({
    id: "contact.placeHolder-country",
    defaultMessage: "País",
  });

  const placeHolderMessage = intl.formatMessage({
    id: "contact.placeHolder-message",
    defaultMessage: "Mensaje",
  });

  return (
    <section className="contact" id="contact">
      <Container>
        <h2>
          <FormattedMessage id="contact.h2" defaultMessage="¿Dudas o ayuda con aditivos? Llámanos o escríbenos, estaremos encantados de ayudarte." />
        </h2>
        <Row className="align-items-center">
          <Col size={12} md={6}>
            {/* <iframe
              src={mapPE}
              width="100%"
              height="550px"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="google-map-peru"
            ></iframe> */}
            <img src={supportContactImg} alt="Soporte de Aditivos de Combustibles Oilfluid" className="support_oilfluid_man"/>
          </Col>
          <Col size={12} md={6}>
            {/* <TrackVisibility> */}
            <div className="animate__animated animate__fadeIn">
              {/* <h3 className="send-msj">
                <FormattedMessage
                  id="contact.row1-col2-h3"
                  defaultMessage="Envíanos un mensaje"
                />
              </h3> */}
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      type="text"
                      name="firstName"
                      value={formDetails.firstName}
                      placeholder={placeHolderFirstName}
                      onChange={(e) =>
                        onFormUpdate("firstName", e.target.value)
                      }
                      required
                    />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      type="text"
                      name="lastName"
                      value={formDetails.lastName}
                      placeholder={placeHolderLastName}
                      onChange={(e) => onFormUpdate("lastName", e.target.value)}
                      required
                    />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      type="email"
                      name="email"
                      value={formDetails.email}
                      placeholder={placeHolderEmail}
                      onChange={(e) => onFormUpdate("email", e.target.value)}
                      required
                    />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      type="tel"
                      name="phone"
                      value={formDetails.phone}
                      placeholder={placeHolderCellphone}
                      onChange={(e) => onFormUpdate("phone", e.target.value)}
                      required
                    />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      type="text"
                      name="company"
                      value={formDetails.company}
                      placeholder={placeHolderCompany}
                      onChange={(e) => onFormUpdate("company", e.target.value)}
                      required
                    />
                  </Col>
                  <Col size={12} sm={6} className="px-1">
                    <input
                      type="text"
                      name="country"
                      value={formDetails.country}
                      placeholder={placeHolderCountry}
                      onChange={(e) => onFormUpdate("country", e.target.value)}
                      required
                    />
                  </Col>
                  <Col size={12} className="px-1">
                    <textarea
                      rows="6"
                      name="message"
                      value={formDetails.message}
                      placeholder={placeHolderMessage}
                      onChange={(e) => onFormUpdate("message", e.target.value)}
                      required
                    ></textarea>
                    <button type="submit">
                      <span>{buttonText}</span>
                    </button>
                  </Col>
                  {status.message && (
                    <Col>
                      <p
                        className={
                          status.success === false ? "danger" : "success"
                        }
                      >
                        {status.message}
                      </p>
                    </Col>
                  )}
                </Row>
              </form>
            </div>
            {/* </TrackVisibility> */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
