import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "../assets/css/banner2.css";
import { FormattedMessage } from "react-intl";

export const Banner2 = () => {
  return (
    <div className="carousel-container-banner2">
      <Carousel>
        <Carousel.Item className="carousel-item-banner2">
          <div className="carousel-caption-banner2">
            <h2>Biobor JF Aviation</h2>
            <h3>
              <FormattedMessage
                id="banner2.astm"
                defaultMessage="Cumple con la especificación ASTM D7719"
              />
            </h3>
            <div></div>
            {/* <p></p> */}
          </div>
          <div className="img-caption-banner2">
            {/* <div>
              <img
                src="https://www.biobor.com/wp-content/uploads/2021/01/BioborJF-32oz-Aviation-Bottle-768x1355.png"
                alt="First slide"
                className="img1-caption-banner2"
              />
            </div> */}
            <div>
              <img
                src="https://i.imgur.com/JMM3ew8.png"
                alt="Second slide"
                className="img2-caption-banner2-2"
              />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className="carousel-item-banner2">
          <div className="carousel-caption-banner2">
            <h2>Biobor JF Aviation</h2>
            <h3>
              <FormattedMessage
                id="banner2.faa"
                defaultMessage="Cuenta con la aprobación de la Administración Federal de Aviación (FAA)"
              />
            </h3>
            <div></div>
            {/* <p></p> */}
          </div>
          <div className="img-caption-banner2">
            {/* <div>
              <img
                src="https://www.biobor.com/wp-content/uploads/2021/01/BioborJF-32oz-Aviation-Bottle-768x1355.png"
                alt="First slide"
                className="img1-caption-banner2"
              />
            </div> */}
            <div>
              <img
                src="https://i.imgur.com/SXnFkpM.png"
                alt="Second slide"
                className="img2-caption-banner2"
              />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className="carousel-item-banner2">
          <div className="carousel-caption-banner2">
            <h2>Biobor JF Aviation</h2>
            <h3>
            <FormattedMessage
                id="banner2.easa"
                defaultMessage="Cuenta con la aprobación de la Agencia Europea de Seguridad Aérea (EASA)"
              />
            </h3>
            <div></div>
            {/* <p></p> */}
          </div>
          <div className="img-caption-banner2">
            {/* <div>
              <img
                src="https://www.biobor.com/wp-content/uploads/2021/01/BioborJF-32oz-Aviation-Bottle-768x1355.png"
                alt="First slide"
                className="img1-caption-banner2"
              />
            </div> */}
            <div>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/a/a2/EASA_Logo.png"
                alt="Second slide"
                className="img2-caption-banner2-3"
              />
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};
