import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import listProductsFullySemiSyntheticEngineOilsCo from "../ProductsExolCommercial/listProductsFullySemiSyntheticEngineOilsCo";
import "../../../assets/css/pages/Exol/Category/Commercial/categoryCommercial.css";
import { AskInformationProducts } from "../../../components/Modal/AskInformationProducts";

export const FullySemiSyntheticEngineOilsCo = () => {
  
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  // Inicio Modal Solicitud de Informacion Productos

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Fin Modal Solicitud de Informacion Productos

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "fullysemisyntheticengineoilsco.title",
    defaultMessage:
      "Exol - Aceites de motor semisintéticos y totalmente sintéticos | Comercial", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "fullysemisyntheticengineoilsco.description",
    defaultMessage:
      "Exol: Los aceites totalmente sintéticos brindan una mayor resistencia a la oxidación y una estabilidad excepcional a altas temperaturas, mientras que los aceites semisintéticos ofrecen una excelente lubricación y protección.", //Cambia Descripcion Pagina
  });

  return (
    <section className="categorycommercial">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-categorycommercial">
        <div className="head-categorycommercial-img"></div>
        <div></div>
      </div>
      <Container>
        <Row className="product-categorycommercial-row1"></Row>
        <Row className="product-categorycommercial-row2">
          <h1>
            <FormattedMessage
              id="fullysemisyntheticengineoilsco.row2-h1"
              defaultMessage="Productos | Aceites de motor semisintéticos y totalmente sintéticos"
            />
          </h1>
          <Col>
            <div className="container-listproductscommercial">
              {listProductsFullySemiSyntheticEngineOilsCo.map(
                (product, index) => (
                  <div
                    key={index}
                    className="product-link-categorycommercial"
                    onClick={handleOpenModal}
                    // onClick={scrollToTop}
                  >
                    <div className="map-listproductcommercial">
                      <h2>
                        <FormattedMessage
                          id={product.name}
                          defaultMessage={product.name}
                        />
                      </h2>
                      <img
                        src={product.imgUrl}
                        alt="Lubricantes Comerciales - Aceites de motor semisintéticos y totalmente sintéticos"
                      />
                      <p>
                        <FormattedMessage
                          id={product.option}
                          defaultMessage={product.option}
                        />
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
            <AskInformationProducts
              showModal={showModal}
              onHide={handleCloseModal}
            />{" "}
            {/* Modal Escondido */}
          </Col>
        </Row>
        <Row className="product-categorycommercial-row3"></Row>
      </Container>
    </section>
  );
};
