import React from "react";
import "../assets/css/info2.css";
import { Col, Container, Row } from "react-bootstrap";
import test from "../assets/img/test.png";
import treat from "../assets/img/treat.png";
import protect from "../assets/img/protect.png";
import testTreatProtect from "../assets/img/test-treat-protect.png";
import marineDiesel from "../assets/img/front/marineDiesel.webp";
import ethanolBuster from "../assets/img/front/ethanolBuster.webp";
import { FormattedMessage } from "react-intl";
import comparativeTable from '../assets/img/front/biobor_comparison_products.webp'

export const Info2 = () => {
  /* Imagenes en Variables */

  const anniversarylogo =
    "https://dsm01pap002files.storage.live.com/y4mU3ZzWP1vDF51ZMKxXZtCFgxxb8ZiFosQAlddmHsRva7zH30JJ3K_njQqyFFc7iIL0rAP0WnQm42Uy5cv1dvbTF0OYtuBpOgCG0PeMb7uPZdCHUCCsx1IKHGw7pY3IeIvMazvH0gLpyeYyersw1YD0dWoUjE0X4mF3GNeO9fxoNlPukMmPjI-IsGRBK-xrYBV?width=700&height=560&cropmode=none";
  
  return (
    <section className="info2">
      <Container className="container-bot-info2">
        <Row className="container1-info2">
          <img src={anniversarylogo} alt="56 Años" />
        </Row>
        <Row className="container2-info2">
          <Col size={12} sm={4}>
            <img src={test} alt="" className="info2-img" />
            <h2>
              <FormattedMessage
                id="info2.col1h2"
                defaultMessage="¿Qué hay en TU TANQUE?"
              />
            </h2>
            <hr />
            <p>
              <FormattedMessage
                id="info2.col1p1"
                defaultMessage="Investigaciones han revelado que más del 75% de los tanques analizados presentan contaminación y contienen combustible que no cumple con las especificaciones actuales del motor. La correcta toma de muestras y el análisis exhaustivo del combustible son esenciales para determinar con precisión el contenido de tu tanque. El proceso se resume en tres pasos clave: Probar, Tratar y Proteger. Estas medidas simples y efectivas garantizarán un óptimo desempeño de tu motor y la protección de tu sistema de combustible."
              />
            </p>
            <p>
              <FormattedMessage
                id="info2.col1p2"
                defaultMessage="El crecimiento microbiano y la biomasa son las principales causas de problemas en motores, fallas en el motor, obstrucciones en filtros de combustible y contaminación de tanques de combustible en diésel y queroseno para aviones. En la actualidad, los combustibles refinados crean un entorno altamente propicio para el crecimiento de microorganismos, lo que puede causar daños significativos en los sistemas de motor y combustible. El contenido de agua en el combustible es un factor contribuyente, ya que incluso pequeñas cantidades son suficientes para mantener la vida microbiana. Además, los biocombustibles tienden a ser más susceptibles al agua. Por ejemplo, el biodiesel es un 85% más soluble en agua que el combustible diésel convencional, lo que agrava aún más el problema."
              />
            </p>
          </Col>
          <Col size={12} sm={4}>
            <img src={treat} alt="" className="info2-img" />
            <h2>
              <FormattedMessage
                id="info2.col2h2"
                defaultMessage="¿Por qué TRATAR el combustible?"
              />
            </h2>
            <hr />
            <p>
              <FormattedMessage
                id="info2.col2p1"
                defaultMessage="La única forma de eliminar el crecimiento microbiológico o remediar el combustible degradado de manera efectiva es mediante el tratamiento del combustible con un aditivo de calidad. Durante más de 56 años, Biobor JF ha sido el biocida más confiable y ampliamente utilizado en todo el mundo. Es importante tener en cuenta que la limpieza del combustible y del tanque por sí sola no será suficiente para eliminar los microorganismos o remediar el combustible deteriorado. Para abordar estos problemas de manera efectiva, es crucial recurrir a un aditivo como Biobor JF."
              />
            </p>
            <p>
              <FormattedMessage
                id="info2.col2p2"
                defaultMessage="Ya sea que estés operando un vehículo recreativo, una embarcación marina, una aeronave o una instalación de almacenamiento de millones de galones, es absolutamente crucial utilizar de manera adecuada y constante un biocida para prevenir problemas microbianos. Biobor JF proporciona una dosis de 'choque' altamente efectiva para eliminar el crecimiento existente de bacterias y hongos cuando se presentan problemas o hay signos evidentes de contaminación. Mediante el uso regular de Biobor JF en la dosis de 'mantenimiento', podrás evitar y mantener un sistema de combustible estéril, proteger el sistema de combustible, prolongar la vida útil de tus equipos y reducir la responsabilidad asociada. Los aditivos de Biobor remediarán y mejorarán de manera eficaz el combustible."
              />
            </p>
          </Col>
          <Col size={12} sm={4}>
            <img src={protect} alt="" className="info2-img" />
            <h2>
              <FormattedMessage
                id="info2.col3h2"
                defaultMessage="¿Quieres PROTEGER tu inversión?"
              />
            </h2>
            <hr />
            <p>
              <FormattedMessage
                id="info2.col3p1"
                defaultMessage="El uso de combustible contaminado se traduce en costos de mantenimiento más elevados. Si no cuentas con un programa de gestión de calidad de combustible en marcha, es probable que ya estés experimentando algunos de los principales signos de advertencia, que incluyen:"
              />
            </p>
            <br />
            <ul>
              <li>
                <FormattedMessage
                  id="info2.ul1li1"
                  defaultMessage="Reemplazos prematuros de filtros"
                />
              </li>
              <br />
              <li>
                <FormattedMessage
                  id="info2.ul1li2"
                  defaultMessage="Filtros corroídos"
                />
              </li>
              <br />
              <li>
                <FormattedMessage
                  id="info2.ul1li3"
                  defaultMessage="Fallos en el sistema de combustible"
                />
              </li>
              <br />
              <li>
                <FormattedMessage
                  id="info2.ul1li4"
                  defaultMessage="Corrosión influenciada por microorganismos"
                />
              </li>
              <br />
              <li>
                <FormattedMessage
                  id="info2.ul1li5"
                  defaultMessage="Fugas en el sistema"
                />
              </li>
              <br />
              <li>
                <FormattedMessage
                  id="info2.ul1li6"
                  defaultMessage="Baja eficiencia del motor"
                />
              </li>
              <br />
              <li>
                <FormattedMessage
                  id="info2.ul1li7"
                  defaultMessage="Fallos en líneas y tanques"
                />
              </li>
              <br />
              <li>
                <FormattedMessage
                  id="info2.ul1li8"
                  defaultMessage="Envejecimiento acelerado del combustible"
                />
              </li>
              <br />
              <li>
                <FormattedMessage
                  id="info2.ul1li9"
                  defaultMessage="Fallos prematuros de los inyectores"
                />
              </li>
              <br />
              <li>
                <FormattedMessage
                  id="info2.ul1li10"
                  defaultMessage="Fallos del motor"
                />
              </li>
              <br />
              <li>
                <FormattedMessage
                  id="info2.ul1li11"
                  defaultMessage="Aumento de las emisiones"
                />
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="container3-info2">
          <Col size={12} sm={4} className="container3-info2-col1">
            <h2>
              <FormattedMessage
                id="info2.col4h2"
                defaultMessage="Inspecciona y PRUEBA el combustible utilizando el kit de detección Hum-Bug o FUELSTAT"
              />
            </h2>
            <hr />
            <ol>
              <li>
                <FormattedMessage
                  id="info2.ol1li1"
                  defaultMessage="Identifica el agua libre y elimínala de inmediato"
                />
              </li>
              <li>
                <FormattedMessage
                  id="info2.ol1li2"
                  defaultMessage="Inspecciona visualmente el combustible: si se ve mal, es malo"
                />
              </li>
              <li>
                <FormattedMessage
                  id="info2.ol1li3"
                  defaultMessage="Realiza pruebas de crecimiento microbiano utilizando Hum-Bug o FUELSTAT"
                />
              </li>
              <li>
                <FormattedMessage
                  id="info2.ol1li4"
                  defaultMessage="Busca corrosión, otra señal de contaminación microbiana. Si está presente, trata el combustible"
                />
              </li>
            </ol>
          </Col>
          <Col size={12} sm={4} className="container3-info2-col2">
            <h2>
              <FormattedMessage
                id="info2.col5h2"
                defaultMessage="Limpia, remedia y TRATA tu combustible con productos de Biobor"
              />
            </h2>
            <hr />
            <ol>
              <li>
                <FormattedMessage
                  id="info2.ol2li1"
                  defaultMessage="Identifica y trata la presencia de microbios, corrosión o combustible envejecido de manera efectiva"
                />
              </li>
              <li>
                <FormattedMessage
                  id="info2.ol2li2"
                  defaultMessage="Protege tu combustible y elimina la contaminación microbiana con Biobor JF"
                />
              </li>
              <li>
                <FormattedMessage
                  id="info2.ol2li3"
                  defaultMessage="Mejora la calidad y operabilidad del combustible con los aditivos de combustible Biobor"
                />
              </li>
            </ol>
          </Col>
          <Col size={12} sm={4} className="container3-info2-col3">
            <h2>
              <FormattedMessage
                id="info2.col6h2"
                defaultMessage="Supervisa, mantén y PROTEGE tu combustible con los productos Biobor"
              />
            </h2>
            <hr />
            <ol>
              <li>
                <FormattedMessage
                  id="info2.ol3li1"
                  defaultMessage="Realiza muestreos y pruebas periódicas del combustible para identificar cambios en su calidad"
                />
              </li>
              <li>
                <FormattedMessage
                  id="info2.ol3li2"
                  defaultMessage="Protege y mantiene tu combustible limpio al tratarlo trimestralmente con Biobor JF para reducir la corrosión del sistema"
                />
              </li>
              <li>
                <FormattedMessage
                  id="info2.ol3li3"
                  defaultMessage="Optimiza la protección estacional de tu combustible utilizando aditivos de combustible de Biobor"
                />
              </li>
            </ol>
          </Col>
        </Row>
        <Row className="container4-info2">
          <div>
            <img src={testTreatProtect} alt="" className="testtreatprotect" />
            <img src={comparativeTable} alt="" className="comparativetable" />
          </div>
        </Row>
        <Row className="container5-info2">
          <h2 className="container5-info2-h2x1">
            <FormattedMessage
              id="info2.row5-h2-1"
              defaultMessage="Mejora de la calidad del combustible"
            />
            <hr />
          </h2>
          <p>
            <FormattedMessage
              id="info2.row5-p1"
              defaultMessage="Es probable que estés familiarizado/a con el renombrado nombre Biobor®. Desde su desarrollo original y formulación por U.S. Borax en 1965, Hammonds Fuel Additives, Inc. ha sido el propietario y comercializador de la reconocida marca Biobor® JF durante casi 30 años. Este destacado aditivo ha sido recomendado por fabricantes de motores a reacción y diésel en muchos manuales de operación como el aditivo de combustible estándar preferido de la industria para eliminar los microorganismos que se forman en los tanques de combustible (conocidos como HUM Bugs) que utilizan hidrocarburos."
            />
          </p>
          <h2 className="container5-info2-h2x2">
            <FormattedMessage
              id="info2.row5-h2-2"
              defaultMessage="Otros productos Biobor"
            />
          </h2>
          <Col size={12} sm={1}></Col>
          <Col size={12} sm={4}>
            <img
              src={marineDiesel}
              alt="Biobor MD: el acondicionador de combustible ideal para vehículos. Mejora rendimiento, lubricidad y reduce vertido en frío. Potencia, ahorro y eco-amigable."
            />
          </Col>
          <Col size={12} sm={6}>
            <h2 className="container5-info2-h2x3">
              <FormattedMessage
                id="info2.col9-h2x1"
                defaultMessage="¿Qué es Biobor MD?"
              />
            </h2>
            <p>
              <FormattedMessage
                id="info2.col9-p1"
                defaultMessage="Biobor MD (Marine Diesel) es un innovador acondicionador de combustible diseñado para vehículos de carretera. Mejora el rendimiento del combustible, aumenta la lubricidad y reduce el punto de vertido en frío (CFPP) hasta en 25 grados Fahrenheit. Biobor MD aumenta el nivel de cetano para facilitar el arranque en frío y limpia todo el sistema de lodos. Al tratar regularmente el combustible con Biobor MD, podrás disfrutar de una mayor potencia disponible, un mayor par motor y una mayor economía de combustible, al mismo tiempo que reduces hasta un 94% las emisiones de hollín y la acumulación de carbono."
              />
            </p>
          </Col>
          <Col size={12} sm={1}></Col>
        </Row>
        <Row className="container6-info2">
          <Col size={12} sm={1} className="container6-info2-col1"></Col>
          <Col size={12} sm={6} className="container6-info2-col2">
            <h2 className="container5-info2-h2x4">
              <FormattedMessage
                id="info2.col12-h2x1"
                defaultMessage="Elimina el Etanol con: Biobor EB"
              />
            </h2>
            <p>
              <FormattedMessage
                id="info2.col12-p1"
                defaultMessage="Hammonds también ofrece Biobor EB (Ethanol Buster), un producto diseñado para contrarrestar los efectos perjudiciales del etanol en la gasolina y mejorar la eficiencia de un motor. El etanol es altamente corrosivo y puede dañar el motor si no se mezcla adecuadamente con la gasolina. Con el tiempo, el etanol tiende a separarse de la gasolina en el tanque, pero Biobor EB evita esta separación de fases, limpia el motor, dispersa los sedimentos y estabiliza la gasolina para su almacenamiento. Los aditivos de combustible Biobor® están ampliamente distribuidos a nivel nacional por West Marine y otros reconocidos distribuidores marinos."
              />
            </p>
          </Col>
          <Col size={12} sm={4} className="container6-info2-col3">
            <img
              src={ethanolBuster}
              alt="Biobor EB: protección contra los efectos del etanol en la gasolina. Mejora la eficiencia del motor y evita problemas de corrosión. Aditivos de combustible confiables y ampliamente distribuidos a nivel nacional."
            />
          </Col>
          <Col size={12} sm={1} className="container6-info2-col4"></Col>
        </Row>
      </Container>
    </section>
  );
};
