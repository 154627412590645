import img1 from '../../../assets/img/exol/products/industrial/greases/1-z011-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/greases/2-z074-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/greases/3-z003-600x600.webp';
import img4 from '../../../assets/img/exol/products/industrial/greases/4-z004-600x600.webp';
import img5 from '../../../assets/img/exol/products/industrial/greases/5-z005-600x600.webp';
import img6 from '../../../assets/img/exol/products/industrial/greases/6-z034-600x600.webp';
import img7 from '../../../assets/img/exol/products/industrial/greases/7-z053-600x600.webp';
import img8 from '../../../assets/img/exol/products/industrial/greases/8-z069-600x600.webp';
import img9 from '../../../assets/img/exol/products/industrial/greases/9-z008-600x600.webp';
import img10 from '../../../assets/img/exol/products/industrial/greases/10-z049-600x600.webp';
import img11 from '../../../assets/img/exol/products/industrial/greases/11-z010-600x600.webp';
import img12 from '../../../assets/img/exol/products/industrial/greases/12-z025-600x600.webp';
import img13 from '../../../assets/img/exol/products/industrial/greases/13-z002-600x600.webp';
import img14 from '../../../assets/img/exol/products/industrial/greases/14-z001-600x600.webp';
import img15 from '../../../assets/img/exol/products/industrial/greases/15-z036-600x600.webp';
import img16 from '../../../assets/img/exol/products/industrial/greases/16-z026-600x600.webp';
import img17 from '../../../assets/img/exol/products/industrial/greases/17-z023-600x600.webp';
import img18 from '../../../assets/img/exol/products/industrial/greases/18-z111-600x600.webp';
import img19 from '../../../assets/img/exol/products/industrial/greases/19-z104-600x600.webp';
import img20 from '../../../assets/img/exol/products/industrial/greases/20-z108-600x600.webp';
import img21 from '../../../assets/img/exol/products/industrial/greases/21-z109-600x600.webp';
import img22 from '../../../assets/img/exol/products/industrial/greases/22-z110-600x600.webp';
import img23 from '../../../assets/img/exol/products/industrial/greases/23-z107-600x600.webp';
import img24 from '../../../assets/img/exol/products/industrial/greases/24-z119-600x600.webp';
import img25 from '../../../assets/img/exol/products/industrial/greases/25-grease-drum-silver-600x600.webp';

const listProductsGreasesIn = [

  { option: "mapgreasesin1", name: "namegreasesin1", imgUrl: img1, description: "", url: "greasesin.url1" },
  { option: "mapgreasesin2", name: "namegreasesin2", imgUrl: img2, description: "", url: "greasesin.url2" },
  { option: "mapgreasesin3", name: "namegreasesin3", imgUrl: img3, description: "", url: "greasesin.url3" },
  { option: "mapgreasesin4", name: "namegreasesin4", imgUrl: img4, description: "", url: "greasesin.url4" },
  { option: "mapgreasesin5", name: "namegreasesin5", imgUrl: img5, description: "", url: "greasesin.url5" },
  { option: "mapgreasesin6", name: "namegreasesin6", imgUrl: img6, description: "", url: "greasesin.url6" },
  { option: "mapgreasesin7", name: "namegreasesin7", imgUrl: img7, description: "", url: "greasesin.url7" },
  { option: "mapgreasesin8", name: "namegreasesin8", imgUrl: img8, description: "", url: "greasesin.url8" },
  { option: "mapgreasesin9", name: "namegreasesin9", imgUrl: img9, description: "", url: "greasesin.url9" },
  { option: "mapgreasesin10", name: "namegreasesin10", imgUrl: img10, description: "", url: "greasesin.url10" },
  { option: "mapgreasesin11", name: "namegreasesin11", imgUrl: img11, description: "", url: "greasesin.url11" },
  { option: "mapgreasesin12", name: "namegreasesin12", imgUrl: img12, description: "", url: "greasesin.url12" },
  { option: "mapgreasesin13", name: "namegreasesin13", imgUrl: img13, description: "", url: "greasesin.url13" },
  { option: "mapgreasesin14", name: "namegreasesin14", imgUrl: img14, description: "", url: "greasesin.url14" },
  { option: "mapgreasesin15", name: "namegreasesin15", imgUrl: img15, description: "", url: "greasesin.url15" },
  { option: "mapgreasesin16", name: "namegreasesin16", imgUrl: img16, description: "", url: "greasesin.url16" },
  { option: "mapgreasesin17", name: "namegreasesin17", imgUrl: img17, description: "", url: "greasesin.url17" },
  { option: "mapgreasesin18", name: "namegreasesin18", imgUrl: img18, description: "", url: "greasesin.url18" },
  { option: "mapgreasesin19", name: "namegreasesin19", imgUrl: img19, description: "", url: "greasesin.url19" },
  { option: "mapgreasesin20", name: "namegreasesin20", imgUrl: img20, description: "", url: "greasesin.url20" },
  { option: "mapgreasesin21", name: "namegreasesin21", imgUrl: img21, description: "", url: "greasesin.url21" },
  { option: "mapgreasesin22", name: "namegreasesin22", imgUrl: img22, description: "", url: "greasesin.url22" },
  { option: "mapgreasesin23", name: "namegreasesin23", imgUrl: img23, description: "", url: "greasesin.url23" },
  { option: "mapgreasesin24", name: "namegreasesin24", imgUrl: img24, description: "", url: "greasesin.url24" },
  { option: "mapgreasesin25", name: "namegreasesin25", imgUrl: img25, description: "", url: "greasesin.url25" },

];


  export default listProductsGreasesIn;