import React, { useContext, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import listProductsFullySyntheticEngineOils from "../ProductsExolAutomotive/listProductsFullySyntheticEngineOils";
import "../../../assets/css/pages/Exol/Category/Automotive/categoryAutomotive.css";
import { AskInformationProducts } from "../../../components/Modal/AskInformationProducts";

export const FullySyntheticEngineOils = () => {
  
  const intl = useIntl(); //Cada ves que la web este gris, activar este comando
  
  // Inicio Modal Solicitud de Informacion Productos

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Fin Modal Solicitud de Informacion Productos


  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "fullysyntheticengineoils.title",
    defaultMessage:
      "Exol - Aceites de motor totalmente sintéticos de bajo contenido de SAPS | OilFluid", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para Description */

  const description = intl.formatMessage({
    id: "fullysyntheticengineoils.description",
    defaultMessage:
      "Aceites de motor Exol de bajo contenido de SAPS: lubricación y protección excepcionales para motores de alto rendimiento. Fórmula sintética avanzada que minimiza el desgaste y mantiene los sistemas de escape limpios. Confíe en Exol para el máximo rendimiento y durabilidad del motor", //Cambia Descripcion Pagina
  });

  return (
    <section className="categoryAutomotive">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-categoryAutomotive">
        <div className="head-categoryAutomotive-img"></div>
        <div></div>
      </div>
      <Container>
        <Row className="product-categoryAutomotive-row1"></Row>
        <Row className="product-categoryAutomotive-row2">
          <h1>
            <FormattedMessage
              id="fullysyntheticengineoils.row2-h1"
              defaultMessage="Productos | Aceites de motor totalmente sintéticos de bajo contenido de SAPS"
            />
          </h1>
          <Col>
            <div className="container-listProductsAutomotive">
              {listProductsFullySyntheticEngineOils.map((product, index) => (
                <div
                  key={index}
                  className="product-link-categoryAutomotive"
                  onClick={handleOpenModal}
                  // onClick={scrollToTop}
                >
                  <div className="map-listProductAutomotive">
                    <h2>
                      <FormattedMessage
                        id={product.name}
                        defaultMessage={product.name}
                      />
                    </h2>
                    <img src={product.imgUrl} alt="Optimal Exol Lubricantes" />
                    <p>
                      <FormattedMessage
                        id={product.option}
                        defaultMessage={product.option}
                      />
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <AskInformationProducts
              showModal={showModal}
              onHide={handleCloseModal}
            />{" "}
            {/* Modal Escondido */}
          </Col>
        </Row>
        <Row className="product-categoryAutomotive-row3"></Row>
      </Container>
    </section>
  );
};
