const listAncillaryProductsCo = [

    { option: "mapancillaryproductsco1", name: "nameancillaryproductsco1", imgUrl: "https://dsm01pap002files.storage.live.com/y4mInwTLOnTqyPbfl1F2IKN9KXODRZ77O9dkToi5lPI93rLfqjtmZ9qYcb2QNfzpNGXxMPrIj74mLC-1IU5VSnZjnhQjhPrb0RdWDfU4wps-N7Zr6IIu80X2NPRptuGW9vbNamriJ0H1l3VoISfDONmpvrpK8DuZ5aZmgrulAhgBsWTUgSF6ZuxunW2_DF6ZWw6?width=600&height=600&cropmode=none", description: "ANTIFREEZE BLUE (D001)", url:"ancillaryproductsco.url1"},
    { option: "mapancillaryproductsco2", name: "nameancillaryproductsco2", imgUrl: "https://dsm01pap002files.storage.live.com/y4mesbvP5TDor7JnibwXWVWjpgQPmxfyhIbG4lR7woHkU-xuvy69DR5VW_WYukqDtMWfHE660qj_W24PFFYV8pomHg7EdfsC-HvbFdLQJTpuSYRM6KzPmVN_myRSMg_s2IO9elESihQJe4TKdNTfKalG1EEg9ky1h_fF82um5kLc2PxdyLnIr4pnkxneUIpUPwz?width=600&height=600&cropmode=none", description: "ANTIFREEZE RED (D017)", url:"ancillaryproductsco.url2" },
    { option: "mapancillaryproductsco3", name: "nameancillaryproductsco3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mMq_dWauMVc3eRbZ3G-kzxbIyarePzZ3mg4BKVl3lrSIzSh_cPlDajhJypAJCxaJTYNAa_KhfqjpVEedHadwR0TGJMRC1VOVFgceklrR9A5_QaS6VJZsbio_28xeCpQ-ukUUcHPnJTX1sQ6bgoPQwtCejX1LYo1PtfpMm_8K4eT7KuAgwf5VXlt-fGJtb-CGU?width=600&height=600&cropmode=none", description: "ANTIFREEZE HD GREEN (D027)", url:"ancillaryproductsco.url3" },
    { option: "mapancillaryproductsco4", name: "nameancillaryproductsco4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mPSKiG6aTh0tWCYBwz54xF0-rJJjHPsviq0eKX4frWzsrGpjRvZcmk7O0OCzqT4e9V7ZNFFRteK3EbNrlU1HbNDT__iX3DCafDRxwHYM0Ukb5nv6VySmDjubzZDYxlVeQpjWDvn64mp5eiw1IPpz8cshMsezAdy0d6rI1OFsLcvMMEeqMsVjLhHOWjUTdmTNj?width=600&height=600&cropmode=none", description: "BRAKE FLUID DOT 4 (H053)", url:"ancillaryproductsco.url4" },
    { option: "mapancillaryproductsco5", name: "nameancillaryproductsco5", imgUrl: "https://dsm01pap002files.storage.live.com/y4mR54pdmITRO-UVpJDAQkM4-xfZ5ZEyQgDgskNNPXbgR9Io-b4zgiDbBnxecHXvii5QI-xxufPsHOFfzc0_ActK75Qa7odcxXqavNt4EPiTsE6IzGHedMmezJj0VE0HOoeydXq5hq-HiGcXB_5_loC5Gr__GvE1NsHhkGipHSB_1y3OGhdpu1MJY8rrHtcqNQz?width=600&height=600&cropmode=none", description: "ANTIFREEZE SOAT LILAC (D032)", url:"ancillaryproductsco.url5" },
    { option: "mapancillaryproductsco6", name: "nameancillaryproductsco6", imgUrl: "https://dsm01pap002files.storage.live.com/y4mGexLZlPJXutLJexheJAFNh-41Qg_nOukY5VAWYICOovDIApshYwhvGM910JzbHEP_u-eKHPzjxRFwVV6eT5kjRb8Z9ma-rGN-fVobUkwSxoR2UvUM4iKMOH-SA52rNbdmt_eS-m2owEkBNYsu4YoSQ1DxaXCffZshiHlJS5uv9Tq8kCcStPTxln04UozyzY9?width=600&height=600&cropmode=none", description: "ANTIFREEZE HDX BLUE GREEN (D031)", url:"ancillaryproductsco.url6" },
    { option: "mapancillaryproductsco7", name: "nameancillaryproductsco7", imgUrl: "https://dsm01pap002files.storage.live.com/y4m3GCMb9msVtOnA7D_UwoiBi43VlflE4d5LxXwqCUixe9pbBtG10LsXBxyJ_JhVq73IWaA1oe-0aJHwK_m4TbUwkHDhElWZTZKOKXmvvqnPU4EE7Hk1F13c6gtmag-xWN0pRWs5lkzfdsbbzwbIKsH520xD_wgiqZF6BZP5x7Wf2U18hE5--6hHMZw7in7qTdN?width=600&height=600&cropmode=none", description: "ANTIFREEZE YELLOW (D039)", url:"ancillaryproductsco.url7" },
    { option: "mapancillaryproductsco8", name: "nameancillaryproductsco8", imgUrl: "https://dsm01pap002files.storage.live.com/y4mJH5imRph4UAphwyixT8g3T6KDDKmKfQNUexixYlXuIsv1XC2boGHvlQRUidrxR0Ytj1Q71b3-8NGtHkXqoyL_08vKtbAi0M9PN97AC5S0EW0sP3Vtctct3HF7OvceJWVApYGO6OerSFSqJ_FugQ2rsfAt2J8an9NXjJq_2GrbNrC9MaCutRdZkwha1FYQfNa?width=600&height=600&cropmode=none", description: "FLEETWASH (D003)", url:"ancillaryproductsco.url8" },
    { option: "mapancillaryproductsco9", name: "nameancillaryproductsco9", imgUrl: "https://dsm01pap002files.storage.live.com/y4mAcGtxronzF5Z41CJwViNK3hQL4sRz2NEYe7Yb1WAifHtgutKaL_eAeKseKqbjQhl-iHRMPDrUVzBbQIijSLk7UJyH9saaKeqEXjiy0uzzylZjuJqlOFxBDbyDZb3amVCKq42_0FgvPgXdc8x8Cgi2NVNx8ac_hnAtEJoZ5CKZfF7GJKZ4hqE5y4idz3ChNvr?width=600&height=600&cropmode=none", description: "DELTA DEGREASER (D002)", url:"ancillaryproductsco.url9" },
    { option: "mapancillaryproductsco10", name: "nameancillaryproductsco10", imgUrl: "https://dsm01pap002files.storage.live.com/y4mOs2_sCLrT8LEAmfQLf2EFP48cAz2hF5zv0R4kSlo4bLy0JGAvGx7Yt8tc1ZmvZIYWx10aiOv2wkmkyDbI4inpc-ei8FLl4nQpLUjFyPrjTuP4FrdoaWeG9Z_ccr6qf-faFmOVRHqBqWZwnTlAnanm0TEiZpgAuBZD7k536Fb-MMIMILMImFUY4KY4_j65d4X?width=600&height=600&cropmode=none", description: "GRANULES (W003G)", url:"ancillaryproductsco.url10" },
    { option: "mapancillaryproductsco11", name: "nameancillaryproductsco11", imgUrl: "https://dsm01pap002files.storage.live.com/y4m6k6YQ_AtahBSxEMrY8cmqlwBqutRfuGxBDe2EZzr5ILBsdYWJPwCDN6Nas0XIjDBNf2_7QBfRaWZdHeLYkVFSB4zBMJOkSgnaK54njp7yr38_OMbhjBnzJj2H1Z0n8y9wg2VwfIDl6x6tuZO8IOmAEZv4TfXXIP2V8OhETFrzTQWN9t_eLFml0nEaGgzwdhK?width=600&height=600&cropmode=none", description: "ANTIFREEZE BLUE 50% (D021)", url:"ancillaryproductsco.url11" },
    { option: "mapancillaryproductsco12", name: "nameancillaryproductsco12", imgUrl: "https://dsm01pap002files.storage.live.com/y4m32EFkz1JNfvGCWzM4--tyqV_4I2WGyO_ofvQtaiRjpW-i1XuuU5PQRg5a_Ry4GXXRiPkaVpVL0JnvlWlz1hrNRmib4PVlB-hKDXR9wRjkvbFpOmPdSDnKt5P42Ae1V4qosR5BeKNL2jOyR5K-Ie-xS_SIagtHR4GeYkq_ZP_v8YN1PE1U8vHcNTkAHZUZp72?width=600&height=600&cropmode=none", description: "ANTIFREEZE RED 50% (D020)", url:"ancillaryproductsco.url12" },
    { option: "mapancillaryproductsco13", name: "nameancillaryproductsco13", imgUrl: "https://dsm01pap002files.storage.live.com/y4mU_ZJN8jwOPXg27LiSBTPgrOIuhWO5gpMgtRqsxuAeQgoFdgudnzZTB6PmIVWioGCMdbRAa_7wpw9e1It5Otz_xLy3b6MHnYVaIBsjFz3-TsbNC9Eu6RXtkiSFLrZkj378QtmUnWuL9MHbzP-tPhSNdhDQ87IQQk8ATB74uTJsfVgqBRwxP_IoBYafrJDnhzt?width=600&height=600&cropmode=none", description: "ANTIFREEZE UNIVERSAL CLEAR (D033)", url:"ancillaryproductsco.url13" },
    { option: "mapancillaryproductsco14", name: "nameancillaryproductsco14", imgUrl: "https://dsm01pap002files.storage.live.com/y4mHMfvBFsupNxtGMF_wDg4oueGLFWd9L5oa6P38-MNPERo4qiJ7IuE3ueW7Xrp2VpF1JfNPRL769jcZrsICKOt2Du3KK4-DsT1mN7W06g-amjdIK0kFjqhjo6xr5uXbvsAoKMy0RMD0d2lPvJU8Fh83j43yeG0NQ32sEd2MtcXj4sR5rvJA8RxB5jU7HNUWes9?width=600&height=600&cropmode=none", description: "POWER STEERING FLUID (A001)", url:"ancillaryproductsco.url14" },
    { option: "mapancillaryproductsco15", name: "nameancillaryproductsco15", imgUrl: "https://dsm01pap002files.storage.live.com/y4mQR3nBPk-dX69Gyc9_fDcmzxZWz1EgKLZPpqQTNifPDTuPEHtkYKaysGVhPCT6gVpSlbp0pAiizSv9XUbLg2ZHxRgYVLgIq8QGj3h0A-hgh6iamnq67BBDzpLF4xqBk_vWNmVs7Ab9K8DqWdE4jQDjWOvl7pW_bUaPrb1UKRe8Dao6Sq8VNELdES3vFqxy1iz?width=600&height=600&cropmode=none", description: "CHF (H201)", url:"ancillaryproductsco.url15" },

];
  
  export default listAncillaryProductsCo;