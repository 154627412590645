import React from "react";
import '../assets/css/pages/notFound.css'
import { FormattedMessage } from "react-intl";

export const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className="not-found-title">404!</h1>
        <br />
        <p className="not-found-message">
          <FormattedMessage
            id="page.notfound"
            defaultMessage="Lo sentimos, la página que estás buscando no existe."
          />
        </p>
      </div>
    </div>
  );
};
