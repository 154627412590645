const listCategoryAutomorive = [

    { option: "mapautomotive1", name: "nameautomotive1", imgUrl: "https://dsm01pap002files.storage.live.com/y4m-rYi2TmxKi3uV-dWKbsUL9VCsxF_tQFwy4KaIos8S3gTsk_U6mobTAHIZkKXKjy75nxpLWJ55Cl9LPGnEfmClsNjGAjAK3z8O3e3aKVuhsluLHOnN-WlMuRUcz9lgYsaK7QIcBl1jwGuMDQ1yAkNyHLvTMK4Z0avOiEcIO1_5l59fWtHtbGBWBi1SEr1mKM_?width=500&height=500&cropmode=none", description: "Totalmente Sintéticos de Bajo Contenido de Cenizas y Bajas Emisiones", url:"automotive.url1"},
    { option: "mapautomotive2", name: "nameautomotive2", imgUrl: "https://dsm01pap002files.storage.live.com/y4mgtFjR39qgmKsaAztbwb1iPJY7_kMl_GsFsVChcpHvnIXj48MJQBxySMVht9gUPsOuTHCnm2UgIeZ7iVaJs_dmXvFwjKhrsKNw1jrAq4GLQSBywt0AkFLrdHy_84q0-lAC5kNqUnFXHlgsvm86RFuxtjIZRAU26fmq32iRrpdPxLMF0CJ4KMRTGTyGYzVNu2d?width=500&height=500&cropmode=none", description: "Totalmente Sintéticos", url:"automotive.url2" },
    { option: "mapautomotive3", name: "nameautomotive3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mF7Fnp4nUTkDcy09Fv9zK5I6BwiYhy3mBTZ8wWt5S-eIM7N3bwxhjduaJCZF3bs0RibXs6X-nq-qVNrfWtGtuCBO4CNMNubGMOBNBblEnX-CbuUvAcry_1BC7KAVKDQ1dO2ji6XfYO2jDxG1duiy_i_qX-G2c7Q92Pg5zLm6qgnIKGtZYCHT7OALTDaFGZvZi?width=500&height=500&cropmode=none", description: "Semisintéticos", url:"automotive.url3" },
    { option: "mapautomotive4", name: "nameautomotive4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mfVATs5NYEUBlv7BH1SKo7mxiAibigodJfvqgRdJwjMtjvzd4N4YCSw2Ec46Bl2Hw5IuWQb_eFXh0rGmEh5Uf33qGYcya8ubA23yeaaDw-41Q69LfdC7qWHHKwSwTDPrEfhPihOBKJFRbWtIXZoqTguqI9foZ9wf9qy-EDbxAW2QjLDvbhg7aohl9udwcjh5E?width=600&height=600&cropmode=none", description: "A Base de Aceite Mineral", url:"automotive.url4" },
    { option: "mapautomotive5", name: "nameautomotive5", imgUrl: "https://dsm01pap002files.storage.live.com/y4mHd-vdBDhfQj1xnKiSLVp98ONPwDRc81EZL0IXIAoN0qLMfsGfKpZOG54UwNFZSdZOUNybpKZe2NiDTHzkNGVHuWNcXSgCIzbzu7H6lEoVleDohZ_YyKRbwsZwBV-ADP7rCPAXifRytvjO0knLlBv-CdS-rXvlbiyGzYaxhRwE0oiwTPzuX3k-ZHpEDDfNjJG?width=600&height=600&cropmode=none", description: "Funcionamiento suave y eficiente de la transmisión", url:"automotive.url5" },
    { option: "mapautomotive6", name: "nameautomotive6", imgUrl: "https://dsm01pap002files.storage.live.com/y4m5WC51Zwtc1qzVtrqj-leM8gQ9s-bA9XnVQ1kYkG33Vu1Z6UEPqzm_4dvjuRwIzLSlFy3YOxL3pVg9ifIDKgN7kap2-1WVC8LLnkz4mvsfYF_jwd3sCOmt3qRAxsIPAllqayycMWg7mtC5mYKevXDVpZChgk6Zor9zvX8k5snlGSyO2UBFbqr4CoF2pibMC_z?width=600&height=600&cropmode=none", description: "Protege tus piezas móviles del desgaste y la corrosión", url:"automotive.url6" },
    { option: "mapautomotive7", name: "nameautomotive7", imgUrl: "https://dsm01pap002files.storage.live.com/y4mwRR-2zwjh2l3wh7KpyozZNNCEnbVmdSZMxJK7OMc8RnJ5_fa9yRLMeIC5iIoF8Hoykks0mqk7ZpNM5mVp63qjDmhAELcF_ujUgYTdjRH8TgjT_TlIEqdwFS7VvXXLCpQZJhXla71wv5LW9wubZ8NQ7p2buTfXjeLqmt1dB-76ELGN8RAl_E0331lMKCDBvTP?width=600&height=600&cropmode=none", description: "Lubrica y protege eficientemente los motores de las motocicletas", url:"automotive.url7" },
    { option: "mapautomotive8", name: "nameautomotive8", imgUrl: "https://dsm01pap002files.storage.live.com/y4mUtwMbU97dEB0ZK2KCuTSZv3wGlVFyRJwLYAJJrfq8JihFiUneBWpXz4qvGnvX3Ky_WFAkqP1eKtxUg0L1BKUI-Bxp1ysO2vz7ZVWAwSJxk1TWeh7GJMvV44Y3y9aLiDsdnzw9eP3CJfYXZ7_fP9G9ox0uXwQAK0YAjHTmaAYCo9VDmPM62vebV1Ic4mAQTeF?width=600&height=600&cropmode=none", description: "Lubrica y protege eficientemente los motores de las motocicletas", url:"automotive.url8" },
    { option: "mapautomotive9", name: "nameautomotive9", imgUrl: "https://dsm01pap002files.storage.live.com/y4mx4RuF7qk92GUteoT33IgZvIuGlO0J_nBixjhIKEYtmB75Jy2LohA0UuLio3F-yDGIACYtuDIu43xn-aT6CIolO1zJXhwGEAYv7uuqEshMsnHuEWjPg03O8kx9MWymcwk6Koe_nYyMLeMIgjahrCa9bbVizCMRofu1Z-WSU-Wc3p1qF7fRuOIWECYT__W-qt-?width=600&height=600&cropmode=none", description: "Formulados con altos estándares de calidad", url:"automotive.url9" },
  ];
  
  export default listCategoryAutomorive;