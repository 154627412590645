import { Col, Container, Row } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import aviation from "../assets/img/aviation.png";
import diesel from "../assets/img/diesel.png";
import gasoline from "../assets/img/gasoline.png";
import accessories from "../assets/img/accessories.png";
// Images de Marcas
import BioborJFLogo from "../assets/img/mark/bioborjf_aviation.png";
// import TurbolineLogo from "../assets/img/mark/biobor_turboline.avif";
import LubriborLogo from "../assets/img/mark/biobor_lubribor_ci_li.png";
// import HumBugLogo from "../assets/img/mark/biobor_hum_bug.avif";

import { Banner2 } from "./Banner2";
import { Link } from "react-router-dom";
import { scrollToTop } from "../components/ReusableFuction/scrollUtils";
import { FormattedMessage, useIntl } from "react-intl";

export const Products = () => {

  const intl = useIntl(); //Cada ves que la web este gris, activar este comando
  
  /* Guardando Imagenes de Marcas en Variables */

  const turbolinePNG = "https://dsm01pap002files.storage.live.com/y4msXC9VPN1SgGWzcqvtAmv81jTnNDAn0lj0XsdYd3CSNPTbVSUiYgIpk1utIEvUGGkBuyYK9rjBHUVvqxQyiCTiiDxr9OPWe0waZHA58-r-YXK19Js2JrpjQbSgs29U9fX0OGBNn-QfEGkCK_-GYFFkYwrHKvOkwSVCNXQNY1kwrq-88xmyCi3o-Bsfzgt79Sm?width=394&height=69&cropmode=none";
  const HumgBugPNG = "https://dsm01pap002files.storage.live.com/y4mLAdOPodVDBfptNNMV5mThzayV6Lb_L2_4QkSls-qUsIJRFawkUw3W2Z8DE4w3j20fGDk5AGDbtXjtp0XqZscGEHf9VjfkPP_zSxdDqEvKiDPzv02vgs0RrFzMzqYHd1XUn5tKcievcz7vtC57UJMYS70d5XyZ_qB2ahG7BdvLKpzuOkDBocvvrcTj0Zf5QsS?width=394&height=164&cropmode=none";

  /* Mantener Activo el color fondo de los botones products */

  function isActiveRoute(route) {
    return window.location.pathname === route ? "active-products-url" : "";
  }

  return (
    <section className="product" id="products">
      <Container>
        <Row className="align-items-center gx-4">
          {/* Primera Fila */}{" "}
          <div className="container-products">
            <Col size={12} sm={3}>
              <div className="products-list aviation-products">
                <Link
                  to={intl.formatMessage({ id: "aviation.products-url" })}
                  onClick={scrollToTop}
                >
                  <FormattedMessage
                    id="product.aviation"
                    defaultMessage="Productos de Aviacion"
                  />
                </Link>
                <Link
                  to={intl.formatMessage({ id: "aviation.products-url" })}
                  onClick={scrollToTop}
                >
                  <img
                    src={aviation}
                    alt="aviation-product"
                    className={isActiveRoute(
                      intl.formatMessage({ id: "aviation.products-url" })
                    )}
                  />
                </Link>
              </div>
            </Col>
            <Col size={12} sm={3}>
              <div className="products-list diesel-products">
                <Link
                  to={intl.formatMessage({ id: "diesel.products-url" })}
                  onClick={scrollToTop}
                >
                  <FormattedMessage
                    id="product.diesel"
                    defaultMessage="Productos Diesel"
                  />
                </Link>
                <Link
                  to={intl.formatMessage({ id: "diesel.products-url" })}
                  onClick={scrollToTop}
                >
                  <img
                    src={diesel}
                    alt="diesel-product-biobor"
                    className={isActiveRoute(
                      intl.formatMessage({ id: "diesel.products-url" })
                    )}
                  />
                </Link>
              </div>
            </Col>
            <Col size={12} sm={3}>
              <div className="products-list gasoline-products">
                <Link
                  to={intl.formatMessage({ id: "gasoline.products-url" })}
                  onClick={scrollToTop}
                >
                  <FormattedMessage
                    id="product.gasoline"
                    defaultMessage="Productos de Gasolina"
                  />
                </Link>
                <Link
                  to={intl.formatMessage({ id: "gasoline.products-url" })}
                  onClick={scrollToTop}
                >
                  <img
                    src={gasoline}
                    alt="gasoline-product-biobor"
                    className={isActiveRoute(
                      intl.formatMessage({ id: "gasoline.products-url" })
                    )}
                  />
                </Link>
              </div>
            </Col>
            <Col size={12} sm={3}>
              <div className="products-list accessories-products">
                <Link
                  to={intl.formatMessage({ id: "accessories.products-url" })}
                  onClick={scrollToTop}
                >
                  <FormattedMessage
                    id="product.accessories"
                    defaultMessage="Accesorios"
                  />
                </Link>
                <Link
                  to={intl.formatMessage({ id: "accessories.products-url" })}
                  onClick={scrollToTop}
                >
                  <img
                    src={accessories}
                    alt="accessories-product-biobor"
                    className={isActiveRoute(
                      intl.formatMessage({ id: "accessories.products-url" })
                    )}
                  />
                </Link>
              </div>
            </Col>
          </div>
        </Row>
        <Row className="container-subscribe">
          {" "}
          {/* Segunda Fila */}
          <Col size={12} sm={8} className="container-subscribe-col1">
            <Banner2 />
          </Col>
          <Col size={12} sm={4} className="container-subscribe-col2">
            {/* <Subscribe /> */}
            <div className="container-mark-logo">
              <img src={BioborJFLogo} alt="BioborJF Aviation Products" />
              <img
                src={turbolinePNG}
                alt="Biobor Turboline Products"
              />
              <img src={LubriborLogo} alt="Biobor Lubribor Products" />
              <img
                src={HumgBugPNG}
                alt="Biobor Hum Bug Detector Kit Products"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
