const listProductsFullySyntheticEngineOils = [

    { option: "mapfullysyntheticengineoils1", name: "namefullysyntheticengineoils1", imgUrl: "https://dsm01pap002files.storage.live.com/y4mhaeVEsL3GojHBHM8QsbUy-Rl_n6NQ8CSBhdpFL9vpw587fED9Z8XJFU-_uG8H80L7Ugh1OCHH_hgs_Sx1dKJQMsaCXdGgh5RU8mqFksppnnfQ1yfDMfGLEaVhq7EVATe61M0y9GZWAkcgnTWPaJbOjX1D_XtT04R1xGoL-b1bMgNIPpzXswywdFR7aJPcCkK?width=600&height=600&cropmode=none", description: "FD 5W-30 (M396)", url:"fullysyntheticengineoils.url1"},
    { option: "mapfullysyntheticengineoils2", name: "namefullysyntheticengineoils2", imgUrl: "https://dsm01pap002files.storage.live.com/y4mXVCFIhvtNWJy4EDYt1kc51kRSyWUxGFxiszWOwvGJZkMXctO5e4KWQkid_25jkrD8Ol8jLPqL2CoXhjFljkJ5pwZzxsrPi2N0Jl4_aNGOuuqApCmCNkqg1OH-_Qoh5XXFqVjtSBCSNXfKdPMkaYDjESXnXrVq9_sLMORjrl0e-UJNgPXEn678uKGjb-vVcn3?width=600&height=600&cropmode=none", description: "FS 5W-30 (M400)", url:"fullysyntheticengineoils.url2" },
    { option: "mapfullysyntheticengineoils3", name: "namefullysyntheticengineoils3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mCyDTPKazSF3IrmqAF0r4ajsE0Fy8d3f2QfVHpR_ssKyPSdpQPmtHpdL77_jLlj26z-Z4R8SUb2-y8Bwu4zuMwmYk-2YtEVT6v64_xr0yHwqq0dsjLLwprcdu1kuFfrgAxJ69iTYmAj9-OxbjlvOXbghXRgbP_N4-_nGJKRPjQ-uy9wHvyOMQ2-icEa2UjKW9?width=600&height=600&cropmode=none", description: "FE 5W-20 (M439)", url:"fullysyntheticengineoils.url3" },
    { option: "mapfullysyntheticengineoils4", name: "namefullysyntheticengineoils4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mmQf0z6mXCQZiyQzVq05CsPslSZG6oUrphSVoX7FvnZH88FHWSoDW_qhtcHGRhXER2QcTlxBTE6YmhGXGoV-Plxm-lEzPf9BunoBtonOuvn00P9iIoh_bB8v5_oTXz2VqZThzDGXLE85BWm5Ti_vQ-2b8xMhbaknyC6afSwsKOX40R9n7pRNmlyFm3ermUV_w?width=600&height=600&cropmode=none", description: "FS 0W-20 (M455)", url:"fullysyntheticengineoils.url4" },
    { option: "mapfullysyntheticengineoils5", name: "namefullysyntheticengineoils5", imgUrl: "https://dsm01pap002files.storage.live.com/y4mF_N7vEmoYV_fF__AxQDHPYO8rBAcCYq5DNqu413KDzhz989U49eUA5iCtbg6z7DQND4NWgUx3f1R3GOucQrVPiFBczpb3otDybu60JWKBwoGvH--EWe00rQud086FPo3T_fo3u6G7iw7rSOWk58mkRK1ZrazAtJma_6i-hqacN5cey45uFPuz2az4LZuSHY4?width=600&height=600&cropmode=none", description: "LONGLIFE 5W-30 (M380)", url:"fullysyntheticengineoils.url5" },
    { option: "mapfullysyntheticengineoils6", name: "namefullysyntheticengineoils6", imgUrl: "https://dsm01pap002files.storage.live.com/y4mAHGIdYVmZyWuj_MyV_noaOyiIqBEr4ji4rSYaLcioIdHzRheyduvp39dm1xAYj-lAoGDqExwSoA1yBwSkxNWdQjtK589T_fYBCTyxFi_3pf6WooJHPlzRDtabCF-uzCC42TrIW9-Z-JgSO7QhxuaYOXzjDwwOiTd4wPYSOtQPJHDSB7JViAjDuuYvlNYOm-9?width=600&height=600&cropmode=none", description: "PD 5W-40 (M411)", url:"fullysyntheticengineoils.url6" },
    { option: "mapfullysyntheticengineoils7", name: "namefullysyntheticengineoils7", imgUrl: "https://dsm01pap002files.storage.live.com/y4mHiUbaUtckZZO1MJKmvUpDHbFiOaE938KmPIGus-HKmxrq3LVKCHSM2h6Aa-46_IE4BcENdX1BovYsq6SVvOOmb4hFtd8qU1heSJfs7eAYvQJcjR7Qt0Louigu7KsUQStjmkzUh8qvUBmK890v1BNVGyR2bSrOPd578jmzJyzwwoWcbtVSWhUepH5wfslh2D8?width=600&height=600&cropmode=none", description: "FS 5W-40 (M091)", url:"fullysyntheticengineoils.url7" },
    { option: "mapfullysyntheticengineoils8", name: "namefullysyntheticengineoils8", imgUrl: "https://dsm01pap002files.storage.live.com/y4m6bK1Vf7txvh65JRctbzIXhiqteLXi1KVZHu58NBaXzNrsfjcKuBxHM07Pmb6fSH5jqDNfUq_xcUW-lP-sy52Igt5MwDDhBIt6JCu0vMysMGddfKcd7pHG9coKuzJFFUQodXxIvAZUJUVG1WzUSbsyYuZ0Lk4leEJnyanIxMuX2wRLKQHas8ekil1239REJcN?width=600&height=600&cropmode=none", description: "FS 0W-30 (M344)", url:"fullysyntheticengineoils.url8" },
    { option: "mapfullysyntheticengineoils9", name: "namefullysyntheticengineoils9", imgUrl: "https://dsm01pap002files.storage.live.com/y4mBfk1Z1BkZjr1Wd50rsKZ580RlHrkGQTIJPPfu5jLP98QfoIZgwQOOkCCHXtg5IYsG8y1u_vBfxndDz6atuAWYlhQi4Rt0n4lMDwQF5xerlmK-LpVEsanJZtDbzDRKhJSdJiAaGRf1NXeqVrYxQhUDHtAZLT4nzeDyLpWHstKE7ngGSk3qxrbrUKV5dLR3ICq?width=600&height=600&cropmode=none", description: "FS 0W-40 (M369)", url:"fullysyntheticengineoils.url9" },
    { option: "mapfullysyntheticengineoils10", name: "namefullysyntheticengineoils10", imgUrl: "https://dsm01pap002files.storage.live.com/y4mIm421U1uqSineduGfLbnq_1BPLuDweIRPFvwQ_uBiZGAYqSKR5r_PkrtH0hqQRbAlI1VzfZG_CqyLUQ0QELuzwcnyGwufZ88wjZvOli2-9URm1eraSvTxuKRpMZVhfQGFnNe6HKhtnAq6TofQHnNKezX24KJAajinB_ynSJwfaMrAffx1cOB6-rlfrWYPMYS?width=600&height=600&cropmode=none", description: "SPORT 10W-60 (M482)", url:"fullysyntheticengineoils.url10" },
    { option: "mapfullysyntheticengineoils11", name: "namefullysyntheticengineoils11", imgUrl: "https://dsm01pap002files.storage.live.com/y4mF2iBMTbk0Gjjqevq2mMyXPRAJZqcvD6ImqWOrkdJFcuS4ZK95Qp24jbIBfr4KotABiNaAy8w-gj9AAyk3cp7lXDbMbH5tAsfB0kJgDtTq5RnbCiHHcf-wnpdHfG7fq7bbN6DnmNrhs_i5_ZhayNtmB9vhEyiJ_Hy12GtTl3PApJ-ql0QULb39gu881PPt0c3?width=600&height=600&cropmode=none", description: "DX1 5W-30 (M497)", url:"fullysyntheticengineoils.url11" },

];
  
  export default listProductsFullySyntheticEngineOils;