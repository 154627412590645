const listCategoryIndustrial = [

    { option: "mapindustrial1", name: "nameindustrial1", imgUrl: "https://dsm01pap002files.storage.live.com/y4mJvoJ4Gl9GtL-qMDT2yN8IJC3R6nrAa8-JE_jxDg2yXbNbkuuPaLYwMOA_Yjz1yXgjU-TjwBZZ6nHjciOZPbTHo1KhlASDGLEiTI6Ew4TF9wSnMkqY632Ong1U_D7ipgJFeXu2nR0yBMpzcnQ_atAAa0I36h57lupvnwpjI-8TSktD4mdDHyBJIUsNMBWammq?width=600&height=600&cropmode=none", description: "", url:"industrial.url1"},
    { option: "mapindustrial2", name: "nameindustrial2", imgUrl: "https://dsm01pap002files.storage.live.com/y4mmpAYT8_9GXbCGbrR2B5YpLyNHBh5RBSKM44XTHfUJeOtz17gcwJwCGljptXff2o5buQi9X_IN7Yr6uyGl4mUNNwMZTDAaAAqb7F5cno_zpWtyaS7887Kz2BdPfOWMAFSrk5HRHBICGLE_g31DDVJ1RyKCj8kd31Pe6xJtDFVhbV1TPYJPuFUwe4W5NxGi2g6?width=600&height=600&cropmode=none", description: "", url:"industrial.url2" },
    { option: "mapindustrial3", name: "nameindustrial3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mWeEQMXQbe3_q_M-9-qS0fKKyuLI_4kPr89Mwi8BzG5IeQLvdSdqW_bPEvk1p3sdm3K_zWTOUI3904G-oPUU7wFU2wm3qnfom1G8bBS2u89yIltMZMvAEHXaeAxa4Vr172rV7cT4ST0tK9U7PtR-tVUdJNY3wjyY5CG654C2DmQre0Mqw-glAQPs_zD90xw4u?width=600&height=600&cropmode=none", description: "", url:"industrial.url3" },
    { option: "mapindustrial4", name: "nameindustrial4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mTv4rNTI-g-0OQ2w3oAmKfapwACNUeV_PB_Iy4oi5CkzNwJhNQbZLd72fqrLwDmyRYads24GInPQnUO8hlB4vSPtYZ-UUpd_DF1nYPO8cCi6xJX4JcJppQQFd0np2Ql63rkY-iSlocK-Dhwy9TuSSYhhKjoaphdJbt_ajSxXApK9rJ-t-810grk9puUsjkszu?width=600&height=600&cropmode=none", description: "", url:"industrial.url4" },
    { option: "mapindustrial5", name: "nameindustrial5", imgUrl: "https://dsm01pap002files.storage.live.com/y4mpobflpHdLDHdMKyBoQkh4V1oYF80JjfpxxgAdXyJZyEIW4ciBmFevW3ls1ujeXSWj3RHzOAE2m8eoxx-xClw223gC-bDTMI7ib96Lk3IFaKeZmtEdUUZ4LfHW5ueH7qnJ1k8_Cpsk5Q1dSNJYpMXUQbCiEaQGlSsbbvZ-iby4MMnKOJTfOg22pGsRiV3O-vT?width=600&height=600&cropmode=none", description: "", url:"industrial.url5" },
    { option: "mapindustrial6", name: "nameindustrial6", imgUrl: "https://dsm01pap002files.storage.live.com/y4mXYLe3RVx3_C50wNDjsM82kxvlZyoWzPnxJG9LItVaieVC2jGguvcO_xAnd_DLEarIZd6N6waIES6Sgjh2jNrc4vF9F9IHXEJvlH3OI2r1fg-y4nUXti3nPTPgk_AxUmXoZlyO-SIIU931OjZQbiMp24YWHkYlq_dPoxv7wjOIoHak12DkBoz7s0gXq2a0ud0?width=600&height=600&cropmode=none", description: "", url:"industrial.url6" },
    { option: "mapindustrial7", name: "nameindustrial7", imgUrl: "https://dsm01pap002files.storage.live.com/y4mzlbh1vq3JBNPqRyRXz-iGYWZlUjtYZYeEBsTC1VmCv5oLNSL_XrsQU3yGUCGkaY2t_FWqBMG9Y_iHaAX8H88BOO1nLIbZR1LPIVsgQFNfDSxESv14T2O2hVamG2yhBova0WeieNUXV4o-MVWXBXoVI6sSyyg1KFw4tBInydWnhaivX9g4qxirIgI5gmRc96_?width=600&height=600&cropmode=none", description: "", url:"industrial.url7" },
    { option: "mapindustrial8", name: "nameindustrial8", imgUrl: "https://dsm01pap002files.storage.live.com/y4mYSrDQItC6p2kWHkYsY2Afk7zvzBbLhJPmbnBJ7deWDyJrtXbHbxmi-crnmUI4EhnR1GE-rVJWz_XG7KLVqgTs1SMRQ0Nwe41j0uwNuQDEEAaXpuMdAjV625VapdLJy1FOpzmBWxrq7CouEkK-mjopc7gfxzOPDvmd4vM16cvlgcEVGUzdVGHoJMBegzbT-Ap?width=600&height=600&cropmode=none", description: "", url:"industrial.url8" },
    { option: "mapindustrial9", name: "nameindustrial9", imgUrl: "https://dsm01pap002files.storage.live.com/y4mNhY3IyWrRgtLnKk0Bnbe1W9xoEAchLnbXQLZSc8LHt36K2GHP2W_W6rhjdZxWnIoiNCxFXNGEnnR1ZlNBGuAEYGkoxi_aFg6pUzFOgVDd2k75OfafpDV7trN6LScRLKkFASlCdIocKV_TYH8-AXg1x60MutOuIRYKkwuBifzla9GQxyiuKbjp1i12UyrA16e?width=600&height=600&cropmode=none", description: "", url:"industrial.url9" },
    { option: "mapindustrial10", name: "nameindustrial10", imgUrl: "https://dsm01pap002files.storage.live.com/y4m-1hQPNxsomnGL-w6pSFXaP6MoprwDOnvq67XCLdwkIP_GJ99bsqgsATPgSKjnfmQfmHKQugupLdyLEr5HUWrM4rRYTKmW8gvyHAQCVsF129-ggCMjmnPal6WGKeN2Y59Brp0W6x6LTo2AbBxBhywsRMpUYlXMtbqxhY7d4tvBxHeptkDqHPsullRFao3Os2P?width=600&height=600&cropmode=none", description: "", url:"industrial.url10" },
    { option: "mapindustrial11", name: "nameindustrial11", imgUrl: "https://dsm01pap002files.storage.live.com/y4me1w9pKnzpDF_Ht1wVZlLMBrrBRGns4WcrFP-Q09ajVKFsTdWNr8DhGrFZV00y4g5tcqY9EkdfVPro4qiA-6Od61hztm4QIPdHPYa2Z16s3rj32iy3X6gzNBOSDl2U2KHzmm2V7_4QDwmWDZqTjXJzszWXlUcXT8oWjl_KixmOXHZtM_SBVtFKIrx4Dzna1C1?width=600&height=600&cropmode=none", description: "", url:"industrial.url11" },
    { option: "mapindustrial12", name: "nameindustrial12", imgUrl: "https://dsm01pap002files.storage.live.com/y4mELtNHKVI4in8_qzeSjSEhU3yXbPFdZMMN62zxfYWy2vRHJMKBZoRr1n_WIFW3zsR5N6DmoxtCjldNh_1EWJqzaOHbaNDNi3SrGV2BOJ9jT7Q4SyirFoqHYBqi5djygBmB8LRxkXcEz3MvdkQrSMRbVbg0tKIOVDcehKcEfdFKOrBDP0mvsm-0hQkpoal4Ojg?width=600&height=600&cropmode=none", description: "", url:"industrial.url12" },
    { option: "mapindustrial13", name: "nameindustrial13", imgUrl: "https://dsm01pap002files.storage.live.com/y4mqHr4hUay78_pR6OsNPyC9UvWarrG9s3Ke8fE_pQTBQoZOq5cmgN4K_wdNbEKprrIXQO9Dea9hbnbmmXJk04FilGfCgopIACLnxKq-VWSPsn-x6-dZoY0m2R4wnOBUlQT9JSeV5RdfPlF5tSZ4_N_owfslv7MH80LlRhOd3BEwuz4n9evB0NO36MBf7pbdBN3?width=600&height=600&cropmode=none", description: "", url:"industrial.url13" },
    { option: "mapindustrial14", name: "nameindustrial14", imgUrl: "https://dsm01pap002files.storage.live.com/y4mbgQDhFIfs2Xli5saBnRtASGjXQRZ7m1rlSz0-4XQKjDeP91IoMzYbRouO4nAfTEAdKvLCX8XSku65SlpbKQhfwwYx78zuEG8GykgaREl4e8HgTSDP4itbhSK0chczjBJmCetj92xv4VU_skl6DedrWoN8jkPfgp_yDS6fl9K4Q_DBReJn71yUH_Gg6aT-jc2?width=600&height=600&cropmode=none", description: "", url:"industrial.url14" },
    { option: "mapindustrial15", name: "nameindustrial15", imgUrl: "https://dsm01pap002files.storage.live.com/y4m-dmzaW1B0ZptJgR8PviKZyFFKbSKFx95_yl9aGW-08CD0nCbH0iKqrjzGx2Ag80RSuVFIgllykmA6O19B7kXGqILc8FbSlueWVkCT3Aob59MnfZ9agGH2dsp3NwrQrzEeJceEGW3v7kGFfanalj1mKQAdPcq6nxX1c1ivCBNAxW19jM4A1ea12IQfj2Q4p-V?width=600&height=600&cropmode=none", description: "", url:"industrial.url15" },
];
  
  export default listCategoryIndustrial;