import img1 from '../../../assets/img/exol/products/industrial/gear-oils/1-G001-600x600.webp'
import img2 from '../../../assets/img/exol/products/industrial/gear-oils/2-G002-600x600.webp'
import img3 from '../../../assets/img/exol/products/industrial/gear-oils/3-G003-600x600.webp'
import img4 from '../../../assets/img/exol/products/industrial/gear-oils/4-G004-600x600.webp'
import img5 from '../../../assets/img/exol/products/industrial/gear-oils/5-G005-600x600.webp'
import img6 from '../../../assets/img/exol/products/industrial/gear-oils/6-G006-600x600.webp'
import img7 from '../../../assets/img/exol/products/industrial/gear-oils/7-G007-600x600.webp'
import img8 from '../../../assets/img/exol/products/industrial/gear-oils/8-G135-600x600.webp'
import img9 from '../../../assets/img/exol/products/industrial/gear-oils/9-G050-600x600.webp'
import img10 from '../../../assets/img/exol/products/industrial/gear-oils/10-G067-600x600.webp'
import img11 from '../../../assets/img/exol/products/industrial/gear-oils/11-G142-600x600.webp'
import img12 from '../../../assets/img/exol/products/industrial/gear-oils/12-G048-600x600.webp'
import img13 from '../../../assets/img/exol/products/industrial/gear-oils/13-G138-600x600.webp'
import img14 from '../../../assets/img/exol/products/industrial/gear-oils/14-G146-600x600.webp'
import img15 from '../../../assets/img/exol/products/industrial/gear-oils/15-G148-600x600.webp'


const listProductsGearOilsIn = [

    { option: "mapgearoilsin1", name: "namegearoilsin1", imgUrl: img1, description: "", url:"gearoilsin.url1"},
    { option: "mapgearoilsin2", name: "namegearoilsin2", imgUrl: img2, description: "", url:"gearoilsin.url2" },
    { option: "mapgearoilsin3", name: "namegearoilsin3", imgUrl: img3, description: "", url:"gearoilsin.url3" },
    { option: "mapgearoilsin4", name: "namegearoilsin4", imgUrl: img4, description: "", url:"gearoilsin.url4" },
    { option: "mapgearoilsin5", name: "namegearoilsin5", imgUrl: img5, description: "", url:"gearoilsin.url5" },
    { option: "mapgearoilsin6", name: "namegearoilsin6", imgUrl: img6, description: "", url:"gearoilsin.url6" },
    { option: "mapgearoilsin7", name: "namegearoilsin7", imgUrl: img7, description: "", url:"gearoilsin.url7" },
    { option: "mapgearoilsin8", name: "namegearoilsin8", imgUrl: img8, description: "", url:"gearoilsin.url8" },
    { option: "mapgearoilsin9", name: "namegearoilsin9", imgUrl: img9, description: "", url:"gearoilsin.url9" },
    { option: "mapgearoilsin10", name: "namegearoilsin10", imgUrl: img10, description: "", url:"gearoilsin.url10" },
    { option: "mapgearoilsin11", name: "namegearoilsin11", imgUrl: img11, description: "", url:"gearoilsin.url11" },
    { option: "mapgearoilsin12", name: "namegearoilsin12", imgUrl: img12, description: "", url:"gearoilsin.url12" },
    { option: "mapgearoilsin13", name: "namegearoilsin13", imgUrl: img13, description: "", url:"gearoilsin.url13" },
    { option: "mapgearoilsin14", name: "namegearoilsin14", imgUrl: img14, description: "", url:"gearoilsin.url14" },
    { option: "mapgearoilsin15", name: "namegearoilsin15", imgUrl: img15, description: "", url:"gearoilsin.url15" },

];
  
  export default listProductsGearOilsIn;