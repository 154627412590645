import React, { useContext } from "react";
import "../../assets/css/pages/ItemsBiobor/itemsBiobor.css";
import { Container, Row, Col } from "react-bootstrap";
// import listProductsAviation from "./listProductsAviation";
import { Products } from "../../components/Products";
import { useAuth0 } from "@auth0/auth0-react";
import BioborDcCetaneWinterImg from "../../assets/img/diesel/biobordccetanewinter/biobor_dc_cetane_winter.webp";
/* Imagenes PDF */
import sdsEn from "../../assets/img/diesel/biobordccetanewinter/biobordcpluswinter_safety_data_sheet_pdf.webp";
// import sdsEs from "../../assets/img/aviation/biobor-jf-aviation/product_bioborjf_SDS_ES.avif";
import specSheetEn from "../../assets/img/diesel/biobordccetanewinter/biobordcpluswinter_specification_sheets_pdf.webp";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
// import specSheetEs from "../../assets/img/aviation/biobor-jf-aviation/product_bioborjf_spec_sheet_ES.avif";

export const BioborDcWinter = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "biobordcwinter.title",
    defaultMessage:
      "Biobor DC Plus + Cetane Winter - Potencia el rendimiento invernal | OilFluid", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para description */

  const description = intl.formatMessage({
    id: "biobordcwinter.description",
    defaultMessage:
      "Mejora tu motor diésel con Biobor DC Plus y Cetane Winter, dos potentes aditivos. Biobor DC Plus previene el crecimiento de microorganismos y la acumulación de agua en el sistema de combustible, mientras que Cetane Winter mejora la capacidad de ignición y el rendimiento del diésel en climas fríos. Aumenta la eficiencia, protege tu motor y asegura un arranque confiable en todas las estaciones con Biobor DC Plus y Cetane Winter, los aditivos líderes en el mercado.", //Cambia Descripcion Pagina
  });

  /* Link PDF */

  const sdsEnLink =
    "https://drive.google.com/file/d/1jWWYZfWQBlRB9vSVkwSAsC482zycm0Uk/view";
  //   const sdsEsLink =
  //     "https://drive.google.com/file/d/1H_ArZU3DnxsK0AOGOLc7UMVprBs8754p/view";
  //   const specSheetEsLink =
  //     "https://drive.google.com/file/d/1-gZ56QbaoyjRCne0JSVD7wtAKSgi9Hhk/view";
  const specSheetEnLink =
    "https://drive.google.com/file/d/15WuDMbEPL-7Fv8F-FN7vIjCdlJn9jxJC/view";

  return (
    <section className="itemsBiobor" id="biobordcwinter">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-itemsBiobor">
        <div className="head-itemsBiobor-img"></div>
        <h1 className="head-itemsBiobor-text">
          <FormattedMessage
            id="biobordcwinter.h1"
            defaultMessage="Biobor DC Plus + Cetane Winter"
          />
        </h1>
      </div>
      <Container>
        <Row className="itemsBiobor-row1">
          <Col size={12} sm={8}>
            <h2>
              <FormattedMessage
                id="biobordcwinter.row1-col1-h2x1"
                defaultMessage="Acondicionador diésel y aditivo de rendimiento"
              />
            </h2>
            <ul>
              <li>
                <FormattedMessage
                  id="biobordcwinter.row1-col1-ulli1"
                  defaultMessage="Limpia los inyectores y previene los depósitos"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biobordcwinter.row1-col1-ulli2"
                  defaultMessage="Prolonga la vida útil del filtro de combustible"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biobordcwinter.row1-col1-ulli3"
                  defaultMessage="Eleva el cetano"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biobordcwinter.row1-col1-ulli4"
                  defaultMessage="Previene la gelificación y la formación de hielo en el combustible"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biobordcwinter.row1-col1-ulli5"
                  defaultMessage="Controla el agua y previene la corrosión"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biobordcwinter.row1-col1-ulli6"
                  defaultMessage="Mejora la eficiencia y restaura la energía"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biobordcwinter.row1-col1-ulli7"
                  defaultMessage="Lubrica inyectores y bombas de combustible"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biobordcwinter.row1-col1-ulli8"
                  defaultMessage="Previene la degradación del combustible y el engomado"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biobordcwinter.row1-col1-ulli9"
                  defaultMessage="Aumenta la estabilidad de almacenamiento"
                />
              </li>
            </ul>
            <h2>
              <FormattedMessage
                id="biobordcwinter.row1-col1-h2x2"
                defaultMessage="Descripción del Producto"
              />
            </h2>
            <p>
              <FormattedMessage
                id="biobordcwinter.row1-col1-p1"
                defaultMessage="Biobor DC + Cetane Winter es un acondicionador de combustible diésel multifuncional diseñado específicamente para climas fríos. Mejora la estabilidad, operatividad y rendimiento de los combustibles diésel de bajo contenido de azufre. Los componentes de Biobor DC + Cetane Winter ofrecen una excepcional estabilidad de combustible, mayor lubricidad, poderosa detergencia, control de agua/corrosión y protección contra la gelificación. Con su uso continuo, Biobor DC + Cetane mejora el rendimiento y la durabilidad del combustible diésel, al tiempo que protege y limpia los componentes esenciales del motor, reduce las emisiones y maximiza el ahorro de combustible. Es un tratamiento diésel rentable para grandes usuarios, brindando una protección completa incluso en concentraciones elevadas de tratamiento."
              />
            </p>
          </Col>
          <Col size={12} sm={4}>
            <img
              src={BioborDcCetaneWinterImg}
              alt="Biobor DC Plus + Cetane Winter: Acondicionador diésel y rendimiento con protección invernal"
            />
          </Col>
        </Row>
        <Row className="itemsBiobor-row2">
          <h2>
            {" "}
            <FormattedMessage
              id="biobordcwinter.row2-h2"
              defaultMessage="Recursos de Biobor DC Plus + Cetane Winter (PDF)"
            />
          </h2>
          <br />
          <div className="notice-to-register">
            {!isAuthenticated ? (
              <div className="notice1-to-register">
                <span>
                  ( <i className="fa-solid fa-lock fa-lock2"></i>
                </span>{" "}
                <span>
                  <FormattedMessage
                    id="notice.to-register1"
                    defaultMessage="Registrarte"
                  />{" "}
                  <button
                    onClick={loginWithRedirect}
                    className="noticetoregister-aqui"
                  >
                    <FormattedMessage
                      id="notice.to-register2"
                      defaultMessage="AQUI"
                    />
                  </button>{" "}
                  <FormattedMessage
                    id="notice.to-register3"
                    defaultMessage="para descargar cualquier informacion )"
                  />{" "}
                  )
                </span>
              </div>
            ) : (
              <div className="notice2-to-register">
                <span>
                  ( <i className="fa-solid fa-lock-open"></i>
                </span>
                <span>
                   {" "}
                  <FormattedMessage
                    id="notice.to-register4"
                    defaultMessage="Descarga cualquier informacion haciendo clic en la imagen )"
                  />{" "}
                  )
                </span>
              </div>
            )}
          </div>
          <br />
          <br />
        </Row>
        <div className="itemsBiobor-row3">
          <div className="container-lock-on-off">
            <h4>
              SDS (EN) <br />
              (Safety Data Sheet)
            </h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={sdsEn}
                  alt="Biobor DC Plus + Cetane Winter SDS - Safety Data Sheet PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={sdsEnLink} target="alt_">
                  <img
                    src={sdsEn}
                    alt="Biobor DC Plus + Cetane Winter SDS - Safety Data Sheet PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                      <p>Click para Descargar</p>
                    </div> */}
              </div>
            )}
          </div>
          <div className="container-lock-on-off">
            <h4>Specification Sheets (EN)</h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={specSheetEn}
                  alt="Biobor DC Plus + Cetane Winter Specification Sheets PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={specSheetEnLink} target="alt_">
                  <img
                    src={specSheetEn}
                    alt="Biobor DC Plus + Cetane Winter Specification Sheets PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                      <p>Click para Descargar</p>
                    </div> */}
              </div>
            )}
          </div>
        </div>
        {/* {!isAuthenticated ? (
              <Products /> ) : (
                      <Info1 />
                    )} */}

        {/* <Row className="biobor-jf-aviation-row4">
            <h2>¿Por qué usar Biobor JF Aviation?</h2>
            <p>
              Biobor JF ha sido el estándar mundial para el tratamiento del
              crecimiento microbiano en el combustible para aviones de aviación
              desde 1965. Originalmente desarrollado para su uso en las industrias
              de aviación comercial y militar, Biobor JF ha seguido brindando un
              historial de confiabilidad y eficacia comprobadas en numerosas
              industrias para más de 50 años. Para agregar a este impresionante
              pedigrí, Biobor JF es el único biocida de combustible de aviación
              recomendado por su nombre por numerosos fabricantes importantes de
              motores de turbina y fuselajes en todo el mundo.
            </p>
            <h4>El ÚNICO biocida de combustible disponible para la aviación:</h4>
            <p>
              Solo ha habido dos biocidas aprobados en la historia de los
              combustibles de aviación. Ahora, solo Biobor JF® está disponible.
              Después de varios incidentes de aeronavegabilidad causados por el
              otro biocida previamente aprobado que se retiró permanentemente del
              mercado, Biobor JF® es el único biocida de combustible disponible en
              todo el mundo.
            </p>
            <h4>La elección obvia para eliminar el crecimiento microbiano:</h4>
            <p>
              Cuando se discuten problemas relacionados con el combustible de
              aviación, se sabe ampliamente que el crecimiento microbiano es la
              causa más común de fallas en los motores relacionadas con el
              combustible. Intrínsecamente, el combustible de aviación y los
              tanques de combustible tienen la tendencia a acumular agua, ya sea
              por condensación del tanque, agua arrastrada por el suministro de
              combustible o simplemente por recibir un lote de combustible
              defectuoso. Los hum-bugs viven en la fase de agua de un tanque de
              combustible y se alimentan del combustible desarrollando esteras
              gelatinosas oscuras y viscosas. A medida que crecen estas colonias
              de bacterias y hongos (potencialmente a una tasa de duplicación cada
              20 minutos), pueden obstruir rápidamente los filtros de combustible
              y provocar acumulaciones excesivas de crecimiento. Además, sus
              productos de desecho son una de las principales causas de corrosión
              en tanques de combustible y componentes metálicos. Con el uso
              continuado de Biobor JF, se eliminará el crecimiento microbiano del
              sistema de combustible, lo que dará lugar a un sistema de
              combustible estéril, lo que extenderá la vida útil de los filtros de
              combustible y evitará problemas de corrosión.
            </p>
          </Row> */}
        <Products />
      </Container>
    </section>
  );
};
