import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import listProductsAviation from "./listProductsAviation";
import { Products } from "../../components/Products";
import { useAuth0 } from "@auth0/auth0-react";
import "../../assets/css/pages/ItemsBiobor/itemsBiobor.css";
/* Imagenes y PDF */
import BioborjfImg from '../../assets/img/aviation/biobor-jf-aviation/bioborjf_aviation_65k.webp';
import sdsEn from "../../assets/img/aviation/biobor-jf-aviation/bioborjf_SDS_safety_data_sheet_pdf.webp";
import specSheetEn from "../../assets/img/aviation/biobor-jf-aviation/bioborjf_specification_sheets_pdf.webp";
import sdsEs from "../../assets/img/aviation/biobor-jf-aviation/bioborjf_SDS_safety_data_sheet_pdf.webp"; /* Tiene Miniatura en Ingles */
import specSheetEs from "../../assets/img/aviation/biobor-jf-aviation/bioborjf_specification_sheets_pdf.webp"; /* Tiene Miniatura en Ingles */
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

export const BioborJfAviation = () => {
  /* Imagenes en Variables */
  
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  /* Link PDF */

  const sdsEnLink =
    "https://drive.google.com/file/d/1VOsDxicRFKoqO_WW2yARyfjikig72LhU/view";
  const sdsEsLink =
    "https://drive.google.com/file/d/1H_ArZU3DnxsK0AOGOLc7UMVprBs8754p/view";
  const specSheetEsLink =
    "https://drive.google.com/file/d/1-gZ56QbaoyjRCne0JSVD7wtAKSgi9Hhk/view";
  const specSheetEnLink =
    "https://drive.google.com/file/d/1vOLULRdF3wJyUJ55UMT5fcIroRuh3uXY/view";

  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

  /* Usando formatMessage para title */

  const { formatMessage } = useContext(IntlContext);

  const pageTitle = formatMessage({
    id: "bioborjfaviation.title",
    defaultMessage:
      "Biobor JF Aviation: el mejor biocida para combustible de aviación | OilFluid", //Cambia Titulo Pagina
  });

  /* Usando formatMessage para description */

  const description = intl.formatMessage({
    id: "bioborjfaviation.description",
    defaultMessage:
      "Descubre el rendimiento superior de Biobor JF Aviation, el principal biocida para combustible de aviación. Combate de manera efectiva el crecimiento microbiano, previene la corrosión y garantiza la calidad óptima del combustible para tus aviones. Confía en la solución que te ofrece Biobor, el mejor mantenimiento para tus equipos", //Cambia Descripcion Pagina
  });

  return (
    <section className="itemsBiobor" id="bioborjfaviation">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-itemsBiobor">
        <div className="head-itemsBiobor-img"></div>
        <h1 className="head-itemsBiobor-text">
          <FormattedMessage
            id="bioborjfaviation.h1"
            defaultMessage="Biobor JF Aviation"
          />
        </h1>
      </div>
      <Container>
        <Row className="itemsBiobor-row1">
          <Col size={12} sm={8}>
            <h2>
              <FormattedMessage
                id="biojfaviation.row1-colh2x1"
                defaultMessage="El ÚNICO biocida de combustible de aviación"
              />
            </h2>
            <ul>
              <li>
                <FormattedMessage
                  id="biojfaviation.row1-colulli1"
                  defaultMessage="Kills and prevents microbial growth in aviation fuels"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biojfaviation.row1-colulli2"
                  defaultMessage="Dual-phase: separates into water and fuel to effectively kill microbes"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biojfaviation.row1-colulli3"
                  defaultMessage="Prevents microbial corrosion and filter clogging"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biojfaviation.row1-colulli4"
                  defaultMessage="APPROVED for aviation use (OEM for turbines and airframes, FAA and IATA)"
                />
              </li>
              <li>
                <FormattedMessage
                  id="biojfaviation.row1-colulli5"
                  defaultMessage="Widely used by airlines, military aircraft, and private planes since 1965"
                />
              </li>
            </ul>
            <h2>
              <FormattedMessage
                id="biojfaviation.row1-colh2x2"
                defaultMessage="Descripción del Producto"
              />
            </h2>
            <p>
              <FormattedMessage
                id="biojfaviation.row1-col-p1"
                defaultMessage="Biobor® JF se formuló especialmente y se introdujo en 1965 para
              combatir y eliminar los microorganismos que utilizan hidrocarburos
              (Hum Bugs) en todas las aeronaves propulsadas por combustible para
              aviones y avgas. Los motores a reacción, turbohélice y
              alternativos, así como los fabricantes de aeronaves de todo el
              mundo, han llegado a recomendar este destacado aditivo en sus
              manuales de operación y mantenimiento. Biobor® JF es utilizado por
              aerolíneas, operadores de aviación general y militares de todo el
              mundo y está aceptado para su uso por la FAA y la IATA. Biobor JF
              es eficaz tanto en la fase de combustible como de agua de todos
              los combustibles, no es corrosivo y es seguro tanto para el
              personal operativo como para el medio ambiente. Biobor JF es el
              biocida de combustible de aviación original, más reconocido y
              acreditado del mundo."
              />
            </p>
          </Col>
          <Col size={12} sm={4}>
            <img
              src={BioborjfImg}
              alt="Biobor JF Aviation: aditivo aprobado por FAA e IATA que mata y previene el crecimiento microbiano en combustibles de aviación. Fácil de usar y seguro para todos los sistemas de combustible. Protege tu avión y evita reparaciones costosas."
            />
          </Col>
        </Row>
        <Row className="itemsBiobor-row2">
          <h2>
            <FormattedMessage
              id="biojfaviation.row2-h2"
              defaultMessage="Recursos de Biobor JF Aviation (PDF)"
            />
          </h2>
          <br />
          <div className="notice-to-register">
            {!isAuthenticated ? (
              <div className="notice1-to-register">
                <span>
                  ( <i className="fa-solid fa-lock fa-lock2"></i>
                </span>{" "}
                <span>
                  <FormattedMessage
                    id="notice.to-register1"
                    defaultMessage="Registrarte"
                  />{" "}
                  <button
                    onClick={loginWithRedirect}
                    className="noticetoregister-aqui"
                  >
                    <FormattedMessage
                      id="notice.to-register2"
                      defaultMessage="AQUI"
                    />
                  </button>{" "}
                  <FormattedMessage
                    id="notice.to-register3"
                    defaultMessage="para descargar cualquier informacion )"
                  />{" "}
                  )
                </span>
              </div>
            ) : (
              <div className="notice2-to-register">
                <span>
                  ( <i className="fa-solid fa-lock-open"></i>
                </span>
                <span>
                   {" "}
                  <FormattedMessage
                    id="notice.to-register4"
                    defaultMessage="Descarga cualquier informacion haciendo clic en la imagen )"
                  />{" "}
                  )
                </span>
              </div>
            )}
          </div>
          <br />
          <br />
        </Row>
        <div className="itemsBiobor-row3">
          <div className="container-lock-on-off">
            <h4>
              SDS (EN) <br />
              (Safety Data Sheet)
            </h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={sdsEn}
                  alt="Biobor JF SDS - Safety Data Sheet PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={sdsEnLink} target="alt_">
                  <img
                    src={sdsEn}
                    alt="Biobor JF SDS - Safety Data Sheet PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                  <p>Click para Descargar</p>
                </div> */}
              </div>
            )}
          </div>
          <div className="container-lock-on-off">
            <h4>Specification Sheets (EN)</h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={specSheetEn}
                  alt="Biobor JF - Specification Sheets PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={specSheetEnLink} target="alt_">
                  <img
                    src={specSheetEn}
                    alt="Biobor JF - Specification Sheets PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                  <p>Click para Descargar</p>
                </div> */}
              </div>
            )}
          </div>
          <div className="container-lock-on-off">
            <h4>
              SDS (ES) <br />
              (Hoja de Datos de Seguridad)
            </h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={sdsEs}
                  alt="Biobor JF SDS - Hija de Datos de Seguridad PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={sdsEsLink} target="alt_">
                  <img
                    src={sdsEs}
                    alt="Biobor JF SDS - Hija de Datos de Seguridad PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                  <p>Click para Descargar</p>
                </div> */}
              </div>
            )}
          </div>
          <div className="container-lock-on-off">
            <h4>Hoja de especificaciones (ES)</h4>
            {!isAuthenticated ? (
              <div className="containeroff-pdf">
                <img
                  src={specSheetEs}
                  alt="Biobor JF - Hoja de Especificaciones PDF"
                  className="containeroff-pdf-img"
                />
                <div className="containeroff-msj-pdf">
                  <div className="msj-lock-off">
                    <i className="fa-solid fa-lock"></i>
                    <p>
                      <FormattedMessage
                        id="lock.need-login"
                        defaultMessage="Necesitas Iniciar Sesion"
                      />
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="containeron-pdf">
                <a href={specSheetEsLink} target="alt_">
                  <img
                    src={specSheetEs}
                    alt="Biobor JF - Hoja de Especificaciones PDF"
                    className="containeron-pdf-img"
                  />
                </a>
                {/* <div class="biobor-jf-aviation-pdf-onmsj">
                  <p>Click para Descargar</p>
                </div> */}
              </div>
            )}
          </div>
        </div>
        {/* {!isAuthenticated ? (
          <Products /> ) : (
                  <Info1 />
                )} */}

        <Row className="itemsBiobor-row4">
          <h2>
            <FormattedMessage
              id="biojfaviation.row4-h2"
              defaultMessage="¿Por qué usar Biobor JF Aviation?"
            />
          </h2>
          <p>
            <FormattedMessage
              id="biojfaviation.row4-p1"
              defaultMessage="Biobor JF ha sido el estándar mundial para el tratamiento del
            crecimiento microbiano en el combustible para aviones de aviación
            desde 1965. Originalmente desarrollado para su uso en las industrias
            de aviación comercial y militar, Biobor JF ha seguido brindando un
            historial de confiabilidad y eficacia comprobadas en numerosas
            industrias para más de 50 años. Para agregar a este impresionante
            pedigrí, Biobor JF es el único biocida de combustible de aviación
            recomendado por su nombre por numerosos fabricantes importantes de
            motores de turbina y fuselajes en todo el mundo."
            />
          </p>
          <h4>
            <FormattedMessage
              id="biojfaviation.row4-h4x1"
              defaultMessage="El ÚNICO biocida de combustible disponible para la aviación:"
            />
          </h4>
          <p>
            <FormattedMessage
              id="biojfaviation.row4-p2"
              defaultMessage="Solo ha habido dos biocidas aprobados en la historia de los
            combustibles de aviación. Ahora, solo Biobor JF® está disponible.
            Después de varios incidentes de aeronavegabilidad causados por el
            otro biocida previamente aprobado que se retiró permanentemente del
            mercado, Biobor JF® es el único biocida de combustible disponible en
            todo el mundo."
            />
          </p>
          <h4>
            <FormattedMessage
              id="biojfaviation.row4-h4x2"
              defaultMessage="La elección obvia para eliminar el crecimiento microbiano:"
            />
          </h4>
          <p>
            <FormattedMessage
              id="biojfaviation.row4-p3"
              defaultMessage=" Cuando se discuten problemas relacionados con el combustible de
            aviación, se sabe ampliamente que el crecimiento microbiano es la
            causa más común de fallas en los motores relacionadas con el
            combustible. Intrínsecamente, el combustible de aviación y los
            tanques de combustible tienen la tendencia a acumular agua, ya sea
            por condensación del tanque, agua arrastrada por el suministro de
            combustible o simplemente por recibir un lote de combustible
            defectuoso. Los hum-bugs viven en la fase de agua de un tanque de
            combustible y se alimentan del combustible desarrollando esteras
            gelatinosas oscuras y viscosas. A medida que crecen estas colonias
            de bacterias y hongos (potencialmente a una tasa de duplicación cada
            20 minutos), pueden obstruir rápidamente los filtros de combustible
            y provocar acumulaciones excesivas de crecimiento. Además, sus
            productos de desecho son una de las principales causas de corrosión
            en tanques de combustible y componentes metálicos. Con el uso
            continuado de Biobor JF, se eliminará el crecimiento microbiano del
            sistema de combustible, lo que dará lugar a un sistema de
            combustible estéril, lo que extenderá la vida útil de los filtros de
            combustible y evitará problemas de corrosión."
            />
          </p>
        </Row>
        <Products />
      </Container>
    </section>
  );
};
