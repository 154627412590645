import img1 from '../../../assets/img/exol/products/industrial/compressor-oils/1-C001-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/compressor-oils/2-C002-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/compressor-oils/3-C003-600x600.webp';
import img4 from '../../../assets/img/exol/products/industrial/compressor-oils/4-C004-600x600.webp';
import img5 from '../../../assets/img/exol/products/industrial/compressor-oils/5-C005-600x600.webp';
import img6 from '../../../assets/img/exol/products/industrial/compressor-oils/6-C009-600x600.webp';
import img7 from '../../../assets/img/exol/products/industrial/compressor-oils/7-C061-600x600.webp';
import img8 from '../../../assets/img/exol/products/industrial/compressor-oils/8-c064-600x600.webp';
import img9 from '../../../assets/img/exol/products/industrial/compressor-oils/9-C052-600x600.webp';
import img10 from '../../../assets/img/exol/products/industrial/compressor-oils/10-C057-600x600.webp';
import img11 from '../../../assets/img/exol/products/industrial/compressor-oils/11-C068-600x600.webp';
import img12 from '../../../assets/img/exol/products/industrial/compressor-oils/12-C072-600x600.webp';


const listProductsCompressorOilsIn = [

  { option: "mapcompressoroilsin1", name: "namecompressoroilsin1", imgUrl: img1, description: "", url: "compressoroilsin.url1" },
  { option: "mapcompressoroilsin2", name: "namecompressoroilsin2", imgUrl: img2, description: "", url: "compressoroilsin.url2" },
  { option: "mapcompressoroilsin3", name: "namecompressoroilsin3", imgUrl: img3, description: "", url: "compressoroilsin.url3" },
  { option: "mapcompressoroilsin4", name: "namecompressoroilsin4", imgUrl: img4, description: "", url: "compressoroilsin.url4" },
  { option: "mapcompressoroilsin5", name: "namecompressoroilsin5", imgUrl: img5, description: "", url: "compressoroilsin.url5" },
  { option: "mapcompressoroilsin6", name: "namecompressoroilsin6", imgUrl: img6, description: "", url: "compressoroilsin.url6" },
  { option: "mapcompressoroilsin7", name: "namecompressoroilsin7", imgUrl: img7, description: "", url: "compressoroilsin.url7" },
  { option: "mapcompressoroilsin8", name: "namecompressoroilsin8", imgUrl: img8, description: "", url: "compressoroilsin.url8" },
  { option: "mapcompressoroilsin9", name: "namecompressoroilsin9", imgUrl: img9, description: "", url: "compressoroilsin.url9" },
  { option: "mapcompressoroilsin10", name: "namecompressoroilsin10", imgUrl: img10, description: "", url: "compressoroilsin.url10" },
  { option: "mapcompressoroilsin11", name: "namecompressoroilsin11", imgUrl: img11, description: "", url: "compressoroilsin.url11" },
  { option: "mapcompressoroilsin12", name: "namecompressoroilsin12", imgUrl: img12, description: "", url: "compressoroilsin.url12" },

];

  
  export default listProductsCompressorOilsIn;