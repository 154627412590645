const listProductsAviation = [

  { option: "mapaviation1", name: "Biobor JF Aviation", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/01/BioborJF-32oz-Aviation-Bottle-768x1355.png", description: "Microbicida para turbinas de aviación y combustibles para aviones", url:"aviation.url1"},
  { option: "mapaviation2", name: "Lubribor", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/01/Lubribor-16-Oz-162x300.png", description: "Lubricante de alta tecnología para combustibles bajos en azufre", url:"aviation.url2" },
  { option: "mapaviation3", name: "Turboline", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/01/Turboline-16-Oz-Bottle-138x300.png", description: "Estabilizador de combustible de alta temperatura y aditivo detergente", url:"aviation.url3" },
  { option: "mapaviation4", name: "Hum-bug Detector® kit", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/01/Hum-Bug-Kit-Bottle-Copy-258x300.png", description: "¡Advertencia temprana! detecta el crecimiento microbiano en el combustible", url:"aviation.url4" },
  { option: "mapaviation5", name: "FuelStat® Plus", imgUrl: "https://www.biobor.com/wp-content/uploads/2021/03/fuelstat-resinae-plus-450x454-1.png", description: "Detección fácil, precisa y rápida del crecimiento microbiano en el combustible", url:"aviation.url5" },

];

export default listProductsAviation;
