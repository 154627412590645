import React from "react";
import "../assets/css/info1.css";
import { Col, Container, Row } from "react-bootstrap";
import technoproduct from "../assets/img/front/biobor_family_products.webp";
import { FormattedMessage } from "react-intl";

export const Info1 = () => {
  return (
    <section className="info1">
      <Container className="container-bot-info1">
        <Row className="container1-info1">
          <h1>
            <FormattedMessage
              id="info1.h1"
              defaultMessage="Familia de Productos Biobor: Líderes en Rendimiento y Protección de Combustibles"
            />
          </h1>
          <p>
            <FormattedMessage
              id="info1.p1"
              defaultMessage="Un aditivo de combustible es un compuesto químico que se agrega al combustible con el propósito de mejorar su calidad, rendimiento y eficiencia. Estos aditivos pueden tener diferentes funciones, como limpiar y proteger los sistemas de combustible, mejorar la lubricación, prevenir la corrosión y reducir las emisiones contaminantes. Su objetivo es optimizar la combustión y el funcionamiento del motor, contribuyendo a un mejor desempeño del vehículo y a una mayor vida útil de sus componentes."
            />
          </p>
          <img src={technoproduct} alt="Techno Standard CORP" />
        </Row>
        <Row className="container2-info1">
          {/* <div className="container2-info1"> */}{" "}
          {/* No usar div entre row y col, sino no funciona las columnas */}
          <Col size={12} sm={6}>
            <h2>
              <FormattedMessage
                id="info1.col1h2x1"
                defaultMessage="¿Los aditivos, Ayuda para el mantenimiento de motores?"
              />
            </h2>
            <hr />
            <p>
              <FormattedMessage
                id="info1.col1px1"
                defaultMessage="Sí, los aditivos pueden contribuir al mejor mantenimiento de los motores. Estos compuestos químicos añadidos al combustible pueden limpiar y proteger los sistemas internos del motor, prevenir la acumulación de depósitos, mejorar la lubricación y reducir la corrosión. Al mantener un motor más limpio y eficiente, los aditivos pueden ayudar a prolongar la vida útil del motor y a mantener su rendimiento óptimo a lo largo del tiempo."
              />
            </p>
            <p>
              <FormattedMessage
                id="info1.col1px1-1"
                defaultMessage="Estos aditivos podrían considerarse parte del mantenimiento preventivo. El mantenimiento preventivo es una estrategia esencial para los técnicos en la industria de motores, ya que implica la realización de acciones planificadas y regulares para prevenir problemas y mantener el óptimo funcionamiento de los equipos. Al aplicar este enfoque, los técnicos pueden anticipar y abordar posibles fallos antes de que se conviertan en problemas costosos o disruptivos. En el caso de motores, el uso de aditivos en los combustibles es un ejemplo de mantenimiento preventivo, ya que contribuye a evitar la acumulación de depósitos, mejora la lubricación y reduce las emisiones. Esto no solo prolonga la vida útil del motor, sino que también asegura un rendimiento óptimo y una mayor eficiencia en el consumo de combustible. En última instancia, el mantenimiento preventivo no solo beneficia la confiabilidad de los equipos, sino que también optimiza el tiempo y los recursos de los técnicos al minimizar los problemas y reducir las interrupciones no planificadas."
              />
            </p>
            <h2>
              <FormattedMessage
                id="info1.col1h2x2"
                defaultMessage="¿Qué aditivo para combustible debería elegir?"
              />
            </h2>
            <hr />
            <p>
              <FormattedMessage
                id="info1.col1px2"
                defaultMessage="Hammonds Fuel Additives, Inc. es un destacado fabricante innovador en la industria de aditivos y biocidas para combustibles, brindando sus servicios a algunos de los mayores usuarios de combustible e industrias en todo el mundo. Nuestro producto estrella, Biobor®JF, se destaca como uno de los aditivos y biocidas para combustibles más confiables y ampliamente utilizados, diseñado para prevenir el crecimiento microbiano y preservar la calidad del combustible. Con más de 56 años de confiabilidad comprobada en la aviación, refinerías, terminales, flotas y usuarios finales de diésel, contamos con una extensa lista de aprobaciones de OEM y un uso efectivo continuo. En Hammonds, también ofrecemos una línea completa de aditivos para combustibles diésel, gasolina y aviación, satisfaciendo todas sus necesidades de calidad de combustible y garantizando que su equipo, vehículos, motores e instalaciones funcionen con máxima eficiencia gracias a los aditivos de la más alta calidad. Confíe en la experiencia y excelencia de Hammonds para mantener sus operaciones funcionando de manera óptima."
              />
            </p>
            <br />
          </Col>
          <Col size={12} sm={6}>
            <h2>
              <FormattedMessage
                id="info1.col2h2x1"
                defaultMessage="¿Los aditivos mejoran el rendimiento del motor?"
              />
            </h2>
            <hr />
            <p>
              <FormattedMessage
                id="info1.col2px1"
                defaultMessage="Si, los aditivos tienen el potencial de mejorar significativamente el rendimiento de un motor. Estas formulaciones químicas están diseñadas para potenciar varias propiedades de los combustibles y lubricantes. A través de su aplicación, es posible lograr un impacto positivo en el rendimiento del motor. A continuación, se presentan ejemplos claros de cómo los aditivos pueden influir positivamente en el rendimiento del motor, incluida la optimización de la eficiencia de la combustión, la reducción de la fricción interna, la mejora del octanaje de la gasolina, la disminución del ruido del motor, la protección contra el desgaste prematuro y la reducción de las emisiones contaminantes."
              />
            </p>
            <p>
              <FormattedMessage
                id="info1.col2px1-1"
                defaultMessage="El uso de aditivos simplifica el mantenimiento del motor, al reducir la necesidad de limpiezas y mantenimientos intensivos. Esto puede ahorrar tiempo y dinero tanto al técnico como al propietario del vehículo."
              />
            </p>
            <p>
              <FormattedMessage
                id="info1.col2px1-2"
                defaultMessage="Desde los aditivos específicos para la aviación, que garantizan la excelencia en los motores de aeronaves, hasta los aditivos para diesel y gasolina, que potencian la eficiencia y reducen el impacto ambiental, su impacto es innegable. Los aditivos en el diesel contribuyen directamente a mejoras en la combustión y eficiencia, generando ahorros significativos. Además, en el ámbito del petróleo y el gas, los aditivos desempeñan un papel esencial en la extracción, refinamiento y utilización de estos recursos."
              />
            </p>
            <h2>
              <FormattedMessage
                id="info1.col2h2x2"
                defaultMessage="Aditivos de primera calidad con un pedigrí inigualable"
              />
            </h2>
            <hr />
            <p>
              <FormattedMessage
                id="info1.col2px2"
                defaultMessage="Desde 1965, en Hammonds Fuel Additives, nos enorgullece haber obtenido una larga lista de recomendaciones y aprobaciones por parte de los principales usuarios de motores diésel, motores de turbina, estructuras de aeronaves y combustibles en todo el mundo. Nuestro producto estrella, Biobor®JF, se menciona específicamente en numerosos manuales de operación como la solución confiable para resolver problemas de contaminación del combustible. Además, es el ÚNICO biocida disponible y aprobado para su uso en combustible de aviación. Con una trayectoria comprobada, la línea completa de productos Biobor® continúa brindando confianza y rendimiento de primer nivel. Cuando busques un aditivo para combustible, no te conformes solo con palabras. Confía en nuestros productos probados y recomendados por fabricantes de equipos originales (OEM) en todo el mundo. Descubre cómo nuestros aditivos han sido testeados en condiciones reales, incluso a 30,000 pies de altura, garantizando un rendimiento óptimo para tus necesidades."
              />
            </p>
          </Col>
          {/* </div> */}
        </Row>
      </Container>
    </section>
  );
};
