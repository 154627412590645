import React, { useContext } from "react";
import "../assets/css/pages/accessories.css";
import { Container, Row, Col } from "react-bootstrap";
import listProductsAccessories from "./listProductsAccessories";
import { Products } from "../components/Products";
import { FormattedMessage, IntlContext, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";

export const Accessories = () => {

  const intl = useIntl(); //Cada ves que la web este gris, activar este comando

   /* Usando formatMessage para title */

   const { formatMessage } = useContext(IntlContext);

   const pageTitle = formatMessage({
     id: "accessories.title",
     defaultMessage:
       "Accesorios Biobor | OilFluid", //Cambia Titulo Pagina
   });
 
   /* Usando formatMessage para Description */
 
   const description = intl.formatMessage({
     id: "accessories.description",
     defaultMessage:
       "Los accesorios Biobor son complementos diseñados para potenciar los aditivos Biobor. Mejoran la aplicación y maximizan los beneficios de los aditivos. Incluyen sistemas de dosificación, kits de prueba y herramientas especializadas. Aseguran una experiencia de uso eficiente y conveniente.", //Cambia Descripcion Pagina
   });
 
  return (
    <section className="accessories">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="head-accessories">
        <div className="head-accessories-img"></div>
        <h1 className="head-accessories-text">
          <FormattedMessage
            id="accessories.h1"
            defaultMessage="BIOBOR ACCESORIOS"
          />
        </h1>
      </div>
      <Container>
        <Row className="product-accessories-row1">
          <p>
            <FormattedMessage
              id="accessories.row1-p1"
              defaultMessage="No te pierdas las novedades. Suscríbete a nuestra lista de correo
            para recibir actualizaciones exclusivas y enterarte de primera mano
            cuando los nuevos accesorios estén disponibles."
            />
          </p>
        </Row>
        <Row className="product-accessories-row2">
          <h2>
            <FormattedMessage
              id="accessories.row2-h2"
              defaultMessage="Productos Variados"
            />
          </h2>
          <Col>
            <div className="container-listProductsAccessories">
              {listProductsAccessories.map((product, index) => (
                <a
                  key={index}
                  href={intl.formatMessage({
                    id: product.url,
                    defaultMessage: product.url,
                  })}
                  className="product-link-accessories"
                >
                  <div className="map-listProductAccessories">
                    <h2>{product.name}</h2>
                    <img src={product.imgUrl} alt="Biobor JF Accessories" />
                    <p>
                      <FormattedMessage
                        id={product.option}
                        defaultMessage={product.option}
                      />
                    </p>
                  </div>
                </a>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="product-accessories-row3">
          {/* <h2></h2>
          <p></p> */}
          <h2>
            <span>
              <FormattedMessage
                id="accessories.row3.h2"
                defaultMessage="Ver Otros Productos"
              />
            </span>
          </h2>
        </Row>
        <Products />
      </Container>
    </section>
  );
};
