import img1 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/1-N002-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/2-N021-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/3-N015-600x600.webp';
import img4 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/4-N018-600x600.webp';
import img5 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/5-N019-600x600.webp';
import img6 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/6-N107-600x600.webp';
import img7 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/7-N020-600x600.webp';
import img8 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/8-N009-600x600.webp';
import img9 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/9-N016-600x600.webp';
import img10 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/10-N017-600x600.webp';
import img11 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/11-n188-600x600.webp';
import img12 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/12-N203-600x600.webp';
import img13 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/13-N197-600x600.webp';
import img14 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/14-N048-600x600.webp';
import img15 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/15-exol-montage1-600x600.webp';
import img16 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/16-exol-montage2-600x600.webp';
import img17 from '../../../assets/img/exol/products/industrial/neat-cutting-grinding-oils/17-exol-montage3-600x600.webp';


const listProductsNeatCuttingGrindingOilsIn = [

  { option: "mapneatcuttinggrindingoilsin1", name: "nameneatcuttinggrindingoilsin1", imgUrl: img1, description: "", url: "neatcuttinggrindingoilsin.url1" },
  { option: "mapneatcuttinggrindingoilsin2", name: "nameneatcuttinggrindingoilsin2", imgUrl: img2, description: "", url: "neatcuttinggrindingoilsin.url2" },
  { option: "mapneatcuttinggrindingoilsin3", name: "nameneatcuttinggrindingoilsin3", imgUrl: img3, description: "", url: "neatcuttinggrindingoilsin.url3" },
  { option: "mapneatcuttinggrindingoilsin4", name: "nameneatcuttinggrindingoilsin4", imgUrl: img4, description: "", url: "neatcuttinggrindingoilsin.url4" },
  { option: "mapneatcuttinggrindingoilsin5", name: "nameneatcuttinggrindingoilsin5", imgUrl: img5, description: "", url: "neatcuttinggrindingoilsin.url5" },
  { option: "mapneatcuttinggrindingoilsin6", name: "nameneatcuttinggrindingoilsin6", imgUrl: img6, description: "", url: "neatcuttinggrindingoilsin.url6" },
  { option: "mapneatcuttinggrindingoilsin7", name: "nameneatcuttinggrindingoilsin7", imgUrl: img7, description: "", url: "neatcuttinggrindingoilsin.url7" },
  { option: "mapneatcuttinggrindingoilsin8", name: "nameneatcuttinggrindingoilsin8", imgUrl: img8, description: "", url: "neatcuttinggrindingoilsin.url8" },
  { option: "mapneatcuttinggrindingoilsin9", name: "nameneatcuttinggrindingoilsin9", imgUrl: img9, description: "", url: "neatcuttinggrindingoilsin.url9" },
  { option: "mapneatcuttinggrindingoilsin10", name: "nameneatcuttinggrindingoilsin10", imgUrl: img10, description: "", url: "neatcuttinggrindingoilsin.url10" },
  { option: "mapneatcuttinggrindingoilsin11", name: "nameneatcuttinggrindingoilsin11", imgUrl: img11, description: "", url: "neatcuttinggrindingoilsin.url11" },
  { option: "mapneatcuttinggrindingoilsin12", name: "nameneatcuttinggrindingoilsin12", imgUrl: img12, description: "", url: "neatcuttinggrindingoilsin.url12" },
  { option: "mapneatcuttinggrindingoilsin13", name: "nameneatcuttinggrindingoilsin13", imgUrl: img13, description: "", url: "neatcuttinggrindingoilsin.url13" },
  { option: "mapneatcuttinggrindingoilsin14", name: "nameneatcuttinggrindingoilsin14", imgUrl: img14, description: "", url: "neatcuttinggrindingoilsin.url14" },
  { option: "mapneatcuttinggrindingoilsin15", name: "nameneatcuttinggrindingoilsin15", imgUrl: img15, description: "", url: "neatcuttinggrindingoilsin.url15" },
  { option: "mapneatcuttinggrindingoilsin16", name: "nameneatcuttinggrindingoilsin16", imgUrl: img16, description: "", url: "neatcuttinggrindingoilsin.url16" },
  { option: "mapneatcuttinggrindingoilsin17", name: "nameneatcuttinggrindingoilsin17", imgUrl: img17, description: "", url: "neatcuttinggrindingoilsin.url17" },

];

  
  export default listProductsNeatCuttingGrindingOilsIn;