import img1 from '../../../assets/img/exol/products/industrial/ancillary-products/1-D003-600x600.webp';
import img2 from '../../../assets/img/exol/products/industrial/ancillary-products/2-D002-600x600.webp';
import img3 from '../../../assets/img/exol/products/industrial/ancillary-products/3-w003-granules-alt-600x600.webp';
import img4 from '../../../assets/img/exol/products/industrial/ancillary-products/4-P002-600x600.webp';
import img5 from '../../../assets/img/exol/products/industrial/ancillary-products/5-P036-600x600.webp';
import img6 from '../../../assets/img/exol/products/industrial/ancillary-products/6-w003-600x600.webp';
import img7 from '../../../assets/img/exol/products/industrial/ancillary-products/7-L153-600x600.webp';
import img8 from '../../../assets/img/exol/products/industrial/ancillary-products/8-L050-600x600.webp';
import img9 from '../../../assets/img/exol/products/industrial/ancillary-products/9-L057-600x600.webp';
import img10 from '../../../assets/img/exol/products/industrial/ancillary-products/10-l027-600x600.webp';
import img11 from '../../../assets/img/exol/products/industrial/ancillary-products/11-l026-600x600.webp';
import img12 from '../../../assets/img/exol/products/industrial/ancillary-products/12-p061-600x600.webp';
import img13 from '../../../assets/img/exol/products/industrial/ancillary-products/13-l249-600x600.webp';
import img14 from '../../../assets/img/exol/products/industrial/ancillary-products/14-p062-600x600.webp';
import img15 from '../../../assets/img/exol/products/industrial/ancillary-products/15-l167-600x600.webp';

const listProductsAncillaryProductsIn = [

  { option: "mapancillaryproductsin1", name: "nameancillaryproductsin1", imgUrl: img1, description: "", url: "ancillaryproductsin.url1" },
  { option: "mapancillaryproductsin2", name: "nameancillaryproductsin2", imgUrl: img2, description: "", url: "ancillaryproductsin.url2" },
  { option: "mapancillaryproductsin3", name: "nameancillaryproductsin3", imgUrl: img3, description: "", url: "ancillaryproductsin.url3" },
  { option: "mapancillaryproductsin4", name: "nameancillaryproductsin4", imgUrl: img4, description: "", url: "ancillaryproductsin.url4" },
  { option: "mapancillaryproductsin5", name: "nameancillaryproductsin5", imgUrl: img5, description: "", url: "ancillaryproductsin.url5" },
  { option: "mapancillaryproductsin6", name: "nameancillaryproductsin6", imgUrl: img6, description: "", url: "ancillaryproductsin.url6" },
  { option: "mapancillaryproductsin7", name: "nameancillaryproductsin7", imgUrl: img7, description: "", url: "ancillaryproductsin.url7" },
  { option: "mapancillaryproductsin8", name: "nameancillaryproductsin8", imgUrl: img8, description: "", url: "ancillaryproductsin.url8" },
  { option: "mapancillaryproductsin9", name: "nameancillaryproductsin9", imgUrl: img9, description: "", url: "ancillaryproductsin.url9" },
  { option: "mapancillaryproductsin10", name: "nameancillaryproductsin10", imgUrl: img10, description: "", url: "ancillaryproductsin.url10" },
  { option: "mapancillaryproductsin11", name: "nameancillaryproductsin11", imgUrl: img11, description: "", url: "ancillaryproductsin.url11" },
  { option: "mapancillaryproductsin12", name: "nameancillaryproductsin12", imgUrl: img12, description: "", url: "ancillaryproductsin.url12" },
  { option: "mapancillaryproductsin13", name: "nameancillaryproductsin13", imgUrl: img13, description: "", url: "ancillaryproductsin.url13" },
  { option: "mapancillaryproductsin14", name: "nameancillaryproductsin14", imgUrl: img14, description: "", url: "ancillaryproductsin.url14" },
  { option: "mapancillaryproductsin15", name: "nameancillaryproductsin15", imgUrl: img15, description: "", url: "ancillaryproductsin.url15" },

];


export default listProductsAncillaryProductsIn;
