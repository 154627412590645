import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import emailjs from "@emailjs/browser";
import "./askInformationProducts.css";
import { FormattedMessage, useIntl } from "react-intl";

export const AskInformationProducts = ({ showModal, onHide }) => {

  // EmailJS

  const serviceId = process.env.REACT_APP_EMAILJS2_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS2_TEMPLATE_ID;
  const userId = process.env.REACT_APP_EMAILJS2_USER_ID;

  const intl = useIntl();

  const [show, setShow] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(serviceId, templateId, e.target, userId)
      .then((result) => {
        console.log(result.text);
        setEnviado(true);
        setError(false);
      })
      .catch((error) => {
        console.log(error.text);
        setError(true);
      });
  };

  const handleClose = () => {
    onHide();
    setEnviado(false);
    setError(false);
    setSelectedBrandModal("");
  };

  /* PlaceHolder */

  const placeHolderName = intl.formatMessage({
    id: "askinformationproducts.placeholder-name",
    defaultMessage: "Tu Nombre Aquí",
  });

  const placeHolderEmail = intl.formatMessage({
    id: "askinformationproducts.placeholder-email",
    defaultMessage: "nombre@ejemplo.com",
  });

  const placeHolderMessage = intl.formatMessage({
    id: "askinformationproducts.placeholder-message",
    defaultMessage: "Escribe tu Mensaje",
  });

  // Imagenes de Marcas para el Modal

  const [selectedBrandModal, setSelectedBrandModal] = useState(""); //Se Guarda La Marca Seleccionada

  const selectingModalEvent = (event) => {
    //Obteniendo Valor con handleBrandChange
    setSelectedBrandModal(event.target.value);
  };

  //Link de Imagenes de las Marcas
  // Las Marcas que terminan en 2 son respaldo

  const brandModalImages = {
    "1. 76 Lubricants":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/b/76-lubricant-320w_1596475838__18383.original.png",
    "1. 76 Lubricants2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21901&authkey=%21ACpknsyr14k2w5s&width=320&height=94",
    "2. AeroShell":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/190x250/u/aeroshell%20logo_1646851882__73909.original.jpg",
    "2. AeroShell2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21900&authkey=%21AE68Rh6vdMpKCnQ&width=190&height=56",
    "3. Aloetizer":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/w/aloetizer-320w_1596475847__15857.original.png",
    "3. Aloetizer2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21902&authkey=%21AJV5qg9aHZatOrU&width=320&height=150",
    "4. American Lubrication":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/j/american-lubricant-320w_1596475851__17819.original.png",
    "4. American Lubrication2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21903&authkey=%21ALm5DhMRjlUQYag&width=320&height=46",
    "5. American Refining Group":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/190x250/n/arg%20logo_1671637849__87990.original.png",
    "5. American Refining Group2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21905&authkey=%21APY-rAlCaSuPOZY&width=190&height=60",
    "6. Arconol": "https://i.imgur.com/mZYuah6.png",
    "6. Arconol2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211070&authkey=%21AD2xEH4xVD6lqGw&width=320&height=94",
    "7. Autoguard":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/q/autoguard-320w_1596475856__85947.original.png",
    "7. Autoguard2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21904&authkey=%21AGYR_AonOKYmehQ&width=320&height=118",
    "8. Bear Paw":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/c/bearpaw-320w_1596475860__00277.original.png",
    "8. Bear Paw2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21907&authkey=%21AChbIeJPW64zi3Y&width=320&height=142",
    "9. Berkebile":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/c/berkebile-320w_1596475864__08298.original-min_1627667136.original.png",
    "9. Berkebile2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21906&authkey=%21ACwOn6g36NZaiig&width=320&height=334",
    "10. Biobor": "https://i.imgur.com/defzcet.png",
    "10. Biobor2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211082&authkey=%21AAhXEcCjG8E764I&width=320&height=94",
    "11. Black Magic":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/190x250/u/black%20magic-logo_1572445369__00702.original.png",
    "11. Black Magic2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21908&authkey=%21AFDOxxfLBeGieMM&width=190&height=79",
    "12. BlueSky Lubricants":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/z/bluesky-320w_1596558003__25165.original.png",
    "12. BlueSky Lubricants2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21910&authkey=%21AE2Ky2UUkTBoF4k&width=320&height=101",
    "13. Carwell Products":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/u/carwell-320w_1596558009__83054.original-min_1627667154.original.png",
    "13. Carwell Products2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21909&authkey=%21AEEA5SMb9U3BsB8&width=320&height=117",
    "14. Castrol":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/k/castrol-320w_1596558014__88919.original.png",
    "14. Castrol2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21911&authkey=%21AOsVoi-EPcsn5dI&width=320&height=88",
    "15. Chemcoat": "https://i.imgur.com/xZ46UyL.png",
    "15. Chemcoat2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211071&authkey=%21AHqMak80L9uWtVw&width=320&height=94",
    "16. Chevron":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/b/chevron-320w_1596558024__03210.original.png",
    "16. Chevron2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21913&authkey=%21AM6DEEiIQALe6ww&width=320&height=356",
    "17. CITGO": "https://i.imgur.com/h6A0ERx.png",
    "17. CITGO2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211072&authkey=%21ABTyWB2pGJBoiS8&width=320&height=94",
    "18. Cim-Tek":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/i/cimtek-320w_1596558030__82281.original.png",
    "18. Cim-Tek2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21921&authkey=%21AFX4Pz72pIuaUTk&width=320&height=133",
    "19. Coastal":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/e/coastal-320w_1596558034__86773.original.png",
    "19. Coastal2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21914&authkey=%21AOK6Aacq-w-Uso8&width=320&height=83",
    "20. Colonial Chemical Company":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/f/colonial-chemical-company-320w_1596558041__15938.original.png",
    "20. Colonial Chemical Company2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21916&authkey=%21AKn_AEG33s72jOw&width=320&height=148",
    "21. ComStar":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/z/comstar-320w_1596558046__12032.original.png",
    "21. ComStar2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21915&authkey=%21AO9oXQm8YfYHZ_8&width=320&height=122",
    "22. CRC":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/w/crc-320w_1596558053__05759.original.png",
    "22. CRC2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21918&authkey=%21AKNY9wiXvmYrAaE&width=320&height=158",
    "23. Daubert":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/l/daubertsafecote-320_1599765310__81489.original.png",
    "23. Daubert2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21919&authkey=%21AMbGtNXkRoS_HQE&width=320&height=74",
    "24. DOW":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/o/dow-320w_1596558065__71521.original.png",
    "24. DOW2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21920&authkey=%21AHdLhbWJ9sIkJGc&width=320&height=111",
    "25. Engis": "https://i.imgur.com/Y8DmMhe.png",
    "25. Engis2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211073&authkey=%21AIikvEFu3v9HhaQ&width=320&height=94",
    "26. Exol": "https://i.imgur.com/XeyWcIs.png",
    "26. Exol2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211083&authkey=%21APM6vPrYLAjaOoU&width=320&height=94",
    "27. Fill-Rite":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/e/fill-rite-320w_1596558070__39492.original.png",
    "27. Fill-Rite2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21922&authkey=%21AKWrzKxK_VbggOo&width=320&height=78",
    "28. Filtroil":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/n/filtroil-320w_1596558075__33488.original.png",
    "28. Filtroil2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21924&authkey=%21AEhxkmWyVaktUqQ&width=320&height=165",
    "29. Fluid Film":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/x/fluid-film-320w_1599765316__68665.original.png",
    "29. Fluid Film2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21925&authkey=%21AGvBouAS2tBTkLw&width=320&height=316",
    "30. FUCHS":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/z/fuchs-oil-logo_1658506059__63013.original.png",
    "30. FUCHS2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21923&authkey=%21AKSlzRM0co9uMF0&width=320&height=259",
    "31. Gasoila":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/x/gasoila_logo_1465479969__55997.original.gif",
    "31. Gasoila2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21927&authkey=%21AGFawKQR7vuMwLA&width=320&height=98",
    "32. GOJO":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/k/gojo-320w_1596558083__83176.original.png",
    "32. GOJO2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21928&authkey=%21AL8V31R4c0-wzhM&width=320&height=108",
    "33. Graco":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/n/graco-320_1599765334__45363.original.png",
    "33. Graco2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21926&authkey=%21ACp4Uxh4ZmjNxvA&width=320&height=346",
    "34. Gulf Lubricants":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/190x250/l/gulf%20lubricants%20logo_1673979607__14826.original.png",
    "34. Gulf Lubricants2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21932&authkey=%21AENba7LqTdT-RJI&width=190&height=190",
    "35. Gumout":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/d/gumout-320_1599765340__30608.original.png",
    "35. Gumout2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21929&authkey=%21ACMXwaw9WoDMsEY&width=320&height=165",
    "36. Gunk":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/f/gunk-320_1599765345__29259.original.png",
    "36. Gunk2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21930&authkey=%21ALZmrygAOrInbpo&width=320&height=179",
    "37. Hot Shot's Secret":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/n/hot-shots-320_1599765351__83495.original.png",
    "37. Hot Shot's Secret2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21931&authkey=%21AEBVrii5bJlerC0&width=320&height=159",
    "38. Howes Lubricator":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/o/howes-320_1599765357__05720.original.png",
    "38. Howes Lubricator2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21934&authkey=%21AAiSs1pbtm5XYfY&width=320&height=119",
    "39. Husky":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/e/husky-320_1599765362__71817.original.png",
    "39. Husky2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21935&authkey=%21AOd9avsoUrqkosA&width=320&height=123",
    "40. Ingredi":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/k/ingredi_logo_1572529338__53317.original.jpg",
    "40. Ingredi2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21933&authkey=%21ANk0NXwEr1ZzYaI&width=320&height=90",
    "41. Itasca":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/g/itasca-320_1599765368__17926.original.png",
    "41. Itasca2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21937&authkey=%21ALMTHlOZVbrUAJM&width=320&height=125",
    "42. Jax":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/k/jax-320_1599765373__62143.original.png",
    "42. Jax2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21943&authkey=%21ANBhLpKiOVoZjD4&width=320&height=215",
    "43. Jet-Lube":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/i/jet-lube320_1599765378__43888.original.png",
    "43. Jet-Lube2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21936&authkey=%21AK8MsMaOGSzsKXQ&width=320&height=76",
    "44. Johnsen's":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/s/johnsens-320_1599765383__19101.original.png",
    "44. Johnsen's2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21945&authkey=%21ACEPhKrNF2PU8cg&width=320&height=138",
    "45. K100":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/h/k100-320_1599765393__94475.original.png",
    "45. K1002":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21938&authkey=%21AACGufZw8vWugb8&width=320&height=194",
    "46. Kendall":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/190x250/c/kendall%20logo%20final%20edit_1645627286__70193.original.jpg",
    "46. Kendall2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21939&authkey=%21AC396l62nzeHvPQ&width=190&height=52",
    "47. KLUBER LUBRICATION": "https://i.imgur.com/099X2GQ.png",
    "47. KLUBER LUBRICATION2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211074&authkey=%21AFWXK4xlPYfIVtQ&width=320&height=94",
    "48. Lincoln":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/q/lincoln-320_1599765403__62487.original.png",
    "48. Lincoln2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21940&authkey=%21AMGIGlZ5wDuE5pc&width=320&height=120",
    "49. Liqui-Cult":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/o/liqui-cult-320_1599765410__15449.original.png",
    "49. Liqui-Cult2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21941&authkey=%21AIMfSf18sVDCBUk&width=320&height=69",
    "50. Lubriguard":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/r/lubriguard320_1599765569__51959.original.png",
    "50. Lubriguard2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21942&authkey=%21APUkl0uHNuzvc-I&width=320&height=69",
    "51. Lucas Oil":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/k/lucas-oil-320_1599765574__52499.original.png",
    "51. Lucas Oil2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21953&authkey=%21AB32hfhU_zN-LX8&width=320&height=277",
    "52. LUBRIPLATE": "https://i.imgur.com/inY2v6W.png",
    "52. LUBRIPLATE2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211075&authkey=%21AESELM6NKcxuiIg&width=320&height=94",
    "53. Mobil":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/m/mobil_logo_1464288213__44190.original.png",
    "53. Mobil2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21944&authkey=%21AN_WCj5T_a-Ob0I&width=320&height=101",
    "54. Morse":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/stencil/0eb650f0-f291-013b-3f0f-7a3874f37388/e/c5b0e180-5424-0139-d166-2a549753eb8b/img/BrandDefault.gif",
    "54. Morse2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21946&authkey=%21AM95bcK47B8Zb-4&width=100&height=50",
    "55. Mountain Productions":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/m/mtn-320_1599765580__31501.original.png",
    "55. Mountain Productions2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21947&authkey=%21AGpnAHNl03zTMBI&width=320&height=166",
    "56. MILES Lubricants": "https://i.imgur.com/hNAWZPC.png",
    "56. MILES Lubricants2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211076&authkey=%21ABr1VEkUj0ILT98&width=320&height=94",
    "57. Nox-Rust":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/g/nox-rust-320_1599765587__84824.original.png",
    "57. Nox-Rust2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21949&authkey=%21AH45OWrRtSFX6_I&width=320&height=61",
    "58. Oil Eater":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/x/oil_eater_logo_1683573440__42051.original.jpg",
    "58. Oil Eater2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21948&authkey=%21AB72hWI_KETLKhQ&width=320&height=268",
    "59. OIL-DRI":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/r/oil-dri_1671638182__60298.original.png",
    "59. OIL-DRI2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21950&authkey=%21ACydv53liF5BzhQ&width=320&height=139",
    "60. OPW":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/m/opw-320_1599765592__21125.original.png",
    "60. OPW2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21951&authkey=%21AMPrKRsL0-1OwLo&width=320&height=68",
    "61. Peak":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/m/peak-320_1599765597__33657.original.png",
    "61. Peak2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21963&authkey=%21ACh18SKzRPQdGc4&width=320&height=171",
    "62. Pennzoil":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/u/pennzoil-320_1599765602__91055.original.png",
    "62. Pennzoil2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21952&authkey=%21APxYfios-vo2Qro&width=320&height=160",
    "63. PENRAY":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/t/penray_1635885010__96308.original.png",
    "63. PENRAY2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21954&authkey=%21ANl88y4ZA-JcFBM&width=320&height=100",
    "64. Permatex":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/m/permatex-320_1599765607__83979.original.png",
    "64. Permatex2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21955&authkey=%21ACxIimTTLQ3U1GQ&width=320&height=77",
    "65. Phillips 66 Lubricants":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/f/phillips-320_1599765620__29626.original.png",
    "65. Phillips 66 Lubricants2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21956&authkey=%21ALEXA1AgyxR2msM&width=320&height=320",
    "66. Phillips 66 X/C Aviation":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/d/phillips-avation-320_1599765626__16812.original.png",
    "66. Phillips 66 X/C Aviation2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21958&authkey=%21AEIusDevQfE8azs&width=320&height=87",
    "67. Polyguard":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/y/polyguard320_1599765632__15201.original.png",
    "67. Polyguard2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21957&authkey=%21ACQXh8KTF2oZfjQ&width=320&height=83",
    "68. PolySafe Products":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/o/polysafe320_1599765637__86416.original.png",
    "68. PolySafe Products2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21960&authkey=%21AELA-7D3YATRfmA&width=320&height=275",
    "69. Power Service":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/m/power-service-logo_1464288292__79386.original.jpg",
    "69. Power Service2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21959&authkey=%21ANmMfPrq8L_ZNo4&width=320&height=102",
    "70. Prestone":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/r/prestone-320_1599765644__87412.original.png",
    "70. Prestone2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21961&authkey=%21AEITxfpTkegdmHo&width=320&height=107",
    "71. Promedic": "https://i.imgur.com/d3ek3Gp.png",
    "71. Promedic2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211077&authkey=%21ALEYE6C9tLql2Ds&width=320&height=94",
    "72. Pure Asphalt":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/x/pureasphalt-320_1599765649__33703.original.png",
    "72. Pure Asphalt2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21970&authkey=%21ABbLVlI-2PKtbLs&width=320&height=82",
    "73. Purple Power":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/q/purple-power-320_1599765654__29620.original.png",
    "73. Purple Power2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21965&authkey=%21ACOza5d6F8CvgPY&width=320&height=271",
    "74. RADCO":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/w/radco-320_1599765659__22196.original.png",
    "74. RADCO2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21964&authkey=%21ABnTBAeY1WBRS10&width=320&height=158",
    "75. Safecote":
      "https://safecote.com/assets/images/safecote-logo-footer.png",
    "75. Safecote2":
      "https://safecote.com/assets/images/safecote-logo-footer.png",
    "76. Sheboygan Paint Company":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/190x250/c/spc%20logo_1671644578__70411.original.jpg",
    "76. Sheboygan Paint Company2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21966&authkey=%21AFt4-Ax_n_CfGSE&width=190&height=64",
    "77. Shell":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/m/shell-logo_114x120_1631720912__14301.original.png",
    "77. Shell2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21967&authkey=%21AF-mM6uRvjKWHHg&width=320&height=337",
    "78. Shell GADUS": "https://i.imgur.com/woJVAa4.png",
    "78. Shell GADUS2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211078&authkey=%21AOs1jT5Kx1rdtKA&width=320&height=94",
    "79. Simple Green":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/b/simple-green-320_1599765673__79575.original.png",
    "79. Simple Green2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21977&authkey=%21AJAE5KdAGA2vs40&width=320&height=214",
    "80. Simpurity":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/o/simpurity-320_1599765679__72898.original.png",
    "80. Simpurity2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21969&authkey=%21AO23-quPLkstTkM&width=320&height=233",
    "81. SKF": "https://i.imgur.com/fhovBMj.png",
    "81. SKF2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211079&authkey=%21AAPw8Rs_3-nU5T0&width=320&height=94",
    "82. Spray Nine":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/w/spray-nine-320_1599765684__96577.original.png",
    "82. Spray Nine2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21972&authkey=%21ADKhpVXMOoAna68&width=320&height=226",
    "83. Star Brite":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/j/star-brite-logo_1465481331__59542.original.jpg",
    "83. Star Brite2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21971&authkey=%21AC3faEXOFlU48HE&width=320&height=98",
    "84. STP":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/c/stp-320_1599765689__94831.original.png",
    "84. STP2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21974&authkey=%21AHHJWNclZdaKfSQ&width=320&height=210",
    "85. Sunoco Lubricants":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/190x250/p/sunoco%20logo%20final%20edit_1645626469__73365.original.jpg",
    "85. Sunoco Lubricants2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21973&authkey=%21AJfo8fCBKWSFpl0&width=190&height=120",
    "86. Sunoco Race Fuels":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/190x250/j/sunoco%20logo%20final%20edit_1645626462__54541.original.jpg",
    "86. Sunoco Race Fuels2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21975&authkey=%21AD3o0JAGMxnjdK0&width=190&height=120",
    "87. Super S":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/h/super-s-320_1599765707__40354.original.png",
    "87. Super S2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21976&authkey=%21ANHbE0qxN57dJwQ&width=320&height=238",
    "88. SUMMIT": "https://i.imgur.com/N0Xv76E.png",
    "88. SUMMIT2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%211081&authkey=%21AEo5ZX0a2DeXCEQ&width=320&height=94",
    "89. Tectyl":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/a/tectyl-320_1599765711__38457.original.png",
    "89. Tectyl2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21981&authkey=%21ANJM6k-59FZhIr8&width=320&height=99",
    "90. TerraCair":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/w/terracair-320_1599765717__04072.original.png",
    "90. TerraCair2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21978&authkey=%21AAbwpMKtCh6iUSc&width=320&height=76",
    "91. The Blaster Corporation":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/v/blaster_logo_1522091711__23058.original.png",
    "91. The Blaster Corporation2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21980&authkey=%21ANTYii62GJ1UJ3s&width=320&height=230",
    "92. The Dri-Rite Company":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/r/dri-rite-premium-absorbant-18_1464288621__83843.original.jpg",
    "92. The Dri-Rite Company2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21979&authkey=%21AJc1bZDvZSO_XLI&width=320&height=320",
    "93. Undercoating In A Can":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/j/uciac-logo-stroke_1599827318__91941.original.png",
    "93. Undercoating In A Can2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21983&authkey=%21APDk3Q6KZho64bI&width=320&height=89",
    "94. United":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/v/uoc-logo-web_1687275073__01492.original.gif",
    "94. United2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21985&authkey=%21AFknn2I8QWO-lQw&width=320&height=209",
    "95. Valvoline":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/u/512px-valvoline_company_logo.svg_1687275059__86990.original.png",
    "95. Valvoline2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21982&authkey=%21ABWsYl2duUBw8P4&width=320&height=218",
    "96. WD-40":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/w/wd-40-320_1599765734__15998.original.png",
    "96. WD-402":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21988&authkey=%21AMkjT9EsCMOtdHQ&width=320&height=221",
    "97. Welcome":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/s/welcome-320_1599765739__43245.original.png",
    "97. Welcome2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21984&authkey=%21ANUSeh0l5GmbJsQ&width=320&height=117",
    "98. Wolf's Head":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/l/wolfs-head-320_1599765745__63670.original.png",
    "98. Wolf's Head2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21987&authkey=%21AEpaxzMngZxoBWA&width=320&height=157",
    "99. Xenon":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/190x250/u/xenon%20logo_1628704773__53974.original.jpg",
    "99. Xenon2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21986&authkey=%21AL2i7Uu7k2EfdmU&width=190&height=190",
    "100. Z-Tech":
      "https://cdn11.bigcommerce.com/s-eqgv9kc7pj/images/stencil/320w/x/ztech-320_1599765751__18658.original.png",
    "100. Z-Tech2":
      "https://onedrive.live.com/embed?resid=B8D186879CDA8A45%21989&authkey=%21AEMoWBcx7nFoc18&width=320&height=146",
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered={true} backdrop="static">
        {!enviado && !error ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                <FormattedMessage
                  id="askinformationproducts.title"
                  defaultMessage="¿Estás interesado en este producto?"
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <FormattedMessage
                  id="askinformationproducts.modalbody-p1"
                  defaultMessage="¿Necesitas más información, tienes alguna duda, requieres una
                cotización o cualquier otra pregunta sobre nuestros productos?"
                />
              </p>
              <p>
                <FormattedMessage
                  id="askinformationproducts.modalbody-p2"
                  defaultMessage="Rellena la solicitud de información de manera rápida y sencilla,
                estaremos encantados de responder lo más pronto posible."
                />
              </p>
              <hr />
              <h4>
                <FormattedMessage
                  id="askinformationproducts.modalbody-h4"
                  defaultMessage="Solicitud de Información"
                />
              </h4>
              <hr />
              <Form onSubmit={sendEmail}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    <i className="fa-solid fa-circle fa-2xs"></i>
                    {" "}
                    <FormattedMessage
                      id="askinformationproducts.form-name"
                      defaultMessage="Nombre"
                    />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={placeHolderName}
                    autoFocus
                    name="name"
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>
                    <i className="fa-solid fa-circle fa-2xs"></i>
                    {" "}
                    <FormattedMessage
                      id="askinformationproducts.form-email"
                      defaultMessage="Correo Electrónico"
                    />
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder={placeHolderEmail}
                    autoFocus
                    name="email"
                    required
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlSelect1"
                >
                  <Form.Label>
                    <i className="fa-solid fa-circle fa-2xs"></i>
                    {" "}
                    <FormattedMessage
                      id="askinformationproducts.form-namebrand"
                      defaultMessage="Marcas:"
                    />
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="brands"
                    required
                    onChange={selectingModalEvent}
                  >
                    <option value="" hidden>
                      <FormattedMessage
                        id="askinformationproducts.form-selectbrand"
                        defaultMessage="Seleccionar Marca"
                      />
                    </option>
                    <option value="1. 76 Lubricants">1. 76 Lubricants</option>
                    <option value="2. AeroShell">2. AeroShell</option>
                    <option value="3. Aloetizer">3. Aloetizer</option>
                    <option value="4. American Lubrication">
                      4. American Lubrication
                    </option>
                    <option value="5. American Refining Group">
                      5. American Refining Group
                    </option>
                    <option value="6. Arconol">6. Arconol</option>
                    <option value="7. Autoguard">7. Autoguard</option>
                    <option value="8. Bear Paw">8. Bear Paw</option>
                    <option value="9. Berkebile">9. Berkebile</option>
                    <option value="10. Biobor">10. Biobor</option>
                    <option value="11. Black Magic">11. Black Magic</option>
                    <option value="12. BlueSky Lubricants">
                      12. BlueSky Lubricants
                    </option>
                    <option value="13. Carwell Products">
                      13. Carwell Products
                    </option>
                    <option value="14. Castrol">14. Castrol</option>
                    <option value="15. Chemcoat">15. Chemcoat</option>
                    <option value="16. Chevron">16. Chevron</option>
                    <option value="17. CITGO">17. CITGO</option>
                    <option value="18. Cim-Tek">18. Cim-Tek</option>
                    <option value="19. Coastal">19. Coastal</option>
                    <option value="20. Colonial Chemical Company">
                      20. Colonial Chemical Company
                    </option>
                    <option value="21. ComStar">21. ComStar</option>
                    <option value="22. CRC">22. CRC</option>
                    <option value="23. Daubert">23. Daubert</option>
                    <option value="24. DOW">24. DOW</option>
                    <option value="25. Engis">25. Engis</option>
                    <option value="26. Exol">26. Exol</option>
                    <option value="27. Fill-Rite">27. Fill-Rite</option>
                    <option value="28. Filtroil">28. Filtroil</option>
                    <option value="29. Fluid Film">29. Fluid Film</option>
                    <option value="30. FUCHS">30. FUCHS</option>
                    <option value="31. Gasoila">31. Gasoila</option>
                    <option value="32. GOJO">32. GOJO</option>
                    <option value="33. Graco">33. Graco</option>
                    <option value="34. Gulf Lubricants">
                      34. Gulf Lubricants
                    </option>
                    <option value="35. Gumout">35. Gumout</option>
                    <option value="36. Gunk">36. Gunk</option>
                    <option value="37. Hot Shot's Secret">
                      37. Hot Shot's Secret
                    </option>
                    <option value="38. Howes Lubricator">
                      38. Howes Lubricator
                    </option>
                    <option value="39. Husky">39. Husky</option>
                    <option value="40. Ingredi">40. Ingredi</option>
                    <option value="41. Itasca">41. Itasca</option>
                    <option value="42. Jax">42. Jax</option>
                    <option value="43. Jet-Lube">43. Jet-Lube</option>
                    <option value="44. Johnsen's">44. Johnsen's</option>
                    <option value="45. K100">45. K100</option>
                    <option value="46. Kendall">46. Kendall</option>
                    <option value="47. KLUBER LUBRICATION">
                      47. KLUBER LUBRICATION
                    </option>
                    <option value="48. Lincoln">48. Lincoln</option>
                    <option value="49. Liqui-Cult">49. Liqui-Cult</option>
                    <option value="50. Lubriguard">50. Lubriguard</option>
                    <option value="51. Lucas Oil">51. Lucas Oil</option>
                    <option value="52. LUBRIPLATE">52. LUBRIPLATE</option>
                    <option value="53. Mobil">53. Mobil</option>
                    <option value="54. Morse">54. Morse</option>
                    <option value="55. Mountain Productions">
                      55. Mountain Productions
                    </option>
                    <option value="56. MILES Lubricants">
                      56. MILES Lubricants
                    </option>
                    <option value="57. Nox-Rust">57. Nox-Rust</option>
                    <option value="58. Oil Eater">58. Oil Eater</option>
                    <option value="59. OIL-DRI">59. OIL-DRI</option>
                    <option value="60. OPW">60. OPW</option>
                    <option value="61. Peak">61. Peak</option>
                    <option value="62. Pennzoil">62. Pennzoil</option>
                    <option value="63. PENRAY">63. PENRAY</option>
                    <option value="64. Permatex">64. Permatex</option>
                    <option value="65. Phillips 66 Lubricants">
                      65. Phillips 66 Lubricants
                    </option>
                    <option value="66. Phillips 66 X/C Aviation">
                      66. Phillips 66 X/C Aviation
                    </option>
                    <option value="67. Polyguard">67. Polyguard</option>
                    <option value="68. PolySafe Products">
                      68. PolySafe Products
                    </option>
                    <option value="69. Power Service">69. Power Service</option>
                    <option value="70. Prestone">70. Prestone</option>
                    <option value="71. Promedic">71. Promedic</option>
                    <option value="72. Pure Asphalt">72. Pure Asphalt</option>
                    <option value="73. Purple Power">73. Purple Power</option>
                    <option value="74. RADCO">74. RADCO</option>
                    <option value="75. Safecote">75. Safecote</option>
                    <option value="76. Sheboygan Paint Company">
                      76. Sheboygan Paint Company
                    </option>
                    <option value="77. Shell">77. Shell</option>
                    <option value="78. Shell GADUS">78. Shell GADUS</option>
                    <option value="79. Simple Green">79. Simple Green</option>
                    <option value="80. Simpurity">80. Simpurity</option>
                    <option value="81. SKF">81. SKF</option>
                    <option value="82. Spray Nine">82. Spray Nine</option>
                    <option value="83. Star Brite">83. Star Brite</option>
                    <option value="84. STP">84. STP</option>
                    <option value="85. Sunoco Lubricants">
                      85. Sunoco Lubricants
                    </option>
                    <option value="86. Sunoco Race Fuels">
                      86. Sunoco Race Fuels
                    </option>
                    <option value="87. Super S">87. Super S</option>
                    <option value="88. SUMMIT">88. SUMMIT</option>
                    <option value="89. Tectyl">89. Tectyl</option>
                    <option value="90. TerraCair">90. TerraCair</option>
                    <option value="91. The Blaster Corporation">
                      91. The Blaster Corporation
                    </option>
                    <option value="92. The Dri-Rite Company">
                      92. The Dri-Rite Company
                    </option>
                    <option value="93. Undercoating In A Can">
                      93. Undercoating In A Can
                    </option>
                    <option value="94. United">94. United</option>
                    <option value="95. Valvoline">95. Valvoline</option>
                    <option value="96. WD-40">96. WD-40</option>
                    <option value="97. Welcome">97. Welcome</option>
                    <option value="98. Wolf's Head">98. Wolf's Head</option>
                    <option value="99. Xenon">99. Xenon</option>
                    <option value="100. Z-Tech">100. Z-Tech</option>
                  </Form.Select>
                  <div className="container-img-brandmodal">
                    {selectedBrandModal && (
                      <img
                        src={brandModalImages[selectedBrandModal]}
                        alt="Aditivos de la marca: ACDelco, Advance Auto Parts, Amalie, Amsoil, 76 Lubricants, AeroShell, Aloetizer, American Lubrication, American Refining Group, Arconol, Autoguard, Bear Paw, Berkebile, Biobor, Black Magic, BlueSky Lubricants, Carwell Products, Castrol, Chemcoat, Chevron, CITGO, Cim-Tek, Coastal, Colonial Chemical Company, ComStar, CRC, Daubert, DOW, Engis, Exol, Fill-Rite, Filtroil, Fluid Film, FUCHS, Gasoila, GOJO, Graco, Gulf Lubricants, Gumout, Gunk, Hot Shot's Secret, Howes Lubricator, Husky, Ingredi, Itasca, Jax, Jet-Lube, Johnsen's, K100, Kendall, KLUBER LUBRICATION, Lincoln, Liqui-Cult, Lubriguard, Lucas Oil, LUBRIPLATE, Mobil, Morse, Mountain Productions, MILES Lubricants, Nox-Rust, Oil Eater, OIL-DRI, OPW, Peak, Pennzoil, PENRAY, Permatex, Phillips 66 Lubricants, Phillips 66 X/C Aviation, Polyguard, PolySafe Products, Power Service, Prestone, Promedic, Pure Asphalt, Purple Power, RADCO, Safecote, Sheboygan Paint Company, Shell, Shell GADUS, Simple Green, Simpurity, SKF, Spray Nine, Star Brite, STP, Sunoco Lubricants, Sunoco Race Fuels, Super S, SUMMIT, Tectyl, TerraCair, The Blaster Corporation, The Dri-Rite Company, Undercoating In A Can, United, Valvoline, WD-40, Welcome, Wolf's Head, Xenon, Z-Tech"
                        className="img-brand-modal"
                        onError={(e) => {
                          e.target.src =
                            brandModalImages[selectedBrandModal + 2];
                        }}
                      />
                    )}
                  </div>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    <p>
                      <i className="fa-solid fa-circle fa-2xs"></i>
                      {" "}
                      <FormattedMessage
                        id="askinformationproducts.modalbody-p3"
                        defaultMessage="Escribir Mensaje"
                      />
                    </p>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="message"
                    placeholder={placeHolderMessage}
                    required
                  />
                </Form.Group>
                <Button variant="secondary" onClick={handleClose}>
                  <FormattedMessage
                    id="askinformationproducts.btn-close"
                    defaultMessage="Cerrar"
                  />
                </Button>
                {"  "}
                <Button variant="primary" type="submit">
                  <FormattedMessage
                    id="askinformationproducts.btn-submit"
                    defaultMessage="Enviar"
                  />
                </Button>
              </Form>
            </Modal.Body>
          </>
        ) : enviado ? (
          <>
            <Modal.Body>
              <div className="modal-smile-happyface">
                <i className="fa-regular fa-face-smile fa-10x"></i>
                <p>
                  <FormattedMessage
                    id="askinformationproducts.message-send"
                    defaultMessage="¡La información ha sido enviada exitosamente! Nos pondremos en contacto contigo pronto."
                  />
                </p>
              </div>
              <Button variant="secondary" onClick={handleClose}>
                <FormattedMessage
                  id="askinformationproducts.btn-sendclose"
                  defaultMessage="Cerrar"
                />
              </Button>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Body>
              <p>
                <FormattedMessage
                  id="askinformationproducts.message-error"
                  defaultMessage="Lo sentimos, no pudimos enviar el mensaje. Por favor, inténtalo
                de nuevo más tarde."
                />
              </p>
              <Button variant="secondary" onClick={handleClose}>
                <FormattedMessage
                  id="askinformationproducts.btn-errorclose"
                  defaultMessage="Cerrar"
                />
              </Button>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
};
