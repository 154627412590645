// LanguageContext.js

import React, { createContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import MessagesES from './es-PE.json';
import MessagesEN from './en-US.json';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [lang, setLang] = useState('es-PE'); //Cambiar Para Produccion
  const [messages, setMessages] = useState(MessagesES); //Cambiar Para Produccion

  useEffect(() => {
    const storedLang = localStorage.getItem('lang');
    if (storedLang) {
      setLang(storedLang);
      selectMessages(storedLang);
    }
  }, []);

  const selectMessages = (selectedLang) => {
    if (selectedLang === 'es-PE') {
      setMessages(MessagesES);
    } else if (selectedLang === 'en-US') {
      setMessages(MessagesEN);
    }
  };

  const handleLanguageChange = (newLang) => {
    setLang(newLang);
    localStorage.setItem('lang', newLang);
    selectMessages(newLang);
  };

  return (
    <LanguageContext.Provider value={{ lang, messages, handleLanguageChange }}>
      <IntlProvider locale={lang} messages={messages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
