const listProductsHydraulicOilsCo = [

    { option: "maphydraulicoilsco1", name: "namehydraulicoilsco1", imgUrl: "https://dsm01pap002files.storage.live.com/y4mex58hgKVTEYgOPoeKkMAuVyCYR9EOKqNiOTlcsBGO3qA3J4WwgZTXh7I5trUYP0O9iThGyUW_kS-NSUYeKs_QpB6SpUwFda8ZwfoF8lxAy73dF22i2D0WYPo03-eVjX6DDloyppeZtOjFre33rQbuhuVyXT-EVoY0Q_37V02DAAv9RSljBmtvaLfE3FNYw13?width=600&height=600&cropmode=none", description: "", url:"hydraulicoilsco.url1"},
    { option: "maphydraulicoilsco2", name: "namehydraulicoilsco2", imgUrl: "https://dsm01pap002files.storage.live.com/y4mujEh94oUiziR8xFMILbkv9QoUiGa9XCW-CG-STT9v1XCJqKK4dbYa3ZTfKFUTZG7tAwfIq_YYnKvEwZgaB_qdJP6Hao90wN86X0E-YfGgmrWL8A7HSHK5UcN7TRM_1O9h-j6wCTtzFHIKJgGV218ZgGuUhTPciaWnjH3O2S-spotz8nO_f7kmCKF2LfZVmO1?width=600&height=600&cropmode=none", description: "", url:"hydraulicoilsco.url2" },
    { option: "maphydraulicoilsco3", name: "namehydraulicoilsco3", imgUrl: "https://dsm01pap002files.storage.live.com/y4mNHhdFhuBDHPKZBJ3Z4BELgC4mFvnXMUHy_dguPNNbgeMXle7ucsC8WIsYqvPTrwcrBqYCrdD_l-m6GNc5B93H1NamesVl7x0RWAHr_s7aEI5pKYLmpW7XOh04C7Xi6IZy5aVSA0LxI35CQyMihy8Uz1QnelRP83GfN3rHklqCLtg-QlTUSe4AZb6GqGMGRv-?width=600&height=600&cropmode=none", description: "", url:"hydraulicoilsco.url3" },
    { option: "maphydraulicoilsco4", name: "namehydraulicoilsco4", imgUrl: "https://dsm01pap002files.storage.live.com/y4mZunxVJ4yZR-jcGqqZdGjHNpu9w273cb2v3vTSLjqV6hkMw87c28CbXy0FkAKJcm6BVQ3wCy37XTcEAX49q6hGORjYLNS9OanbSsC_qdPFdIjjGML53M0W7DkIiLwgQgQXMy6wTrpq6_pJe4lDijd1BgFu0VcCF7AQU44-Yh6lXUm2OsSGn2mPAMmn7Nmle2k?width=600&height=600&cropmode=none", description: "", url:"hydraulicoilsco.url4" },
    { option: "maphydraulicoilsco5", name: "namehydraulicoilsco5", imgUrl: "https://dsm01pap002files.storage.live.com/y4mxdpKhu5_TRwTJKaqiRRGOl3rP84i7MeUA0JbIlviYiYg9RSsRy3Hau5cCaXNRDeehPQ73hRhUDcLTj-Ri1rgQbnls1Ai1u0oQL_ufh1rK3mI8PX86u4ZGIg0I1V6FpHhDBeQOwa_BiwHLbIR62r0uCbjGNWcx06VlCkOMO5a004h7k0dKtlHOnE3WWf3a3_u?width=600&height=600&cropmode=none", description: "", url:"hydraulicoilsco.url5" },
    { option: "maphydraulicoilsco6", name: "namehydraulicoilsco6", imgUrl: "https://dsm01pap002files.storage.live.com/y4mbp3QnUudaNT6r1989uWWQdR5-fCN5p1Aa-nVMopxLEV1BNdHeLHeftvygquCrv_e4ceLz-2stSRqrGdqat0b7plhKVhP_ePYEjH4fDwUTSp87uhlfMrC-Kfy7W_civo5XMAhSpq6xp-rMLh4EGBAIFhG5kj2bBLnOnPkBsVcOf3GHT5XVwCtYXU1n1MU4iY-?width=600&height=600&cropmode=none", description: "", url:"hydraulicoilsco.url6" },
    { option: "maphydraulicoilsco7", name: "namehydraulicoilsco7", imgUrl: "https://dsm01pap002files.storage.live.com/y4mCMFsaTM8y0F7F-ZbWWWeDGRg21EOiOW9VMEDZcmM476fxC0AHgjHdkvCVGCRpGCjDY2xdD6lbszS9dx9BaFdLX3K1M7AtY4wGBLtbQtHC4SdUy89SMI971RzxY9flsa64BxIp9X204RrmyqM8e6GJ0C07BIHpnxcZxC3OZ3oIhzkdQPqKFq1hqso28pO2clN?width=600&height=600&cropmode=none", description: "", url:"hydraulicoilsco.url7" },
];
  
  export default listProductsHydraulicOilsCo;